import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  changedPassword: string;
  confirmChangedPassword: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      changedPassword: '',
      confirmChangedPassword: '',
      // Customizable Area End
    };
  }

  // Customizable Area Start
   handleChangePassword = (e: any) => {
    this.setState({ changedPassword: e.target.value });
  };

  handleConfirmChangepassword= (e: any) => {
    this.setState({ confirmChangedPassword: e.target.value });
  };

  handlepasswordLength = () => {
    return this.state.changedPassword.length >= 8;
  };

  handledifferentCharacters = () => {
    return /[A-Za-z]/.test(this.state.changedPassword) && /\d/.test(this.state.changedPassword) && /[^A-Za-z0-9]/.test(this.state.changedPassword);
  };

  handleupperCaseCheck = () => {
    return /[A-Z]/.test(this.state.changedPassword);
  };
  // Customizable Area End
}
