import React from "react"
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("../config");

interface DayState {
  checked: boolean;
  startTime: string;
  endTime: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  patient_id: number,
  isLoading: boolean,
  stepperStep: string[],
  heading: string,
  subHeading: string,
  pleaseTellHeading2: string,
  pleaseTellHeading: string,
  genderList: string[],
  specialtiesList: string[],
  typeOfTherapyList: string[],
  selectedRadios: any,
  anythingElseInput: string,
  pageNo: number,
  schedule: Record<string, DayState>;
  anyTimeCheckBox: boolean,
  timeOptions: string[],
  isModalOpen: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WaitListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pageTopRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pageTopRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      patient_id: 158,
      isLoading: false,
      stepperStep: ["About You", "Verify Insurance", "Choose Provider"],
      heading: "Join Our Waitlist",
      subHeading: "Sorry, there are no providers who match your requirements at this time.",
      pleaseTellHeading2: "Please tell us your ideal session times:",
      pleaseTellHeading: "Please tell us more about what would work for you:",
      genderList: ["Either", "Male", "Female"],
      specialtiesList: ["Anxiety", "Depression", "Addiction", "Grief and Loss", "Chronic Pain", "Cognitive Decline"],
      typeOfTherapyList: ["Individual", "Couple", "Family", "Group"],
      selectedRadios: { typeOfTherapy: "", specialist: "", gender: "" },
      anythingElseInput: "",
      pageNo: 1,
      schedule: {
        Monday: { checked: false, startTime: "", endTime: "" },
        Tuesday: { checked: false, startTime: "", endTime: "" },
        Wednesday: { checked: false, startTime: "", endTime: "" },
        Thursday: { checked: false, startTime: "", endTime: "" },
        Friday: { checked: false, startTime: "", endTime: "" },
        Saturday: { checked: false, startTime: "", endTime: "" },
        Sunday: { checked: false, startTime: "", endTime: "" },
      },
      anyTimeCheckBox: false,
      timeOptions: ["8 AM", "9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM"
      ],
      isModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (this.pageTopRef.current) {
      this.pageTopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  checkIsValidCondition = () => {
    const { selectedRadios, anythingElseInput } = this.state;
    const { gender, specialist, typeOfTherapy } = selectedRadios
    return gender && specialist && typeOfTherapy && anythingElseInput;
  }

  checkDayTimeValidCondition = () => {
    const { anyTimeCheckBox, schedule } = this.state;
    return (
      anyTimeCheckBox ||
      Object.values(schedule).some(day => day.checked && day.startTime.trim() && day.endTime.trim())
    );
  };

  handleBack = () => {
    if (this.state.pageNo === 2) {
      this.setState({ pageNo: 1 }, () => {
        if (this.pageTopRef.current) {
          this.pageTopRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });
    } else {
      this.handleNavigate("DesignCareTeam")
    }
  };

  onRadioChange = (name: string, value: string) => {
    this.setState((prevState) => ({
      selectedRadios: {
        ...prevState.selectedRadios,
        [name]: value
      }
    }));
  };

  handleInputChange = (value: string) => {
    this.setState({ anythingElseInput: value });
  }

  handleNext = () => {
    if (this.state.pageNo === 1) {
      this.setState({ pageNo: 2 }, () => {
        if (this.pageTopRef.current) {
          this.pageTopRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });
    } else {
      this.setState({ isModalOpen: true })
    }
  }

  toggleDay = (day: string) => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        [day]: { ...prevState.schedule[day], checked: !prevState.schedule[day].checked },
      },
    }));
  };

  handleTimeChange = (day: string, field: "startTime" | "endTime", value: string | null) => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        [day]: {
          ...prevState.schedule[day],
          [field]: value ?? "",
          ...(field === "startTime" && { endTime: "" }),
        },
      },
    }));
  };

  getDisabledTimes = (startTime?: string) => {
    if (!startTime) return [];
    const startIndex = this.state.timeOptions.indexOf(startTime);
    return this.state.timeOptions.slice(0, startIndex + 1);
  };

  handleAnyTimeChange = () => {
    this.setState((prevState) => {
      const updatedSchedule = Object.keys(prevState.schedule).reduce((acc, day) => {
        acc[day] = { ...prevState.schedule[day], checked: false };
        return acc;
      }, {} as S["schedule"]);

      return {
        anyTimeCheckBox: !prevState.anyTimeCheckBox,
        schedule: prevState.anyTimeCheckBox ? prevState.schedule : updatedSchedule,
      };
    });
  };

  getClassName = (checked: boolean) => (checked ? "Enabled" : "Disabled");

  getHeading = () => (this.state.pageNo === 1 ? this.state.pleaseTellHeading : this.state.pleaseTellHeading2);

  getProviderRefText = () => (this.state.pageNo === 1 ? configJSON.providerRef : configJSON.schedulePref);

  getDashClass = (checked: boolean) => (checked ? "dashEnable" : "dashDisabled");

  getNextBtnClass = () => {
    return `nextBtnText ${this.getNextButtonValidCondition() ? "nextBtnActive" : "nextBtnInactive"}`;
  };

  getNextButtonValidCondition = () => {
    const { pageNo } = this.state;
    return pageNo === 1 ? this.checkIsValidCondition() : this.checkDayTimeValidCondition();
  };

  getNextButtonDisabledState = () => {
    const { pageNo } = this.state;
    return pageNo === 1 ? !this.checkIsValidCondition() : !this.checkDayTimeValidCondition();
  };
  // Customizable Area End
}
