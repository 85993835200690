import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  offerDetailLabels: string[],
  offerDetailData: string[],
  offerDetailDataFullTime: string[],
  offerDetailDataPartTime: string[],
  scheduleLabels: string[],
  scheduleData: string[],
  scheduleTimeLabels: string[],
  scheduleTimeData: string[],
  remunerationLabels: string[],
  remunerationData: string[],
  remunerationLabelsFullTime: string[],
  remunerationDataFullTime: string[],
  remunerationLabelsPartTime: string[],
  remunerationDataPartTime: string[],
  
  role: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContractReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      offerDetailLabels: ["Company", "First Name", "Last Name", "Position Title", "Employment Type"],
      offerDetailData: ["Total Life LLC", "Meredith", "Grey", "Freelance Writer", "Contractor (1099)"],
      offerDetailDataFullTime: ["Total Life LLC", "Meredith", "Grey", "Call Center Manager", "Full Time Employee"],
      offerDetailDataPartTime: ["Total Life LLC", "Meredith", "Grey", "Clinical Assistant", "Part Time Employee"],
      scheduleLabels: ["Effective Date", "Duration of Agreement"],
      scheduleData: ["June 07, 2024", "2 months"],
      scheduleTimeLabels: ["Effective Date", "Probationary End Date"],
      scheduleTimeData: ["June 07, 2024", "September 07, 2024"],
      remunerationLabels: ["Hourly Rate"],
      remunerationData: ["$40"],
      remunerationLabelsFullTime: ["Annual Salary", "Vacation Time", "Personal leave"],
      remunerationDataFullTime: ["$87.000", "20 days per year", "12 days per year"],
      remunerationLabelsPartTime: ["Hourly Rate", "Vacation Time", "Personal leave"],
      remunerationDataPartTime: ["$20", "5% of total wages per year", "12 days per year"],

      role: "Full Time Employee" // Full Time Employee, Part Time Employee, Contractor (1099)
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getOfferDetails = () => {
    const { role } = this.state;
    let offerDetails, scheduleLabels, scheduleData, remunerationLabels, remunerationData;

    if (role === "Full Time Employee") {
        offerDetails = this.state.offerDetailDataFullTime;
        scheduleLabels = this.state.scheduleTimeLabels;
        scheduleData = this.state.scheduleTimeData;
        remunerationLabels = this.state.remunerationLabelsFullTime;
        remunerationData = this.state.remunerationDataFullTime;
    } else if (role === "Part Time Employee") {
        offerDetails = this.state.offerDetailDataPartTime;
        scheduleLabels = this.state.scheduleTimeLabels;
        scheduleData = this.state.scheduleTimeData;
        remunerationLabels = this.state.remunerationLabelsPartTime;
        remunerationData = this.state.remunerationDataPartTime;
    } else {
        offerDetails = this.state.offerDetailData;
        scheduleLabels = this.state.scheduleLabels;
        scheduleData = this.state.scheduleData;
        remunerationLabels = this.state.remunerationLabels;
        remunerationData = this.state.remunerationData;
    }

    return { offerDetails, scheduleLabels, scheduleData, remunerationLabels, remunerationData };
  }

  // Customizable Area End
}
