import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../config");

interface MultiBoxType {
  "id": number,
  "image": string,
  "title": string,
  "description": string,
  "button_text": string,
}

interface SupportDesignType {
  "id": string,
  "attributes": {
    "title": string,
    "body": string,
    "multi_select_boxes": MultiBoxType[],
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperStep: string[],
  heading: string,
  subHeading: string,
  isLoading: boolean,
  supportDesignList: MultiBoxType[],
  selectedOptions: number[],
  isHovered: boolean,
  patient_id: number,
  submittedList: { id: number, title: string }[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SupportDesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSupportDesignApiCallId: string = "";
  submitSelectedDesignApiCallId: string = "";
  getSubmittedDesignApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepperStep: ["About You", "Verify Insurance", "Choose Provider"],
      heading: "",
      subHeading: "",
      isLoading: false,
      supportDesignList: [],
      selectedOptions: [],
      isHovered: false,
      patient_id: 158,
      submittedList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId) {
        if (apiRequestCallId === this.getSupportDesignApiCallId) {
          this.receiveSupportDesignList(responseJson);
        } else if (apiRequestCallId === this.submitSelectedDesignApiCallId) {
          if (responseJson && responseJson.message === "Patient support designed update successfully!") {
            this.handleNavigate("LastQuestion");
          }
        } else if (apiRequestCallId === this.getSubmittedDesignApiCallId) {
          this.receiveSubmittedData(responseJson)
        }
      }
    }
  }

  receiveSupportDesignList = (responseJson: { data: SupportDesignType[] }) => {
    if (responseJson && responseJson.data) {
      let data = responseJson.data && responseJson.data[0]
      let attributes = data && responseJson.data[0].attributes;

      this.setState({
        heading: attributes.title,
        subHeading: attributes.body,
        supportDesignList: attributes && attributes.multi_select_boxes,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false, })
    }
  };

  receiveSubmittedData = (data: { selected_boxes: { id: number, title: string }[] }) => {
    if (data && data.selected_boxes) {
      let selected_options = data.selected_boxes;
      this.setState((prevState) => ({
        submittedList: selected_options,
        selectedOptions: [
          ...prevState.selectedOptions,
          ...selected_options.map((item) => item.id),
        ],
      }));
    }
  }


  async componentDidMount() {
    this.getSupportDesignList();
    this.getSubmittedDesignList();
  }

  getSupportDesignList = () => {
    this.setState({ isLoading: true, });

    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSupportDesignApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.supportDesignEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitSelectedDesign = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const data = {
      "patient_id": this.state.patient_id,
      "multi_box_selected_ids": this.state.selectedOptions,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitSelectedDesignApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitDesignEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubmittedDesignList = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubmittedDesignApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubmittedDesignEndPoint}?patient_id=${this.state.patient_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  selectDesign = (option: number) => {
    this.setState((prevState) => {
      const selected = prevState.selectedOptions.includes(option)
        ? prevState.selectedOptions.filter((item) => item !== option)
        : [...prevState.selectedOptions, option];

      return { selectedOptions: selected }
    });
  }

  getItemStyle = (itemId: number) => {
    const isSelected = this.state.selectedOptions.includes(itemId);
    return {
      backgroundColor: isSelected ? "#E46760" : "#FFFFFF",
      color: isSelected ? "#FFFAF6" : "#E46760",
    };
  };

  handleBack = () => {
    this.handleNavigate("DiagnosedSelected")
  };

  handleContinue = () => {
    if (this.state.selectedOptions.length > 0) {
      this.submitSelectedDesign();
    }
  };

  handleSkip = () => {
    this.setState({
      selectedOptions: [],
    }, () => {
      this.submitSelectedDesign();
    });
  };

  getContinueTextColor = () => {
    return (this.state.selectedOptions.length > 0)
      ? "#FFFAF6"
      : "#78716C";
  };

  getContinueBackgroundColor = () => {
    const { isHovered, selectedOptions } = this.state;
    if (isHovered) {
      return "#B6524D";
    } else if (selectedOptions.length > 0) {
      return "#E46760";
    } else {
      return "#D6D3D1";
    }
  }

  onMouseEnter = () => {
    this.setState({ isHovered: this.state.selectedOptions.length > 0 })
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false })
  };
  // Customizable Area End
}
