// Customizable Area Start
import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    heading: string,
    subHeading: string,
    chooseFollowing?: string,
    chooseFollowingSubHeading?: string,
    width?: string,
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class RegistrationHeadings extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { heading, subHeading, chooseFollowing, chooseFollowingSubHeading, width } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <StyledWrapper>
                <Box className="headingsBox">
                    <Typography className="heading">{heading}</Typography>
                    <Typography className="subHeading" style={{ width: width }}>{subHeading}</Typography>
                    {chooseFollowing && <Typography className="chooseFollowingHeading">{chooseFollowing}</Typography>}
                    {chooseFollowingSubHeading && <Typography className="chooseFollowingSubHeading">{chooseFollowingSubHeading}</Typography>}
                </Box>
            </StyledWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    "& .headingsBox": {
        width: "fit-content",
        margin: "auto",
        textAlign: "center",
    },
    "& .heading": {
        marginTop: "60px",
        fontSize: "41px",
        fontWeight: 600,
        lineHeight: "53.3px",
        fontFamily: "Raleway",
        color: "#2C2524",
        letterSpacing: "0.5px",
        "@media (max-device-width: 716px)": {
            fontSize: "32px",
            marginTop: "40px",
        },
        "@media (max-device-width: 490px)": {
            fontSize: "24px",
            marginTop: "20px",
        },
    },
    "& .subHeading": {
        width: "600px",
        color: "#2C2524",
        fontWeight: 400,
        lineHeight: "36px",
        fontSize: "24px",
        marginTop: "32px",
        fontFamily: "Poppins",
        "@media (max-device-width: 716px)": {
            fontSize: "20px",
            width: "450px",
            marginTop: "24px",
        },
        "@media (max-device-width: 490px)": {
            lineHeight: "20px",
            width: "350px",
            fontSize: "16px",
            marginTop: "16px",
        },
    },
    "& .chooseFollowingHeading": {
        color: "#2C2524",
        fontWeight: 700,
        lineHeight: "30px",
        fontSize: "20px",
        marginTop: "32px",
        fontFamily: "Poppins",
        "@media (max-device-width: 716px)": {
            lineHeight: "20px",
            marginTop: "20px",
            fontSize: "20px",
        },
        "@media (max-device-width: 490px)": {
            fontSize: "16px",
            lineHeight: "16px",
            marginTop: "16px",
        },
    },
    "& .chooseFollowingSubHeading": {
        color: "#2C2524",
        fontSize: "18px",
        lineHeight: "30px",
        fontWeight: 400,
        fontFamily: "Poppins",
        "@media (max-device-width: 490px)": {
            marginTop: "5px",
            fontSize: "14px",
            lineHeight: "16px",
        },
    },
});
// Customizable Area End
