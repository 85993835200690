import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Formtype {
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  email:string,
  mobile:string

} 
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  stepper:string[];
  continue:boolean;
  contactMethod: string[],
  Title:string;
  acceptAggrementCheckbox:boolean,
  patientConcent:boolean,
  RepresentativeReason:string[],
  RepresentativeCause:string,
  landline:boolean,
  email:string,
  emailValid:boolean,
  Relation:boolean,
  Representative:boolean,
  landlineNumber:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PatientRepresentativeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createRepresentativeCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      stepper:['About You','Verify Insurance','Choose Provider'],
      continue:false,
      contactMethod: [],
      Title:'',
      RepresentativeReason:['Limited Internet Access','Not comfortable using digital platforms',
        'They have a physical disability','They are visually impaired','They have cognitive challenges','I am their caregiver',
        'They are currently hospitalized','They are not fluent in English','They do not want to enroll in therapy but need it',
        'Prefer not to answer','Other (specify)'
      ],
      acceptAggrementCheckbox:false,
      patientConcent:false,
      RepresentativeCause:'',
      landline:false,
      email:'',
      emailValid:false,
      Relation:false,
      Representative:false,
      landlineNumber:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId===this.createRepresentativeCallId){
       
         const id=responseJson.data.id;
         setStorageData('representativeid',id)
         this.props.navigation.navigate('PatientSignupForm2')
        }
       
      }
    }
   



    // Customizable Area Start
    // Customizable Area End
  }  

  // Customizable Area Start

 
  handleCheckboxChange = (method:any) => {
    this.setState((prevState:S) => {
      const { contactMethod } = prevState;
      if (contactMethod.includes(method)) {
        
        return {
          contactMethod: contactMethod.filter(
            (item) => item !== method
          ),
        };
      } else {
      
        return {
          contactMethod: [...contactMethod, method],
        };
      }
    });
   
  };
handleValidation=(e:any)=>{
this.setState({email:e.target.value})
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
this.setState({emailValid:!emailRegex.test(this.state.email)})
}

 handleSubmit=()=>{
   
  this.setState({
    Relation: this.state.Title.length === 0,
    Representative: this.state.RepresentativeCause.length === 0,
  });
if(!this.state.emailValid&&this.state.contactMethod.length!=0&&this.state.Title.length!==0&&this.state.RepresentativeCause.length!==0){
    
    
    this.setState({continue:true})
}
 }
 handleRepresentativeAPI=async(values:any)=>{
  
    let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": "application/json",
      };
const httpBody={
    "data": {
        "type": "authorized_representatives",
        "attributes": {
            "email": values.email,
            "contact_method": values.contactMethod,
            "relationship_patient": values.Title,
            "reason_for_signing_up": values.RepresentativeCause,
            "first_name": values.firstName,
            "last_name": values.lastName,
            "landline_number": values.landineNumber,
            "phone_number": values.mobileNumber,
            "terms_of_use": true,
            "help_footer": false,
            "feeling": "Happy",
            "landline_status": values.islandine
        }
    }
}
this.createRepresentativeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AuthorizedCreateEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);

 }

  // Customizable Area End
}
