// Customizable Area Start
import React from "react";
import { Box, styled, Typography } from "@mui/material";
import SupportDesController, { Props, configJSON } from "./SupportDesController.web";
import RegistrationFooter from "../components/RegistrationFooter.web";
import CustomStepper from "../components/CustomStepper.web";
import RegistrationHeadings from "../components/RegistrationHeadings.web";
import RegistrationActionBtns from "../components/RegistrationActionBtns.web";
import Loader from '../../../../../../src/packages/components/src/Loader.web';
// Customizable Area End

export default class SupportDes extends SupportDesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <SidebarLine />
        {
          this.state.isLoading ? (
            <Loader loading={this.state.isLoading} />
          ) : (
            <CustomWrapper>
              <CustomStepper
                stepperSteps={this.state.stepperStep}
                bgColor="#E46760"
                color="#FFFFFF"
                activeStep={0}
              />
              <RegistrationHeadings
                heading={this.state.heading}
                subHeading={this.state.subHeading}
                width="100%"
              />

              <Box className="supportList">
                {
                  this.state.supportDesignList.length > 0 && this.state.supportDesignList.map((item) => {
                    return (
                      <Box
                        key={item.id}
                        className="support"
                        style={{ border: this.state.selectedOptions.includes(item.id) ? "3px solid #E46760" : "1px solid #D6D3D1" }}
                      >
                        <img className="boxIcon" src={item.image} alt="icon" />
                        <Typography className="title">{item.title}</Typography>
                        <Typography className="description">{item.description}</Typography>
                        <Typography
                          className="btnText"
                          data-test-id="btnToggle"
                          onClick={() => this.selectDesign(item.id)}
                          style={this.getItemStyle(item.id)}
                        >{this.state.selectedOptions.includes(item.id) ? "Selected" : item.button_text}</Typography>
                      </Box>
                    )
                  })
                }
              </Box>

              <RegistrationActionBtns
                data-test-id="testRegistrationActionBtns"
                backText={configJSON.backText}
                continueText={configJSON.continueText}
                skipThisStepText={configJSON.skipThisStepText}
                handleBack={this.handleBack}
                handleContinue={this.handleContinue}
                handleSkip={this.handleSkip}
                getContinueTextColor={this.getContinueTextColor}
                getContinueBackgroundColor={this.getContinueBackgroundColor}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
              />

              <RegistrationFooter />
            </CustomWrapper>
          )
        }

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  marginLeft: "32px",
  boxSizing: "border-box",
  "& .supportList": {
    width: "62%",
    margin: "56px auto 0 auto",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 24,
    "@media (max-device-width: 1235px)": {
      width: "75%",
    },
    "@media (max-device-width: 1030px)": {
      width: "80%",
    },
    "@media (max-device-width: 970px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-device-width: 660px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
      margin: "40px auto 0 auto",
    },
  },
  "& .support": {
    boxSizing: "border-box",
    padding: "16px 16px",
    borderRadius: "16px",
    "@media (max-device-width: 660px)": {
      width: "70%",
      margin: "auto",
    },
    "@media (max-device-width: 460px)": {
      width: "100%",
    },
  },
  "& .boxIcon": {
    width: "72px",
    heigth: "72px",
    display: "block",
    margin: "auto",
  },
  "& .title": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "30px",
    color: "#2C2524",
    marginTop: "12px",
    textAlign: "center",
  },
  "& .description": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineGeight: "24px",
    color: "#807C7C",
    marginTop: "12px",
    textAlign: "center",
    minHeight: "96px",
  },
  "& .btnText": {
    width: "184px",
    boxSizing: "border-box",
    margin: "auto",
    border: "1px solid #E46760",
    padding: "4 0",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "12px",
    borderRadius: "16px",
    cursor: "pointer",
  },
});

const SidebarLine = styled(Box)({
  width: "32px",
  backgroundColor: "#1A4A42",
  height: "100vh",
  position: "fixed",
});

// Customizable Area End
