import React from 'react';

// Customizable Area Start
import {
    Box,
    styled,
    Typography,
    Grid,
    Tab,
    Tabs,
} from '@mui/material';
import { ArrowBack, KeyboardArrowDown, CloseOutlined } from "@mui/icons-material";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Morning, Afternoon, Night, ActiveMorning, ActiveAfternoon, ActiveNight, PlayIcon, MuteIcon, ScreenSize, ShareIcon, OptionIcon } from "./assets";
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import {
    DatePicker,
    LocalizationProvider,
    PickersDay,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from 'moment';

// Customizable Area End

import SelectedProviderController, {
    Props,
} from "./SelectedProviderController";
import { Card } from '@material-ui/core';
class SelectedProvider extends SelectedProviderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        dayjs.extend(updateLocale);
        dayjs.updateLocale('en', {
            weekStart: 1,
        });
        moment.updateLocale('en', { week: { dow: 1, } });

        const dayOfWeekFormatter = (dayOfWeek: any) => {
            const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            return weekdays[dayOfWeek];
        };

        const handleTabs = () => {
            return <Box className='tab-parent-box' sx={{ width: '100%', margin: '24px 12px 24px 42px', maxWidth: 364 }}>
                <Tabs
                    data-test-id="TabTest"
                    className='tab-inner-div'
                    value={this.state.selectedTab}
                    onChange={this.handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        display: 'flex',
                        borderRadius: '50px',
                        border: '1px solid #D6D3D1',
                        flexDirection: 'row',
                        gap: 4.5,
                        justifyContent: 'space-between',
                        padding: '4px',
                    }}
                >
                    <Tab
                        label={<Box sx={{ display: 'flex', textTransform: "capitalize", alignItems: 'center', }}><img style={{ marginRight: '2px' }} src={this.state.selectedTab === 0 ? ActiveMorning : Morning} />Morning</Box>}
                        sx={this.state.selectedTab === 0 ? webStyles.TimeZoneActive : webStyles.TimeZone}
                    />
                    <Tab
                        label={<Box sx={{ display: 'flex', textTransform: "capitalize", alignItems: 'center', }}><img style={{ marginRight: '2px' }} src={this.state.selectedTab === 1 ? ActiveAfternoon : Afternoon} />Afternoon</Box>}
                        sx={this.state.selectedTab === 1 ? webStyles.TimeZoneActive : webStyles.TimeZone}
                    />
                    <Tab
                        label={<Box sx={{ display: 'flex', textTransform: "capitalize", alignItems: 'center', }}><img style={{ marginRight: '2px' }} src={this.state.selectedTab === 2 ? ActiveNight : Night} />Night</Box>}
                        sx={this.state.selectedTab === 2 ? webStyles.TimeZoneActive : webStyles.TimeZone}
                    />
                </Tabs>
            </Box>
        }
        return (
            <StyledWrapper>
                <Box className='leftLine'></Box>
                <>

                    <section className="containerStyles">
                        <Box className="content">
                            <Box className="leftSide">
                                <Box className='padding-class back-box' sx={webStyles.chooseProvider}>
                                    <Box onClick={this.goBack} data-test-id="goBackTest" style={{ display: "flex", position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 0, flexDirection: "row", gap: '12px', alignItems: 'center', padding: "10px 16px", border: "2px solid #E7E5E4", borderRadius: "16px" ,cursor:"pointer"}}>
                                        <ArrowBack style={{ color: '#A8A29E', width: 16 }} />
                                        <Typography style={{ color: '#A8A29E', fontSize: 20, fontWeight: 700, lineHeight: 1, position: 'relative', top: 2, fontFamily: "Raleway" }}>
                                            Back
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <img className='card-profile' style={{ minWidth: '132px', height: '132px', borderRadius: '50%' }} src="https://picsum.photos/200/300" alt='profile-img' />
                                        <Typography style={{ fontWeight: 700, fontSize: "24px", lineHeight: "1.1", color: "#2C2524", marginTop: "12px" }}>
                                            Meredith J. Grey
                                        </Typography>
                                        <Typography style={{ fontWeight: 700, fontSize: "16px", lineHeight: "1.1", color: "#78716C", marginTop: "12px" }}>
                                            Psychologist
                                        </Typography>
                                    </Box>
                                </Box>
                                <Grid className='padding-class' style={{ justifyContent: 'center', gap: 24, marginTop: '37px', overflow: "scroll", paddingLeft: "112px" }} container spacing={1}>
                                    <Card style={webStyles.root}>
                                        <CardActionArea>
                                            <Box style={{ padding: "44px 20px" }}>
                                                <Typography style={{ fontWeight: 700, fontSize: "16px", color: "#2C2524" }}>
                                                    About Me:
                                                </Typography>
                                                <Typography style={{ fontWeight: 400, fontSize: "16px", marginTop: "24px", color: "#78716C", fontStyle: "italic" }}>
                                                    “I am a Licensed Psychologist at Stanford University with seven years experience focusing on anxiety, depression, addictions, grief, and family dynamics.”
                                                </Typography>
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                    <Card>
                                    </Card>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                                        <video
                                            controls style={{ borderRadius: "26px", width: "497px", maxHeight: "280px", objectFit: 'cover' }}>
                                            <source src="https://www.w3schools.com/html/movie.mp4" type="video/mp4"
                                            />
                                        </video>
                                    </Box>
                                    <Box>
                                        <Box sx={{ textAlign: "left", marginBottom: "21px" }}>
                                            <Typography style={webStyles.heading}>Modalities and Expertise:</Typography>
                                            <Box sx={webStyles.disease1}>
                                                <Typography style={webStyles.specialities}>
                                                    Anxiety
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Family Therapy
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Couple Therapy
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Attachment-based Therapy
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Autism
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ textAlign: "left", marginBottom: "21px" }}>
                                            <Typography style={webStyles.heading}>Work Experience:</Typography>
                                        </Box>
                                        <Box sx={{ textAlign: "left", marginBottom: "21px" }}>
                                            <Typography style={webStyles.heading}>Education:</Typography>
                                            <Typography style={webStyles.subHeading}>Lorem ipsum dolor sit amet consectetur. Et bibendum praesent ac mollis elit facilisis etiam. Aliquet aliquam pellentesque nec at neque. Turpis id lectus massa eu sed pharetra placerat. Scelerisque.</Typography>
                                        </Box>
                                        <Box sx={{ textAlign: "left", marginBottom: "21px" }}>
                                            <Typography style={webStyles.heading}>Spoken Languages:</Typography>
                                            <Box sx={webStyles.disease1}>
                                                <Typography style={webStyles.specialities}>
                                                    English
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    French
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Arabic
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ textAlign: "left", marginBottom: "21px" }}>
                                            <Typography style={webStyles.heading}>FAQS</Typography>
                                        </Box>
                                        <Box sx={{ textAlign: "left", marginBottom: "21px" }}>
                                            <Box sx={{ marginBottom: "21px" }}>
                                                <Accordion data-test-id="testAccordian" expanded={this.state.expanded === "panel1"} onChange={this.handleChange('panel1')} style={webStyles.accordian}>
                                                    <AccordionSummary
                                                        expandIcon={<KeyboardArrowDown />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography style={webStyles.subHeading}> What makes you enjoy working with seniors? </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            As a therapist, working with seniors is immensely rewarding. They have life stories to tell that span youth, loves, challenges, and everything in between. When I am able to help them with a problem or solve some emotional struggle, or in any way improve their lives, it provides me with a deep sense of gratitude
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                            <Box sx={{ marginBottom: "21px" }}>
                                                <Accordion expanded={this.state.expanded === "panel2"} onChange={this.handleChange('panel2')} style={webStyles.accordian}>
                                                    <AccordionSummary
                                                        expandIcon={<KeyboardArrowDown />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header"
                                                    >
                                                        <Typography style={webStyles.subHeading}> Why do you think online therapy is effective for seniors? </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            As a therapist, working with seniors is immensely rewarding. They have life stories to tell that span youth, loves, challenges, and everything in between. When I am able to help them with a problem or solve some emotional struggle, or in any way improve their lives, it provides me with a deep sense of gratitude
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                            <Box sx={{ marginBottom: "21px" }}>
                                                <Accordion expanded={this.state.expanded === "panel3"} onChange={this.handleChange('panel3')} style={webStyles.accordian}>
                                                    <AccordionSummary
                                                        expandIcon={<KeyboardArrowDown />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header"
                                                    >
                                                        <Typography style={webStyles.subHeading}> Can you share some details about your experience? </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            As a therapist, working with seniors is immensely rewarding. They have life stories to tell that span youth, loves, challenges, and everything in between. When I am able to help them with a problem or solve some emotional struggle, or in any way improve their lives, it provides me with a deep sense of gratitude
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                            <Box sx={{ marginBottom: "21px" }}>
                                                <Accordion expanded={this.state.expanded === "panel4"} onChange={this.handleChange('panel4')} style={webStyles.accordian} >
                                                    <AccordionSummary
                                                        expandIcon={<KeyboardArrowDown />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header"
                                                    >
                                                        <Typography style={webStyles.subHeading}> Can you provide details on your approach to therapy? </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            As a therapist, working with seniors is immensely rewarding. They have life stories to tell that span youth, loves, challenges, and everything in between. When I am able to help them with a problem or solve some emotional struggle, or in any way improve their lives, it provides me with a deep sense of gratitude
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        </Box>
                                        <Box sx={{ textAlign: "left", marginBottom: "21px" }}>
                                            <Typography style={webStyles.heading}> Areas I don't cover: </Typography>
                                            <Box sx={webStyles.disease1}>
                                                <Typography style={webStyles.specialities}>
                                                    Spirituality
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Suicidal Ideations
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Trauma/PTSD
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Weight Loss
                                                </Typography>
                                                <Typography style={webStyles.specialities}>
                                                    Women's Issues
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box className='rightSide'>
                                <Typography className='time-box date-box' style={webStyles.chooseHeadStyle}>
                                    Meredith’s Availability:                                    </Typography>
                                <Box className='time-box' style={{ display: "flex", justifyContent: "center", paddingBottom: "12px", gap: "5px", paddingLeft: 42, maxWidth: 364 }}>
                                    <Typography style={{ fontWeight: 700, color: "#A8A29E", fontSize: "16px", }}>Central Time  </Typography>
                                    <Typography style={{ fontWeight: 400, color: "#A8A29E", fontSize: "16px", }}> (UTC-6)</Typography>
                                    <KeyboardArrowDown style={{ backgroundColor: "#FFFFFF", color: "#222222", bottom: 5 }} />
                                </Box>
                                <Box style={{ borderBottom: '1px solid #D6D3D1' }}></Box>
                                {handleTabs()}
                                <Box className='calender-box' style={{ paddingInline: '42px', maxWidth: "364px" }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            open
                                            label="Select Date"
                                            value={this.state.currentDate}
                                            data-test-id="DatePickerTestId"
                                            onChange={this.handleDateChange}
                                            dayOfWeekFormatter={dayOfWeekFormatter}
                                            className="datePicker"
                                            disablePast
                                            slots={{
                                                day: (dateProps) => {
                                                    const { day, outsideCurrentMonth, ...otherProps } = dateProps;
                                                    const isSelected = this.isDateSelected(day);

                                                    return (
                                                        <PickersDay
                                                            {...otherProps}
                                                            day={day}
                                                            outsideCurrentMonth={outsideCurrentMonth}
                                                            selected={isSelected}
                                                            onClick={() => this.handleDateChange(day)}
                                                        />
                                                    );
                                                },
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    variant: "outlined"
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box style={{ paddingInline: "42px", maxWidth: "364px" }}>
                                    <Typography style={{ fontWeight: 700, marginTop: "390px", fontSize: "20px", fontFamily: "poppins", }}>
                                        Next Timeslots Available:
                                    </Typography>
                                    <Box style={{}}>
                                        <Typography style={{ marginTop: "20px", fontWeight: 700, fontFamily: "16px", color: "#2C2524" }}>Wednesday, August 15:</Typography>
                                        <Grid style={{ justifyContent: 'center', gap: "4px", overflow: "scroll", marginTop: "12px" }} container spacing={1}>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>7 - 8 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>8 - 9 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>9 - 10 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>10 - 11 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>11 - 12 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>12 - 1 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>1 - 2 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>2 - 3 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>3 - 4 AM</span></Typography>
                                        </Grid>
                                    </Box>
                                    <Box style={{}}>
                                        <Typography style={{ fontWeight: 700, fontFamily: "16px", marginTop: "20px", color: "#2C2524" }}>Wednesday, August 15:</Typography>
                                        <Grid style={{ justifyContent: 'center', gap: "4px", overflow: "scroll", marginTop: "12px" }} container spacing={1}>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>8 - 9 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>9 - 10 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>10 - 11 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>11 - 12 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>12 - 1 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>1 - 2 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>3 - 4 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>4 - 5 AM</span></Typography>
                                        </Grid>
                                    </Box>
                                    <Box style={{}}>
                                        <Typography style={{ marginTop: "20px", color: "#2C2524", fontWeight: 700, fontFamily: "16px" }}>Wednesday, August 15:</Typography>
                                        <Grid style={{ justifyContent: 'center', gap: "4px", overflow: "scroll", marginTop: "12px" }} container spacing={1}>

                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>9 - 10 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>10 - 11 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>11 - 12 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>12 - 1 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>1 - 2 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>3 - 4 AM</span></Typography>
                                            <Typography sx={webStyles.timeSlots}><span style={{ position: 'relative', top: 2 }}>4 - 5 AM</span></Typography>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </section>
                </>
            </StyledWrapper >

        );
        // Customizable Area End
    }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    '& .leftLine': {
        backgroundColor: "#1A4A42",
        width: 32,
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99999,
        borderRadius: '0px 16px 16px 0px'
    },
    "& .MuiTabs-indicator": { display: "none" },
    '& .MuiStepLabel-label': {
        fontWeight: 700,
        fontSize: '16px !important',
        fontFamily: "Poppins",
    },
    "& .MuiTab-root.Mui-selected": { color: "#E46760" },
    "& .containerStyles": {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        minHeight: "78px",
        margin: "auto",
        flex: 1,
        backgroundcolor: "#F5F5F4",
    },
    '& .MuiStep-root.MuiStep-horizontal': {
        minWidth: 'max-content'
    },
    '& .MuiStepConnector-horizontal': {
        top: '15px !important',
    },
    "& .datePicker": {
        width: "100%",
        maxWidth: 425,
        visibility: 'hidden',
        marginTop: -50,
    },
    "& .filtericon": {
        position: "relative"
    },
    "& .filtericon .MuiSvgIcon-root": {
        backgroundColor: "#1A4A42",
        color: "#FFFFFF",
        borderRadius: "16px",
        height: "24px",
        width: "24px",
        padding: 10
    },
    "& .MuiBadge-badge": {
        color: "#E46760",
        backgroundColor: "#E46760",
        top: 3,
        right: 3,
        minWidth: 16,
        width: 16,
        height: 16
    },
    "& .MuiStepIcon-root.Mui-active": {
        color: "#E46760",
        height: "32px",
        width: "32px"
    },
    "& .MuiStepIcon-root.Mui-completed": {
        color: "#1A4A42",
        height: "32px",
        width: "32px"
    },
    "& .header": {
        height: "auto",
        minHeight: "78px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff"
    },
    "& .logoImg": {
        marginLeft: "144px",
        width: "162px",
        height: "41.24px"
    },
    "& .contentWrapper": {
        width: '100%',
        maxWidth: "560px",
        marginTop: "55.92px",
        margin: "55.92px auto auto auto",
    },

    "& .content": {
        display: "flex",
        width: "100%",
        height: "auto",
        flex: 1
    },



    "& .rightSide": {
        height: "auto",
        width: "100%",
        maxWidth: '33%',
        backgroundColor: '#FFF'
    },

    "& .leftSide": {
        height: "auto",
        width: "100%",
        maxWidth: '67%',
        textAlign: "center",
        padding: "32px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F5F5F4",
        "& .image": {
            width: "100%",
            height: "auto",
            maxWidth: "373.89px",
            maxHeight: "337.08px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flex: 1
        },
        '& .stepper-width': {
            maxWidth: "600px",
            width: '100%',
            fontfamily: "Poppins",
            margin: "0 auto"
        }
    },

    '& .tab-inner-div': {
        minHeight: 40,
        height: 40,
        '& > div > div': {
            justifyContent: 'space-between'
        }
    },

    "& .secondarybtn": {
        display: "flex",
        justifyContent: "center",
        lineHeight: "23.48px",
        letterSpacing: "1px",
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "20px",
        color: "#E46760",
        border: "none",
        background: "none",
        cursor: "pointer",
        marginTop: '33px'
    },

    '& .tab-inner-div button': {
        padding: '8px',
        minHeight: 40,
        height: 40
    },
    "& .paragraph": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#FFFFFF",
        margin: 0,
        "& p": {
            margin: 0
        }
    },
    "& .headingRight": {
        fontFamily: "Raleway",
        color: "#2C2524",
        fontWeight: 700,
        fontSize: "50px",
        lineHeight: "60px",
        letterSpacing: "0.5px",
        textAlign: "center",
        marginBottom: "16px",
        marginTop: "0"
    },
    "& .heading": {
        fontFamily: "Raleway",
        color: "#FFFFFF",
        fontWeight: 700,
        fontSize: "50px",
        lineHeight: "60px",
        letterSpacing: "0.5px",
        textAlign: "center",
        marginBottom: "16px",
        marginTop: "0"
    },
    "& .button": {
        width: "100%",
        maxWidth: "540px",
        display: "flex",
        justifyContent: "center",
        textDecoration: "none",
        borderRadius: "24px",
        backgroundColor: "#E46760",
        color: "#FFFFFF",
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "23.48px",
        padding: "16.5px 0",
        border: "none",
        cursor: "pointer",
        margin: "46px auto 0",
    },

    "& .paragraphRight": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#2C2524",
        margin: 0,
        "& p": {
            margin: 0
        }
    },



    "@media screen and (max-width: 1480px)": {
        "& .logoImg": {
            marginLeft: "30px",
        },
        "& .headingRight": {
            fontSize: "40px",
        },
        "& .paragraph": {
            fontSize: "18px",
        },
        "& .heading": {
            fontSize: "40px",
        },
        "& .paragraphRight": {
            fontSize: "18px",
        },
    },

    '@media(max-width: 1440px)': {
        '& .padding-class': {
            paddingLeft: '24px !important'
        },
        "& .logoImg": {
            paddingLeft: "24px",
        },
        '& .card-profile': {
            minWidth: '100px !important',
            width: '100px !important',
            height: '100px !important',
        },

        '& .time-box': {
            padding: 'unset !important',
            maxWidth: 'unset !important',
        },
        '& .date-box': {
            marginBlock: 32
        },
        '& .back-box': {
            maxWidth: '94%',
        },
        '& .tab-parent-box': {
            margin: '20px 12px !important'
        },
        '& .calender-box': {
            paddingInline: '12px !important'
        }
    },

    '@media (max-width: 1299px)': {
        '& .card-footer': {
            flexDirection: "column",
        },
    },
    '@media (max-width: 1170px)': {
        '& .tab-parent-box': {
            maxWidth: '284px !important'
        },
        '& .tab-inner-div button': {
            fontSize: '13px !important',
            padding: '5px !important'
        },
    },

    "@media screen and (max-width: 1100px)": {
        "& .logoImg": {
            marginLeft: "30px",
        },
        "& .heading": {
            fontSize: "38px",
        },
        "& .paragraphRight": {
            fontSize: "18px",
        }, "& .headingRight": {
            fontSize: "38px",
        },
        "& .paragraph": {
            fontSize: "18px",
        }
    },

    "@media screen and (max-width: 991px)": {
        "& .logoImg": {
            marginLeft: "30px",
        },
        "& .paragraph": {
            fontSize: "16px",
            lineHeight: "1.5",
            letterSpacing: "0.5",
        },
        "& .heading": {
            fontSize: "26px",
        },
        "& .headingRight": {
            fontSize: "26px",
        },
        "& .leftSide": {
            padding: "30px",
        },
        "& .paragraphRight": {
            fontSize: "16px",
            lineHeight: "1.5",
            letterSpacing: "0.5",
        },

    },

    "@media screen and (max-width: 767px)": {
        "& .leftSide": {
            maxWidth: "100%",
        },
        "& .button": {
            fontSize: "18px",
        },
        "& .content": {
            flexWrap: "wrap",
        }
    },
});

const webStyles = {
    root: {
        maxWidth: "291px",
        maxHeight: "280px",
        borderRadius: 16,
        width: '100%',
        boxShadow: "unset",
        border: "1px solid #D6D3D1"
    },
    timeSlots: {
        fontSize: "16px", border: "2px solid #E46760", color: "#E46760", borderRadius: "26px",
        maxWidth: "180px", height: "40px", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "12px"
    },
    accordian: { borderRadius: "26px", boxShadow: "none", backgroundColor: "#E7E5E4" },
    heading: { fontWeight: 700, fontSize: "16px", color: "#2C2524", fontFamily: "Poppins" },
    subHeading: { fontWeight: 400, fontSize: "16px", color: "#57534E", fontFamily: "Poppins" },

    specialities: { fontWeight: "400", fontSize: "13px", padding: "4px 8px", borderRadius: "16px", backgroundColor: "#E9EBED", fontFamily: "Poppins" },
    TimeZone: {
        borderRadius: "50px", fontWeight: "700", fontSize: "16px", color: "#78716C", padding: "12px 10px", backgroundColor: "#F5F5F4"
        , display: "flex",
        gap: "6px",
        alignItems: "center",
        fontFamily: "Poppins",
    },
    datePicker: { width: "100%", height: "344px" },
    filterCard: {
        maxHeight: "344px",
        maxWidth: "348px",
        width: "100%",
        borderRadius: 16,
        boxShadow: "unset",
        border: "1px solid #D6D3D1"
    },
    TimeZoneActive: {
        color: "#E46760", backgroundColor: "#FAE1DF", border: "1px solid #F2B3B0",
        borderRadius: "50px", fontWeight: "700", fontSize: "16px", padding: "12px 10px", display: "flex",
        gap: "6px",
        alignItems: "center",
        fontFamily: "Poppins",
    },
    sessionPrice: { fontWeight: 700, fontSize: "13px", color: "#292524", fontFamily: "Poppins" },
    viewProfile: { color: "#E46760", fontWeight: 400, fontSize: "13px", fontFamily: "Poppins" },
    chooseProvider: { display: "flex", position: 'relative', flexDirection: "row", justifyContent: "center", paddingLeft: "112px", maxWidth: 811, width: '100%', margin: '0 auto' },
    cardName: {
        fontWeight: 700, fontSize: "20px",
        fontFamily: "Poppins"
    },
    LMFT: { fontWeight: 700, fontSize: "13px", color: "#292524", marginTop: "8px", marginBottom: "4px", fontFamily: "Poppins" },
    specialitiesHead: { fontWeight: 400, fontSize: "16px", color: "#2C2524", marginTop: "8px", marginBottom: "4px", fontFamily: "Poppins" },
    disease: { display: "flex", flexWrap: 'wrap', flexDirection: "row", color: "#203447", fontWight: 400, fontSize: "13px", marginBottom: "8px", gap: "2px" },
    disease1: { marginTop: "8px", display: "flex", color: "#203447", fontWight: 400, fontSize: "13px", marginBottom: "8px", gap: "8px" },

    CardFooter: {
        display: "flex",
        flexDirection: "row", justifyContent: 'space-between', padding: "12px 16px", alignItems: "center",
    },
    chooseHeadStyle: {
        fontFamily: "Poppins",
        fontSize: "29px",
        fontWeight: 500,
        padding: "33px 77px 33px 42px",
        color: "#2C2524",
        textAlign: "center" as "center",
        maxWidth: "20vw"
    },
    selectProvider: {
        color: "#E46760",
        border: "2px solid #E46760",
        borderRadius: "26px",
        padding: "4px 10.5px",
        height: 21,
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
    }
};
export default SelectedProvider;
// Customizable Area End