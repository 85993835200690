// Customizable Area Start
import React from "react";
import { Box, Step, StepLabel, Stepper, styled } from "@mui/material";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    stepperSteps: string[],
    bgColor: string,
    color: string,
    activeStep: number,
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CustomStepper extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { stepperSteps, activeStep, bgColor, color } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <StyledWrapper>
                <Box className="stepper">
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {stepperSteps && stepperSteps.map((label, index) => (
                            <Step
                                key={label}
                                sx={{
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: bgColor,
                                    },
                                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                        fill: color,
                                        fontWeight: 700,
                                    },
                                    '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-root': {
                                        borderRadius: '50%',
                                        color: '#1A4A42',
                                    },
                                }}
                            >
                                <StepLabel>
                                    <span style={{
                                        fontFamily: 'Poppins',
                                        fontWeight: 700,
                                        color: index <= activeStep ? '#2C2524' : '#78716C',
                                    }}>
                                        {label}
                                    </span>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </StyledWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    "& .stepper": {
        width: "600px",
        margin: "32px auto 0 auto",
        "@media (max-device-width: 610px)": {
            width: "450px",
        },
        "@media (max-device-width: 490px)": {
            width: "360px",
        },
    },
});
// Customizable Area End
