import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import {
  Box,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
  Button,
  Checkbox,
} from '@mui/material';
import { SearchIcon, CheckboxIcon, Checkbox_checked, Disabled_checkbox } from "../../customform/src/assets";
import { ProfileImage, Card_number_icon, Date_icon, LocationIcon, Security_code_icon, Submit_details_icon, Submitted_details_icon, VisaIcon, MasterCard, ApplePay, AmericanExpressIcon, Maestro, DiscoverIcon, visaIcon } from "./assets"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

// Customizable Area End

import Splitpayments2Controller, {
  Props,
  configJSON,
} from "./Splitpayments2Controller";

export default class Splitpayments2 extends Splitpayments2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleHomeAddressSubHead = () => {
    if (this.isHomeAddressComplete()) {
      return <Typography className="showPolicy">{`${this.state.homeAddress.homeAddress}, ${this.state.homeAddress.cityName}, ${this.state.homeAddress.stateName}`}</Typography>

    }
    else {
      return <Typography className="fill-details-error">Please enter your address details</Typography>
    }
  }

  handleBillingAddressSubHead = () => {
    if (this.isBillingAddressComplete() && this.state.useSameInformation) {
      return <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}><Box style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}><Typography>{this.handleCardNumber()}</Typography>
        <img className="cardIconStyle1" src={visaIcon} /></Box>
        <Box style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
          <Checkbox data-test-id="useSameInformation" checked={this.state.useSameInformation} onClick={this.useSameInformation} icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Disabled_checkbox} style={{ width: "20px", height: "20px" }} />} disabled />
          <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#A8A29E" }}>Use the same as Home Address</Typography>
        </Box><Typography className="DisabledshowPolicy">{`${this.state.BillingAddress.homeAddress}, ${this.state.BillingAddress.cityName}, ${this.state.BillingAddress.stateName}`}</Typography>
      </Box >
    }
    else if (this.isBillingAddressComplete()) {
      return <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Box style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}> <Typography>{this.handleCardNumber()}</Typography>
          <img className="cardIconStyle1" src={visaIcon} /></Box>
        <Typography className="showPolicy">{`${this.state.BillingAddress.homeAddress}, ${this.state.BillingAddress.cityName}, ${this.state.BillingAddress.stateName}`}</Typography></Box >
    }
    else {
      return <Typography className="fill-details-error">Please enter your payment details</Typography>
    }
  }

  handleSubmitClass = () => {
    if (this.isBillingAddressComplete() && this.isHomeAddressComplete() && this.state.checkBox === true) {
      return "Button-style"
    }
    else {
      return "Button-style-disabled"
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        <View style={{ display: "flex", alignItems: "center", marginTop: "32px" }}>
          <Box className="stepper-width">
            <Stepper alternativeLabel activeStep={this.state.activeStep}>
              {this.state.steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box className="heading">
            <Typography sx={styles.headingText}>
              Finalize your Booking
            </Typography>
          </Box>
        </View>
        <Box className="content">
          <Box className="leftSide">
            {this.state.openDetailedHomeAddress ? <Box className="box1">
              <Box style={{ alignItems: "center", textAlign: "start" }} onClick={this.handleCloseHomeAddress}>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <Box className="submit_details">
                    <img className="submit_details_icon" src={this.isHomeAddressComplete() ? Submitted_details_icon : Submit_details_icon} />
                    <Typography className="homeCart">Home Address</Typography>
                  </Box>
                  <KeyboardArrowUp />
                </Box>
                <Typography className="subhomeCart">If you are a snowbird or have two addresses please indicate that within your account settings once you log in</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", paddingInline: "36px" }}><Box className="inputHead"><Box className="security-text" ><Typography className='stateSelect' >State </Typography>
                <Typography className='colorstateSelect' >*</Typography>
              </Box>
                <Box className="input-parent">
                  <input data-test-id="handleStateInHomeAddress" value={this.state.homeAddress.stateName} className='search-input-tag' onChange={(e) => { this.handleHomeAddress(e, "stateName") }} type="text" />
                  <img src={SearchIcon} className="search-icon" alt="" />
                </Box>
              </Box>
                <Box className="inputHead">
                  <Box className="security-text"><Typography className='stateSelect' >Home Address </Typography>
                    <Typography className='colorstateSelect' >*</Typography>
                  </Box>
                  <Box className="input-parent">
                    <input data-test-id="testInputForHomeAddress" value={this.state.homeAddress.homeAddress} className='search-input-tag' type="text" onChange={(e) => this.handleHomeAddress(e, "homeAddress")} />
                    <img src={SearchIcon} className="search-icon" alt="" />
                    <img src={LocationIcon} className="location-icon" alt="" />
                  </Box>
                </Box>
                <Box className="input-parent-without-icon">
                  <input data-test-id="handleHomeInHomeAddress" value={this.state.homeAddress.StreetAddress2} placeholder="Street Address Line 2" className='search-input-tag' type="text" onChange={(e) => this.handleHomeAddress(e, "StreetAddress2")} />
                </Box>
                <Box className="input-header"> <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect' >City </Typography>
                  <Typography className='colorstateSelect' >*</Typography>
                </Box>
                  <Box className="input-parent-without-icon">
                    <input data-test-id="handleCityInHomeAddress" value={this.state.homeAddress.cityName} placeholder="Enter your City" className='search-input-tag' type="text" onChange={(e) => this.handleHomeAddress(e, "cityName")} />
                  </Box>
                </Box>
                  <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect' >Zip Code </Typography>
                    <Typography className='colorstateSelect' >*</Typography>
                  </Box>
                    <Box className="input-parent-without-icon">
                      <input data-test-id="handleZipCodeForHomeAddress" placeholder="000000" maxLength={6} value={this.handleValueForHomeAddressZipCode()} className='search-input-tag' type="number"
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          const inputElement = e.target as HTMLInputElement;
                          inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 6);
                          this.handleZipCodeForHomeAddress(e as React.ChangeEvent<HTMLInputElement>, "zipCode");
                        }} />
                    </Box>
                  </Box>
                </Box>
                <Button className="Button-style" data-test-id="handleCloseHomeAddressTest" onClick={this.handleCloseHomeAddress}>Save Details</Button>
              </Box></Box> : <Box className="box1">
              <Box data-test-id="handleOpenHome" style={{ display: "flex", flexDirection: "row", textAlign: "start", alignItems: "center", justifyContent: "space-between" }} onClick={this.handleOpenHomeAddress}>
                <Box className="submit_details_parent"><Box className="submit_details">
                  <img className="submit_details_icon" src={this.isHomeAddressComplete() ? Submitted_details_icon : Submit_details_icon} />
                  <Typography className="homeCart">Home Address</Typography>
                </Box>
                  <Box className="fill-details">
                    <Typography className="showPolicy">Details:</Typography>
                    {this.handleHomeAddressSubHead()}
                  </Box></Box>
                <KeyboardArrowDown />

              </Box></Box>}
            {this.state.openDetailedBillingAddress ? <Box className="box1">
              <Box style={{ textAlign: "start" }} onClick={this.handleCloseBillingAddress}>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Box className="submit_details">
                    <img className="submit_details_icon" src={this.isBillingAddressComplete() ? Submitted_details_icon : Submit_details_icon} />
                    <Typography className="homeCart">Payment Details</Typography>
                  </Box>
                  <KeyboardArrowUp />
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                  <Typography className="subPaymentCart">Why we ask for your Credit Card Info?</Typography>
                  <Typography className="learnMore">Learn More</Typography>
                </Box>
              </Box>
              <Box style={{ display: "flex", flexDirection: "column", gap: "20px", paddingInline: "36px" }}>
                <Typography className="billingHeader">Credit / Debit Card Details</Typography>
                <Box className="cardIconParentStyle">
                  <img className="cardIconStyle" src={VisaIcon} />
                  <img className="cardIconStyle" src={MasterCard} />
                  <img className="cardIconStyle" src={ApplePay} />
                  <img className="cardIconStyle" src={AmericanExpressIcon} />
                  <img className="cardIconStyle" src={Maestro} />
                  <img className="cardIconStyle" src={DiscoverIcon} />
                </Box>

                <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect' >Card Number </Typography>
                  <Typography className='colorstateSelect' >*</Typography>
                </Box>
                  <Box className="input-parent-without-icon">
                    <input data-test-id="handleCardNumber" placeholder="Placeholder" type="number" className='search-input-tag' value={this.state.cardDetails.cardNumber}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        const inputElement = e.target as HTMLInputElement;
                        inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 16);
                        this.handleCardDetail(e as React.ChangeEvent<HTMLInputElement>, "cardNumber");
                      }} />
                    <img src={Card_number_icon} className="location-icon" alt="" />

                  </Box>
                </Box>
                <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect' >Name on Card </Typography>
                  <Typography className='colorstateSelect' >*</Typography>
                </Box>
                  <Box className="input-parent-without-icon">
                    <input placeholder="Neelam Brar" data-test-id="handleCardName" value={this.state.cardDetails.nameOnCard} className='search-input-tag' type="text" onChange={(e) => { this.handleCardDetail(e, "nameOnCard") }} />
                  </Box>
                </Box>
                <Box className="input-header"> <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect' >Expiration Date </Typography>
                  <Typography className='colorstateSelect' >*</Typography>
                </Box>
                  <Box className="input-parent-without-icon">
                    <input
                      type="text"
                      placeholder="MM / YY"
                      data-test-id="handleExpirationForCard"
                      className='search-input-tag'
                      value={this.state.cardDetails.expirationCode}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        const inputElement = e.target as HTMLInputElement;
                        inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 10);
                        this.handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "expirationCode");
                      }}
                    />
                    <img src={Date_icon} className="location-icon" alt="" />
                  </Box>
                </Box>
                  <Box className="inputHead">
                    <Box className="security-text">
                      <Typography className='stateSelect' >Security Code</Typography>
                      <Typography className='security-subtext' >(CVV) </Typography>
                      <Typography className='colorstateSelect' >*</Typography>
                    </Box>
                    <Box className="input-parent-without-icon">
                      <input type="number" min={0} max={999}
                        data-test-id="handleCVV" value={this.handleValueForCVV()} style={{ letterSpacing: "5px" }} placeholder="000" className='search-input-tag' onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          const inputElement = e.target as HTMLInputElement;
                          inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 3);
                          this.handleCVV(e as React.ChangeEvent<HTMLInputElement>, "securityCode");
                        }} />
                      <img src={Security_code_icon} className="location-icon" alt="" />
                    </Box>
                  </Box>
                </Box></Box>
              <Box style={{ display: "flex", flexDirection: "column", gap: "20px", paddingInline: "36px" }}>
                <Typography className="billingHeader">Billing Information</Typography>
                <Box className="billing-checkbox">
                  <Checkbox data-test-id="useSameInformation" checked={this.state.useSameInformation} onClick={this.useSameInformation} icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Checkbox_checked} style={{ width: "20px", height: "20px" }} />} />
                  <Typography style={{ position: "relative", top: "3px" }}>Use the same information as Home Address</Typography>
                </Box>
                <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect' >State </Typography>
                  <Typography className='colorstateSelect' >*</Typography>
                </Box>

                  <Box className="input-parent">
                    <input data-test-id="handleStateForBilling" value={this.state.BillingAddress.stateName} className='search-input-tag' type="text" onChange={(e) => this.handleBillingAddress(e, "stateName")} />
                    <img src={SearchIcon} className="search-icon" alt="" />
                  </Box>
                </Box>
                <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect' >Home Address </Typography>
                  <Typography className='colorstateSelect' >*</Typography>
                </Box>
                  <Box className="input-parent">
                    <input className='search-input-tag' data-test-id="handleBillingAddress" value={this.state.BillingAddress.homeAddress} type="text" onChange={(e) => this.handleBillingAddress(e, "homeAddress")} />
                    <img src={SearchIcon} className="search-icon" alt="" />
                    <img src={LocationIcon} className="location-icon" alt="" />
                  </Box>
                </Box>
                <Box className="input-parent-without-icon">
                  <input type="text" className='search-input-tag' placeholder="Street Address Line 2" value={this.state.BillingAddress.StreetAddress2} data-test-id="handleStreetForBilling" onChange={(e) => this.handleBillingAddress(e, "StreetAddress2")} />
                </Box>
                <Box className="input-header" > <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect'>City </Typography>
                  <Typography className='colorstateSelect' >*</Typography>
                </Box>
                  <Box className="input-parent-without-icon">
                    <input type="text" placeholder="Enter your City" className='search-input-tag' value={this.state.BillingAddress.cityName} data-test-id="handleCityForBilling" onChange={(e) => this.handleBillingAddress(e, "cityName")} />
                  </Box>
                </Box>
                  <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect' >Zip Code </Typography>
                    <Typography className='colorstateSelect' >*</Typography>
                  </Box>
                    <Box className="input-parent-without-icon">
                      <input placeholder="000000" className='search-input-tag' data-test-id="handleZIPForBilling" maxLength={6} value={this.handleValueForBillingAddressZipCode()} type="number"
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          const inputElement = e.target as HTMLInputElement;
                          inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 6);
                          this.handleZipCodeForBillingAddress(e as React.ChangeEvent<HTMLInputElement>, "zipCode");
                        }} />
                    </Box>
                  </Box>
                </Box>
                <Button className="Button-style" onClick={this.handleCloseBillingAddress}>Save Details</Button></Box>
            </Box> : <Box className="box1">
              <Box data-test-id="testOpenBillingAddress" style={{ display: "flex", flexDirection: "row", textAlign: "start", alignItems: "center", justifyContent: "space-between" }} onClick={this.handleOpenBillingAddress}>
                <Box className="submit_details_parent"> <Box className="submit_details">
                  <img className="submit_details_icon" src={this.isBillingAddressComplete() ? Submitted_details_icon : Submit_details_icon} />
                  <Typography className="homeCart">Payment Details</Typography>
                </Box>
                  <Box className="fill-details">
                    <Typography className="showPolicy">Details:</Typography>
                    {this.handleBillingAddressSubHead()}
                  </Box>
                </Box>

                <KeyboardArrowDown />

              </Box></Box>}
          </Box>
          <Box className='rightSide'>
            <Box className="box2">
              <Box className="orderSummaryMain">
                <Typography className="orderSummary">Order Summary</Typography>
                <Typography className="subhomeCart">Please review your order below and <br />
                  press submit to confirm</Typography>
                <Box className="summary-boxes">
                  <Box className="summaryProfile">
                    <Box className="profile-parent">
                      <Box>
                        <img className="Profile-image-style" src={ProfileImage} />
                      </Box>
                      <Box>
                        <Typography className="showPolicy1">Provider:</Typography>
                        <Typography className="orderSummaryHeader1">Meredith Grey</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="summaryProfile">
                    <Typography className="orderSummaryHeader">Duration:</Typography>
                    <Typography className="orderSummarySubHeader">60 minutes</Typography>
                  </Box>
                  <Box className="summaryProfile">
                    <Typography className="orderSummaryHeader">Start Time:</Typography>
                    <Typography className="orderSummarySubHeader">1:30pm CST</Typography>
                  </Box>
                  <Box className="summaryProfile">
                    <Typography className="orderSummaryHeader">Date:</Typography>
                    <Typography className="orderSummarySubHeader">Wednesday, August 15th, 2024</Typography>
                  </Box>
                </Box>
                <Box className="summary-boxes">
                  <Box className="summaryProfile1">
                    <Typography className="orderSummarySubHeader1">Subtotal</Typography>
                    <Typography className="orderSummarySubHeader1">$150.00</Typography>
                  </Box>
                  <Box className="summaryProfile1">
                    <Typography className="orderSummarySubHeader1">Taxes</Typography>
                    <Typography className="orderSummarySubHeader1">$30.35</Typography>
                  </Box>
                  <Box className="summaryProfile1">
                    <Typography className="orderSummaryHeader1">Total:</Typography>
                    <Typography className="orderSummaryHeader1">$180.35</Typography>
                  </Box>
                </Box>
                <Box className="summary-boxes">
                  <Box className="Coupon-box-parent">
                    <Box className="Coupon-box">
                      <Typography className="orderSummaryHeader2">Do you have a Coupon Code?</Typography>
                      <Typography className="orderSummarySubHeader2">If so, enter it here.</Typography>
                    </Box>
                    <Box>
                      <Box className="input-parent1">
                        <input data-test-id="handleState" className='search-input-tag' placeholder="__________" style={{ textAlign: "center", maxWidth: "192px", width: "100%", letterSpacing: "5px", paddingInline: "10px" }} type="text" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="billing-checkbox-checkbox">
                  <Checkbox data-test-id="testCheckBox" onClick={this.handleCheckBox} icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Checkbox_checked} style={{ width: "20px", height: "20px" }} />} />
                  <Typography className="showPolicy">Accept</Typography>
                  <Typography className="showPolicyAlert">no show policy</Typography>
                </Box>
                <Button data-test-id="testSubmit" className={this.handleSubmitClass()} onClick={this.submitBooking}>Submit</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  '& .stepper-width': {
    maxWidth: "600px",
    paddingInline: "112px",
    width: '100%',
    fontfamily: "Poppins",
    margin: "0 auto",
  },
  "& .fill-details": { display: "flex", flexDirection: "column", gap: "2px" },
  "& .fill-details-error": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#DC2626" },
  "& .submit_details": { display: "flex", flexDirection: "row", gap: "10px", width: "272px", alignItems: "center" },
  "& .submit_details_parent": { display: "flex", flexDirection: "row", gap: "24px" },
  "& .submit_details_icon": { height: "32px", width: "32px" },
  "& .showPolicy": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#2C2524" },
  "& .DisabledshowPolicy": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#A8A29E" },
  "& .showPolicy1": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#2C2524", textAlign: "start" },
  "& .showPolicyAlert": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#E46760", textDecoration: "underline" },
  "& .Coupon-box-parent": { display: "flex", flexDirection: "row", gap: "12px" },
  "& .profile-parent": { display: "flex", flexDirection: "row", gap: "56px" },
  "& .Coupon-box": { display: "flex", flexDirection: "column" },
  "& .summary-boxes": { paddingBlock: "24px", borderBottom: "1px solid #D6D3D1", display: "flex", flexDirection: "column", gap: "12px" },
  "& .orderSummaryMain": { textAlign: "center", paddingInline: "24px" },
  "& .orderSummaryHeader": { fontWeight: 700, fontSize: "16px", fontFamily: "Poppins", color: "#2C2524", maxWidth: "112px", width: "100%", textAlign: "start" },
  "& .cardIconStyle": { height: "30px", width: "50px" },
  "& .cardIconStyle1": { height: "10px", width: "30px", alignItems: "center", position: "relative", bottom: "2px" },

  "& .cardIconParentStyle": { display: "flex", flexDirection: "row", gap: "4px", justifyContent: "end" },
  "& .MuiStepIcon-root.Mui-active": { color: "#E46760", height: "32px", width: "32px" },
  "& .MuiStepIcon-root.Mui-completed": { color: "#1A4A42", height: "32px", width: "32px" },
  "& .orderSummaryHeader1": { fontWeight: 700, fontSize: "20px", fontFamily: "Poppins", color: "#2C2524" },
  "& .orderSummaryHeader2": { fontWeight: 700, fontSize: "16px", fontFamily: "Poppins", color: "#2C2524", textAlign: "start" },
  "& .orderSummarySubHeader": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#78716C" },
  "& .orderSummarySubHeader2": { fontWeight: 400, fontSize: "13px", fontFamily: "Poppins", color: "#78716C", textAlign: "start" },
  "& .orderSummarySubHeader1": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#57534E" },
  "& .orderSummary": { fontWeight: 500, fontSize: "29px", fontFamily: "Poppins", color: "#2C2524" },
  "& .input-parent": { position: 'relative', border: '1px solid #E6C79C', borderRadius: 30, overflow: 'hidden', paddingLeft: 38, paddingBlock: 12 },
  "& .input-parent-without-icon": { position: 'relative', border: '1px solid #E6C79C', borderRadius: 30, overflow: 'hidden', paddingLeft: 16, paddingBlock: 12 },
  "& .input-parent1": { position: 'relative', border: '1px solid #E6C79C', borderRadius: 30, overflow: 'hidden', paddingBlock: 12 },
  "& .input-header": { display: "flex", flexDirection: "row", gap: "10px" },
  "& .billingHeader": { fontWeight: 700, fontSize: "20px", fontFamily: "Poppins", color: "#2C2524", textAlign: "center", borderTop: "1px solid #D6D3D1", paddingTop: "20px", marginTop: "16px" },
  "& .content": { display: "flex", gap: 24, maxWidth: "1230px", margin: "auto", width: "100%", height: "auto", flex: 1, "@media (max-width:960px)": { flexDirection: "column" } },
  "& .search-icon": { width: 24, position: 'absolute', top: 10, left: 10 },
  "& .location-icon": { width: 24, position: 'absolute', top: 10, right: 10 },
  "& .billing-checkbox": { display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" },
  "& .billing-checkbox-checkbox": { display: "flex", flexDirection: "row", gap: "8px", alignItems: "center", marginTop: "24px" },
  "& .Profile-image-style": { height: "56px", width: "56px", borderRadius: "100px" },
  "& .Button-style": { background: "#E46760", color: "#FFFAF6", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", width: '100%', marginTop: 16 },
  '& .Button-style:hover': { background: "#E46760", color: "#FFFAF6" },
  "& .Button-style-disabled": { background: "#E7E5E4", color: "#A8A29E", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", width: '100%', marginTop: 24 },
  "& .Button-style-disabled:hover": { background: "#E7E5E4", color: "#A8A29E" },
  "& .inputHead": { display: "flex", flexDirection: "column", gap: "4px", flex: "1" },
  "& .stateSelect": { textAlign: "start", fontWeight: 700, fontSize: "16px", fontFamily: "Poppins", color: "#2C2524" },
  "& .summaryProfile": { display: "flex", flexDirection: "row" },
  "& .summaryProfile1": { display: "flex", flexDirection: "row", justifyContent: "space-between" },
  "& .colorstateSelect": { textAlign: "start", fontWeight: 700, fontSize: "16px", fontFamily: "Poppins", color: "#DC2626" },
  '& .search-input-tag:hover, .search-input-tag:focus': { border: 'none !important', outline: 'none !important' },
  '& .search-input-tag::placeholder': { fontWeight: "400px", fontSize: "16px", fontFamily: "Poppins", color: "#A8A29E" },

  "& .search-input-tag": { width: '100%', border: 'none', backgroundColor: "transparent" },
  "& .learnMore": { fontWeight: 700, fontSize: "16px", fontFamily: "Poppins", color: "#57534E", textDecoration: "underline" },
  "& .security-text": { display: "flex", flexDirection: "row", gap: "4px", },
  "& .security-subtext": { fontWeight: "400px", fontSize: "16px", fontFamily: "Poppins", color: "#78716C" },
  "& .box1": { display: "flex", flexDirection: "column", gap: "20px", border: "1px solid #A8A29E", borderRadius: "24px", paddingInline: "16px", paddingBlock: "24px", marginBottom: "52px", backgroundColor: "white" },
  "& .box2": { display: "flex", flexDirection: "column", gap: "20px", border: "1px solid #A8A29E", borderRadius: "24px", paddingBlock: "24px", marginBottom: "52px" },
  "& .subStateSelect": { fontWeight: 400, fontSize: "13px", fontFamily: "Poppins", color: "#78716C" },
  "& .homeCart": { fontWeight: 400, fontSize: "24px", fontFamily: "Poppins", color: "#2C2524" },
  "& .subhomeCart": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#78716C" },
  "& .subPaymentCart": { fontWeight: 400, fontSize: "16px", fontFamily: "Poppins", color: "#57534E" },
  "& .rightSide": { height: "auto", width: "100%", maxWidth: '40%', backgroundColor: '#FFF', "@media (max-width:960px)": { maxWidth: "95%", margin: "auto" } },
  "& .leftSide": {
    height: "auto", width: "100%", maxWidth: '60%', textAlign: "center", display: "flex", flexDirection: "column",
    "& .image": {
      width: "100%",
      height: "auto",
      maxWidth: "373.89px",
      maxHeight: "337.08px",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flex: 1
    },
    '& .stepper-width': {
      maxWidth: "600px",
      width: '100%',
      fontfamily: "Poppins",
      margin: "0 auto"
    },
    "@media (max-width:960px)": { maxWidth: "95%", margin: "auto" }
  },
  "& .heading": { paddingBlock: "36px 28px", justifyContent: "center" },
  "& .MuiCheckbox-root.Mui-checked": { color: "#1A4A42", height: "20px", width: "20px" },
  "& .MuiCheckbox-root": { height: "20px", width: "20px" },
  "& .MuiStepIcon-root.MuiStepIcon-completed": { color: "#1A4A42", height: "32px", width: "32px" },
  '& .MuiStep-root.MuiStep-horizontal': { minWidth: 'max-content' },
  '& .MuiStepConnector-horizontal': { top: '15px !important', },
  "& .MuiTab-root.Mui-selected": { color: "#E46760" },
});
const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",
  },
  headingText: { fontWeight: "600", fontSize: 41, fontFamily: "Raleway", color: "#2C2524", },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End
