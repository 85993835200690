// Customizable Area Start
import React from "react";
import { Box, FormControlLabel, Radio, RadioGroup, styled, Typography, Autocomplete, Button, Checkbox, TextField, Dialog, DialogContent } from "@mui/material";
import WaitListController, { Props, configJSON } from "./WaitListController.web";
import CustomStepper from "../components/CustomStepper.web";
import CareTeamHeader from "../components/CareTeamHeader";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { successImage2 } from "../assets";
// Customizable Area End

export default class WaitList extends WaitListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderRadioGroup = (name: string, options: string[], selectedValue: string, onChangeHandler: (value: string) => void) => {
    return (
      <RadioGroup
        data-test-id={`${name}RadioChange`}
        name={`${name}RadioTest`}
        value={selectedValue}
        style={{ marginLeft: "8px" }}
        onChange={(event) => onChangeHandler(event.target.value)}
      >
        {options && options.map((option: string) => (
          <FormControlLabel
            key={option}
            className="radioFormControl"
            value={option}
            control={
              <Radio
                className="radio"
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                value={option}
              />
            }
            label={<Typography className="radioFormLabel">{option}</Typography>}
          />
        ))}
      </RadioGroup>
    );
  };

  renderLabeledRadioGroup = (label: string, name: any, options: string[]) => {
    return (
      <Box>
        <Typography className="radioMainTitle">{label}</Typography>
        <Box>
          {this.renderRadioGroup(
            name,
            options,
            this.state.selectedRadios[name],
            (value: string) => this.onRadioChange(name, value)
          )}
        </Box>
      </Box>
    );
  };

  renderCheckboxOption = (label: string, value: string) => {
    return (
      <FormControlLabel
        data-test-id="dayFormControlLabel"
        style={{ width: "30%", }}
        control={
          <Checkbox
            data-test-id="dayCheckboxChange"
            value={value}
            checked={this.state.schedule[value].checked}
            onChange={() => this.toggleDay(value)}
            sx={{ padding: 0 }}
            style={{ marginRight: "8px", marginLeft: "10px" }}
            icon={<span className="uncheckCheckbox" />}
            checkedIcon={
              <span className="checkedCheckbox">
                <CheckIcon style={{ color: "white", fontSize: "16px" }} />
              </span>
            }
            disabled={this.state.anyTimeCheckBox}
          />
        }
        label={<Typography className="checkboxLabelDays" style={{ color: this.state.schedule[value].checked ? "#2C2524" : "#807C7C" }}>{label}</Typography>}
      />
    );
  };


  renderSchedule = () => {
    return Object.keys(this.state.schedule).map((day, dayIndex) => {
      const { checked, startTime, endTime } = this.state.schedule[day];

      return (
        <Box key={day} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {this.renderCheckboxOption(day, day)}

          <Box style={{
            display: "flex",
            width: "70%",
            alignItems: "center",
            gap: "6px",
          }}>
            <Autocomplete
              className={this.getClassName(checked)}
              data-test-id="TestAutoComplete"
              options={this.state.timeOptions}
              value={startTime}
              onChange={(event, newValue) => this.handleTimeChange(day, "startTime", newValue)}
              disablePortal
              disabled={!checked}
              sx={{ width: "100%" }}
              popupIcon={<KeyboardArrowDownIcon style={{ fontSize: "24px" }} />}
              renderInput={(params) => <TextField {...params} placeholder="8 AM" />}
            />

            <Typography className={this.getDashClass(checked)}></Typography>

            <Autocomplete
              className={this.getClassName(checked)}
              data-test-id="TestAutoCompleteEndTime"
              options={this.state.timeOptions.filter(time => !this.getDisabledTimes(startTime).includes(time))}
              disablePortal
              onChange={(event, newValue) => this.handleTimeChange(day, "endTime", newValue)}
              value={endTime}
              disabled={!checked || !startTime}
              sx={{ width: "100%" }}
              popupIcon={<KeyboardArrowDownIcon style={{ fontSize: "24px" }} />}
              renderInput={(params) => <TextField {...params} placeholder="8 PM" />}
            />
          </Box>
        </Box>
      );
    });
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box ref={this.pageTopRef}>
        <SidebarLine />
        <CustomWrapper>
          <CareTeamHeader />
          <CustomStepper
            activeStep={2}
            color="#FFFFFF"
            bgColor="#E46760"
            stepperSteps={this.state.stepperStep}
          />
          <Box className="headingBox">
            <Typography className="heading">{this.state.heading}</Typography>
            <Typography className="subHeading">{this.state.subHeading}</Typography>
            <Typography className="pleaseTellHeading">{this.getHeading()}</Typography>
          </Box>

          <Box className="providerSelectionBox">
            <Typography className="providerRef">{this.getProviderRefText()}</Typography>
            <Box className="providerSelection">
              {
                this.state.pageNo === 1
                  ? <>
                    <Box className="selectionBox">
                      {this.renderLabeledRadioGroup("Gender", "gender", this.state.genderList,)}
                      {this.renderLabeledRadioGroup("Specialties", "specialist", this.state.specialtiesList,)}
                      {this.renderLabeledRadioGroup("Type of Therapy", "typeOfTherapy", this.state.typeOfTherapyList,)}
                    </Box>
                    <Box className="anythingElseBox">
                      <Typography className="anythingElseTxt">{configJSON.anythingElseTxt}</Typography>
                      <Box className="textAreaBox">
                        <textarea
                          className='textarea-div'
                          onChange={(event) => this.handleInputChange(event.target.value)}
                          value={this.state.anythingElseInput}
                          maxLength={500}
                          data-test-id="handleChangeText"
                        />
                      </Box>
                      <Typography className="inputWordCount">
                        {this.state.anythingElseInput.length}/500
                      </Typography>
                    </Box>
                  </>
                  : <>
                    <Typography className="centralTimeTxt">{configJSON.centralTimeTxt} <span className="utcTxt">{configJSON.utcTxt}</span></Typography>
                    <FormControlLabel
                      data-test-id="changeAnyTimeControlLabel"
                      className="formControl"
                      control={
                        <Checkbox
                          data-test-id="changeAnyTimeTest"
                          value={this.state.anyTimeCheckBox}
                          onChange={this.handleAnyTimeChange}
                          sx={{ padding: 0 }}
                          style={{ marginRight: "8px", marginLeft: "10px", }}
                          icon={<span className="uncheckCheckbox" />}
                          checkedIcon={
                            <span className="checkedCheckbox" >
                              <CheckIcon style={{ color: "white", fontSize: "16px" }} />
                            </span>
                          }
                        />
                      }
                      label={<Typography className="checkboxLabel">{configJSON.anyTimeTxt}</Typography>}
                    />

                    <Box>
                      <Box style={{ marginTop: "32px", display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gap: 12 }}>
                        {this.renderSchedule()}
                      </Box>
                    </Box>
                  </>
              }
            </Box>
          </Box>

          <Box className={`nextBackBtnBox ${this.state.pageNo === 2 ? "pageTwoMargin" : ""}`}>
            <Typography className="backBtnTxt" data-test-id="handleBackBtn" onClick={this.handleBack}>{configJSON.backText}</Typography>
            <Button
              className={this.getNextBtnClass()}
              disabled={this.getNextButtonDisabledState()}
              data-test-id="nextBtnTest"
              onClick={this.handleNext}
            >
              {this.state.pageNo === 1 ? configJSON.nextBtnTxt : configJSON.submitTxt}
            </Button>
          </Box>
        </CustomWrapper>

        <StyledDialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isModalOpen}
        >
          <DialogContent dividers>
            <Typography className="modalTitle">{configJSON.youAreOnWaitListTxt}</Typography>
            <img className="modalSuccessImage" src={successImage2} alt="" />
            <Typography className="willLetYouKnowTxt">{configJSON.willLetYouKnowTxt}</Typography>
            <Button className="loginBtn">{configJSON.loginTxt}</Button>
          </DialogContent>
        </StyledDialog>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  paddingBottom: 70,
  boxSizing: "border-box",
  marginLeft: "32px",
  "& .headingBox": {
    width: "fit-content",
    margin: "60px auto auto auto",
    textAlign: "center",
  },
  "& .heading": {
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "50px",
    lineHeight: "60px",
    letterSpacing: "0.5px",
    color: "#2C2524",
  },
  "& .subHeading": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0px",
    marginTop: "24px",
    color: "#2C2524",
  },
  "& .pleaseTellHeading": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0px",
    color: "#2C2524",
  },
  "& .providerSelectionBox": {
    width: "100%",
    margin: "60px auto auto auto",
  },
  "& .selectionBox": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 24,
  },
  "& .providerRef": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0px",
    color: "#2C2524",
    textAlign: "center",
  },
  "& .providerSelection": {
    width: "40%",
    margin: "auto",
    border: "1px solid #A8A29E",
    backgroundColor: "#ffffff",
    marginTop: "8px",
    borderRadius: "24px",
    padding: "32px 32px",
  },
  "& .radioMainTitle": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
    color: "#2C2524",

  },
  "& .radioFormControl": {
    width: "fit-content",
    marginTop: "8px",
  },
  "& .radio": {
    marginLeft: "2px",
    padding: "0",
    marginRight: "8px",
    color: "#78716C",
  },
  "& .radioFormLabel": {
    color: "#2C2524",
    fontFamily: "Poppins",
    fontSize: "16px",
    width: "fit-content",
    lineHight: "24px",
    letterSpacing: "0px",
    fontWeight: "400",
  },
  "& .anythingElseBox": {
    marginTop: "32px",
  },
  "& .anythingElseTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
    color: "#2C2524",
  },
  "& .textarea-div": {
    marginTop: "8px",
    padding: "0",
    border: "none",
    width: "100%",
    height: "100px",
    fontWeight: 400,
    fontSize: "13px",
    fontFamily: "Poppins",
    resize: "vertical",
  },
  "& .textarea-div:focus-visible": {
    outline: 'none',
  },
  "& .textAreaBox": {
    borderRadius: "8px",
    border: "1px solid #D6D3D1",
    padding: "0 8px 8px 8px",
    marginTop: "8px",
  },
  "& .inputWordCount": {
    textAlign: "right",
    fontWeight: "400",
    fontSize: "12px",
    color: "#44403C",
    lineHeight: "18px",
    marginTop: "2px",
  },
  "& .nextBackBtnBox": {
    width: "80%",
    position: "relative",
    boxSizing: "border-box",
    margin: "58px auto 88px auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-device-width: 980px)": {
      width: "100%",
      padding: "0 15px 0 15px",
    },
    "@media (max-device-width: 840px)": {
      margin: "48px auto 48px auto",
    },
    "@media (max-device-width: 745px)": {
      display: "grid",
      padding: "0",
      gridTemplateRows: "repeat(1, 1fr)",
      gap: 20,
      width: "fit-content",
    },
    "@media (max-device-width: 490px)": {
      margin: "30px auto 30px auto",
    },
  },
  "& .pageTwoMargin": {
    marginTop: "104px !important",
    "@media (max-device-width: 980px)": {
      marginTop: "84px !important",
    },
    "@media (max-device-width: 840px)": {
      marginTop: "74px !important",
    },
    "@media (max-device-width: 745px)": {
      marginTop: "64px !important",
    },
    "@media (max-device-width: 490px)": {
      marginTop: "50px !important",
    },
  },
  "& .backBtnTxt": {
    fontSize: "20px",
    lineHeight: "23.48px",
    color: "#78716C",
    letterSpacing: "1px",
    fontWeight: 700,
    fontFamily: "Raleway",
    cursor: "pointer",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 745px)": {
      margin: "auto",
    },
  },
  "& .nextBtnText": {
    order: "-1",
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    cursor: "pointer",
    fontSize: "20px",
    letterSpacing: "1px",
    padding: "16.5px 207px",
    position: "absolute",
    left: "50%",
    borderRadius: "24px",
    transform: "translateX(-50%)",
    fontWeight: "700",
    textTransform: "capitalize",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
      padding: "10px 120px",
    },
    "@media (max-device-width: 716px)": {
      margin: "auto",
      position: "static",
      transform: "none",
      left: "unset",
    },
  },
  "& .nextBtnActive": {
    backgroundColor: "#E46760",
    color: "#FFFAF6",
    "&:hover": {
      backgroundColor: "#B6524D",
    },
  },
  "& .nextBtnInactive": {
    backgroundColor: "#D6D3D1",
    color: "#78716C",
  },
  "& .centralTimeTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
    color: "#A8A29E",
  },
  "& .utcTxt": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
    marginLeft: "8px",
  },
  "& .formControl": {
    width: "fit-content",
    marginTop: "26px",
  },
  "& .uncheckCheckbox": {
    height: "20px",
    width: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #78716C",
    borderRadius: "6px",
  },
  "& .checkedCheckbox": {
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1A4A42",
    border: "1px solid #1A4A42",
    borderRadius: "6px",
  },
  "& .checkboxLabel": {
    fontSize: "20px",
    fontFamily: "Poppins",
    color: "#2C2524",
    lineHeight: "30px",
    fontWeight: 400,
  },
  "& .checkboxLabelDays": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
  },

  "& .MuiAutocomplete-clearIndicator": { display: "none" },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    borderRadius: 24,
    border: "1px solid #E6C79C",
    textAlign: "center",
    padding: '3.5px 4px 1px 10px',
  },
  "& .Disabled .MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input": {
    letterSpacing: "0px",
    paddingRight: 65,
    color: "#D6D3D1",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    fontFamily: "Poppins",
  },
  "& .Enabled .MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input": {
    color: "#2C2524",
    paddingRight: 65,
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    letterSpacing: "0px",
    fontFamily: "Poppins",
  },
  "& .dashEnable": {
    lineHeight: "30px",
    width: "12px",
    border: "1px solid #2C2524",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    letterSpacing: "0px",
  },
  "& .dashDisabled": {
    letterSpacing: "0px",
    border: "1px solid #D6D3D1",
    width: "12px",
    fontFamily: "Poppins",
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 400,
  },
  '& .MuiAutocomplete-root .MuiOutlinedInput-root, .MuiInputBase-input.MuiOutlinedInput-input': {
    paddingRight: '15px !important',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const BpIcon = styled('span')(({ theme }) => ({
  height: 20,
  width: 20,
  borderRadius: '50%',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  backgroundColor: '#f5f8fa',
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  '.Mui-focusVisible &': {
    outlineOffset: 2,
    outline: '2px auto rgba(19,124,189,.6)',
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    background: 'rgba(206,217,224,.5)',
    boxShadow: 'none',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    backgroundColor: '#394b59',
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  backgroundColor: '#1A4A42',
  '&::before': {
    height: 20,
    width: 20,
    display: 'block',
    content: '""',
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
  },
  'input:hover ~ &': {
    backgroundColor: '#1A4A42',
  },
});

const StyledDialog = styled(Dialog)({
  textAlign: "center",
  "& .MuiDialog-paper": {
    width: '600px',
    borderRadius: "24px",
    boxSizing: 'border-box',
  },
  '& .MuiDialogContent-root': {
    paddingTop: '32px',
    paddingBottom: '32px',
  },
  "& .modalTitle": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "29px",
    lineHeight: "40.6px",
    letterSpacing: "0.5px",
  },
  "& .modalSuccessImage": {
    marginTop: "40px",

  },
  "& .willLetYouKnowTxt": {
    width: "468px",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
    marginTop: "40px",

  },
  "& .loginBtn": {
    marginTop: "72px",
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    cursor: "pointer",
    fontSize: "20px",
    letterSpacing: "1px",
    padding: "16.5px 167px",
    borderRadius: "24px",
    fontWeight: "700",
    textTransform: "capitalize",
    backgroundColor: "#E46760",
    color: "#FFFAF6",
    "&:hover": {
      backgroundColor: "#B6524D",
    },
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
      padding: "10px 120px",
    },
    "@media (max-device-width: 716px)": {
      margin: "auto",
      position: "static",
      transform: "none",
      left: "unset",
    },
  },

});

const SidebarLine = styled(Box)({
  width: "32px",
  backgroundColor: "#1A4A42",
  height: "100vh",
  position: "fixed",
});
// Customizable Area End
