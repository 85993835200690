// Customizable Area Start
import React from "react";
import { Box, styled, Typography } from "@material-ui/core";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { callLogo } from "../../../cfemploymentcontractconsole2/src/assets";
import { warningIcon } from "../assets";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class RegistrationFooter extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <StyledWrapper>
                <Box className="container">
                    <Box className="leftBox">
                        <img src={warningIcon} alt="" />
                        <Typography className="leftBoxText">If this is an emergency</Typography>
                        <Typography className="leftBoxSpan">please call 911</Typography>
                    </Box>
                    <Box className="rightBox">
                        <Typography className='needHelpText'>Need Help?</Typography>
                        <Box className='scheduleCallBox'>
                            <Typography className='scheduleCallText'>Schedule a Call</Typography>
                            <img src={callLogo} alt="" />
                        </Box>
                    </Box>
                </Box>
            </StyledWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    padding: "26px 0 26px 0",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    "@media (max-device-width: 980px)": {
        padding: "26px 15px 26px 15px",
    },
    "@media (max-device-width: 784px)": {
        padding: "15px 10px 15px 10px",
    },
    borderTop: "1px solid #E7E5E4",
    "& .container": {
        width: "80%",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        "@media (max-device-width: 980px)": {
            width: "100%",
        },
        "@media (max-device-width: 716px)": {
            flexDirection: "column",
            gap: 20,
        },
    },
    "& .leftBox": {
        display: "flex",
        alignItems: "center",
    },
    "& .leftBoxText": {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        color: "#2C2524",
        margin: "0 12px 0 12px",
        "@media (max-device-width: 980px)": {
            fontSize: "18px",
        },
        "@media (max-device-width: 784px)": {
            fontSize: "16px",
        },
        "@media (max-device-width: 460px)": {
            fontSize: "14px",
        },
    },
    "& .leftBoxSpan": {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        color: "#D97706",
        textDecoration: "underline",
        "@media (max-device-width: 980px)": {
            fontSize: "18px",
        },
        "@media (max-device-width: 784px)": {
            fontSize: "16px",
        },
        "@media (max-device-width: 460px)": {
            fontSize: "14px",
        },
    },
    "& .rightBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    "& .needHelpText": {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "30px",
        color: "#2C2524",
        marginRight: "12px",
        "@media (max-device-width: 980px)": {
            fontSize: "18px",
        },
        "@media (max-device-width: 784px)": {
            fontSize: "16px",
        },
        "@media (max-device-width: 460px)": {
            fontSize: "14px",
        },
    },
    "& .scheduleCallBox": {
        display: "flex",
        alignItems: "center",
        border: "2px solid #E46760",
        padding: "10px 16px 10px 16px",
        borderRadius: "16px",
        cursor: "pointer",
    },
    "& .scheduleCallText": {
        fontFamily: "Raleway",
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "23.48px",
        letterSpacing: "1px",
        color: "#E46760",
        marginRight: "8px",
        "@media (max-device-width: 980px)": {
            fontSize: "18px",
        },
        "@media (max-device-width: 784px)": {
            fontSize: "16px",
        },
        "@media (max-device-width: 460px)": {
            fontSize: "14px",
        },
    },
})
// Customizable Area End
