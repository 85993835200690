Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.putApiMethod = "PUT"
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.getApiMethod="GET"
exports.validationApiMethodType = "GET";
exports.representativeEndPoint = "bx_block_landingpage2/landing_page_questions/get_therapy_signup_form_stage_a";
exports.authorisedApiEndPoint = "account_block/authorized_representatives"
exports.AuthorizedRepresentative = "account_block/authorized_representatives";
exports.PatientDetails = "account_block/patients";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcustomonboardingflowcreator1";
exports.labelBodyText = "cfcustomonboardingflowcreator1 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getQuestionStatsEndpoint = "bx_block_landingpage2/landing_page_questions/get_variant_form_data";
exports.signInPatient = "account_block/accounts"
exports.genCoverage = "coverage"
exports.getStateApiEndPoint = "account_block/patients/states"
exports.postTherapyDataApiEndPoint = "account_block/patients"
exports.postAPiMethod = "POST";
exports.putApiMethod = "PUT";
exports.howAreYouFeelingApiEndp = "bx_block_landingpage2/landing_page_questions/get_multiple_page_instance";
exports.patientCreateEndpoint="/account_block/patients"
exports.patientState="/account_block/patients/states"
exports.getDiagnoseListEndpoint = "account_block/health_reviews/get_health_review";
exports.submitDianoseListEndpoint = "account_block/health_reviews/save_patient_health_review_options";
exports.getSubmittedDiagnoseListEndpoint = "account_block/health_reviews/show_patient_selected_options/";
exports.chooseFollowingHeading = "Choose from the following options"
exports.selectAllSubHeading = "(select all that apply)"
exports.backText = "Back";
exports.continueText = "Continue";
exports.skipThisStepText = "Skip this Step";
exports.AuthorizedCreateEndpoint="/account_block/authorized_representatives"
// Customizable Area End
