// Customizable Area Start
import React from "react";
import { Box, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, styled, TextField, Typography } from "@material-ui/core";
import { Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import OnboardingFormController, { Props, configJSON } from "./OnboardingFormController.web";
import {calendarIcon, callLogo, downloadIcon, leftIcon, locationIcon, searchIcon, successIcon, tickIcon } from "./assets";
import { OnboardingFooter, SidebarLine } from "./ContractReview.web";
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import UploadDocument from "./UploadDocument.web";
import OnboardingHeader from "./OnboardingHeader.web";
// Customizable Area End

export default class OnboardingForm extends OnboardingFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  hanldeDropDowns = (data: any) => {
    return data && data.length > 0 && data.map((option: any) => (
      <MenuItem
        value={option.name}
        key={option.id}
        style={{ color: "#2C2524", fontSize: "16px", fontFamily: "Poppins" }}
      >{option.name}</MenuItem>
    ))
  }

  CustomInputField = (name: string, value: string | number, isImportant: boolean, label: string, placeholder: string,  onChange: any, className: string,) => {
    return (
      <>
        <Box className={className}>
          <InputsLabel>{label} {isImportant && <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol>}</InputsLabel>
          <StyledInputField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            data-test-id={`${name}TestId`}
            name={name}
            value={value}
            onChange={onChange}
          />
        </Box>
      </>
    )
  }

  CustomSelect = (name: string, value: string | boolean, initialValue: string, initialText: string, menuList: {id: number, name: string}[], label: string, optionalText?: string, isImportant?: boolean, ) => {
    return (
      <>
        <Box>
          <InputsLabel>{label} {optionalText && <Typography className="optionalLabel">{optionalText}</Typography>} {isImportant && <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol>}</InputsLabel>
          <SelectField fullWidth>
            <Select
              fullWidth
              style={{ fontSize: "16px", fontWeight: 400, fontFamily: "Poppins",
                color: value === initialValue ? "#A8A29E" : "#2C2524"
              }}
              IconComponent={KeyboardArrowDown}
              variant="outlined"
              value={value}
              onChange={(event) => this.handleSelectchange(event.target.value, name)}
              data-test-id={`${name}TestId`}
              MenuProps={{MenuListProps: { style: { padding: "10px", maxHeight: 398,},},anchorOrigin: { vertical: "bottom", horizontal: "left", }, getContentAnchorEl: null, }}
            >
              <MenuItem value={initialValue} style={{ display: "none", fontSize: "16px", fontFamily: "Poppins", fontWeight: 400 }}>
                {initialText}
              </MenuItem>
              {this.hanldeDropDowns(menuList)}
            </Select>
          </SelectField>
        </Box>
      </>
    )
  }

  renderCheckbox = ( value: string, label: string, checkboxClass: string) => {
    return (
      <FormControlLabel
        data-test-id={`${label}dataTestId`}
        className="formControl"
        control={
          <Checkbox
            value={value}
            style={{ marginRight: "8px" }}
            icon={<span className="checkboxInitial" />}
            sx={{ padding: 0 }}
            checkedIcon={
              <span className="checkedCheckbox">
                <CheckIcon style={{ color: "white", fontSize: "16px" }} />
              </span>
            }
          />
        }
        label={<Typography className={checkboxClass}>{label}</Typography>}
      />
    );
  };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { pageNo, } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <CustomWrapper ref={this.pageTopRef}>
        <SidebarLine />
        <OnboardingHeader />
        {pageNo !== 3 && <><Box className="mainContainer">

          <Box className="topSection">
            <Box>
              <Typography className="topSectionTitle">{pageNo === 1 ? configJSON.generalInformationText : configJSON.financialInformationHeading}</Typography>
              <Typography className="topSectionText">{configJSON.sectionText} {pageNo}</Typography>
            </Box>
            <Typography className="currentPageText">{pageNo} {configJSON.of2Text}</Typography>
          </Box>

          {
            this.state.pageNo === 1 
            ? <Box className="middleSection">
            <TopProgressBar />
            <Typography className="middleSectionHeading">{configJSON.middleSectionHeading}</Typography>
            <Box className="topInputBox">
              <Box>
                <InputsLabel>{configJSON.firstNameLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <StyledInputField 
                  variant="outlined"
                  placeholder={`${configJSON.enterYourText} ${configJSON.firstNameLabel}`}
                  data-test-id="firstNameTestId"
                  name="firstName"
                  value={this.state.formData.firstName}
                  onChange={this.handleOnchange}
                />
              </Box>
              <Box>
                <InputsLabel>{configJSON.middleNameLabel} <Typography className="optionalLabel">{configJSON.ifAppliesLabel}</Typography> </InputsLabel>
                <StyledInputField 
                  variant="outlined"
                  placeholder={`${configJSON.enterYourText} ${configJSON.middleNameLabel}`}
                  data-test-id="middleNameTestId"
                  name="middleName"
                  value={this.state.formData.middleName}
                  onChange={this.handleOnchange}
                />
              </Box>
              <Box>
                <InputsLabel>{configJSON.lastNameLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <StyledInputField 
                  variant="outlined"
                  placeholder={`${configJSON.enterYourText} ${configJSON.lastNameLabel}`}
                  data-test-id="lastNameTestId"
                  name="lastName"
                  value={this.state.formData.lastName}
                  onChange={this.handleOnchange}
                />
              </Box>
            </Box>

            <Box className="margin36Box">
              <InputsLabel>{configJSON.formerNameLabel} <Typography className="optionalLabel">{configJSON.ifAppliesLabel}</Typography></InputsLabel>
              <StyledInputField
                fullWidth
                variant="outlined"
                placeholder={`${configJSON.enterYourText} ${configJSON.formerNameLabel}`}
                data-test-id="formerNameTestId"
                name="formerName"
                value={this.state.formData.formerName}
                onChange={this.handleOnchange}
              />
            </Box>

            <Box className="margin36Box">
              <InputsLabel>{configJSON.preferredNameLabel} <Typography className="optionalLabel">{configJSON.optionalText}</Typography></InputsLabel>
              <StyledInputField
                fullWidth
                variant="outlined"
                placeholder={`${configJSON.enterYourText} ${configJSON.preferredNameLabel}`}
                data-test-id="preferredNameTestId"
                name="preferredName"
                value={this.state.formData.preferredName}
                onChange={this.handleOnchange}
              />
            </Box>
            
            <Box className="genderEthnicityBox">
              <Box className="genderEthnicitySingleBox">
                <InputsLabel>{configJSON.genderLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <SelectField fullWidth>
                  <Select
                    fullWidth
                    style={{ fontSize: "16px", fontWeight: 400, fontFamily: "Poppins",
                      color: this.state.formData.gender === "Select" ? "#A8A29E" : "#2C2524"
                    }}
                    IconComponent={KeyboardArrowDown}
                    variant="outlined"
                    value={this.state.formData.gender}
                    onChange={(event) => this.handleSelectchange(event.target.value, "gender")}
                    data-test-id="genderTestId"
                    MenuProps={{MenuListProps: { style: { padding: "10px", maxHeight: 398,},},anchorOrigin: { vertical: "bottom", horizontal: "left", }, getContentAnchorEl: null, }}
                  >
                    <MenuItem value="Select" style={{ display: "none", fontSize: "16px", fontFamily: "Poppins", fontWeight: 400 }}>
                      Please Select
                    </MenuItem>
                    {this.hanldeDropDowns(this.state.genderList)}
                  </Select>
                </SelectField>
              </Box>

              <Box className="genderEthnicitySingleBox">
                <InputsLabel>{configJSON.ethnicityLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <SelectField fullWidth>
                  <Select
                    fullWidth
                    style={{ fontSize: "16px", fontWeight: 400, fontFamily: "Poppins",
                      color: this.state.formData.ethnicity === "Select" ? "#A8A29E" : "#2C2524"
                    }}
                    IconComponent={KeyboardArrowDown}
                    variant="outlined"
                    value={this.state.formData.ethnicity}
                    onChange={(event) => this.handleSelectchange(event.target.value, "ethnicity")}
                    data-test-id="ethnicityTestId"
                    MenuProps={{MenuListProps: { style: { padding: "10px", maxHeight: 398,},},anchorOrigin: { vertical: "bottom", horizontal: "left", }, getContentAnchorEl: null, }}
                  >
                    <MenuItem value="Select" style={{ display: "none", fontSize: "16px", fontFamily: "Poppins", fontWeight: 400 }}>
                      Please Select
                    </MenuItem>
                    {this.hanldeDropDowns(this.state.ethnicityList)}
                  </Select>
                </SelectField>
              </Box>
            </Box>

            <CustomDivider />

            <Box className="emailContactInputBox">
              <Box>
                <InputsLabel>{configJSON.personalEmailLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <StyledInputField 
                  fullWidth
                  variant="outlined"
                  placeholder={`${configJSON.enterYourText} ${configJSON.personalEmailLabel}`}
                  data-test-id="personalEmailTestId"
                  name="personalEmail"
                  value={this.state.formData.personalEmail}
                  onChange={this.handleOnchange}
                />
              </Box>
              <Box>
                <InputsLabel>{configJSON.contactNumberLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <StyledInputField 
                  fullWidth
                  variant="outlined"
                  placeholder={`${configJSON.contactNumberPlaceholder}`}
                  data-test-id="contactNumberTestId"
                  name="contactNumber"
                  value={this.state.formData.contactNumber}
                  onChange={this.handleOnchange}
                />
              </Box>
            </Box>
            
            <Box className="margin36Box prefContactMethodBox">
              <InputsLabel>{configJSON.prefContactMethodLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
              <Box className="prefContactMethodCheckboxes">
                {this.renderCheckbox("cryon", configJSON.phoneLabel, "checkboxLabel")}
                {this.renderCheckbox("cryon", configJSON.emailLabel, "checkboxLabel")}
                {this.renderCheckbox("cryon", configJSON.smsLabel, "checkboxLabel")}
              </Box>
            </Box>

            <CustomDivider />

            <Box>
              <InputsLabel>{configJSON.homeAddressLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
              <StyledInputField
                fullWidth
                variant="outlined"
                placeholder={`${configJSON.homeAddressPlaceholder}`}
                data-test-id="street1TestId"
                name="street1"
                value={this.state.formData.street1}
                onChange={this.handleOnchange}
                InputProps={{
                  startAdornment: (
                    <img src={searchIcon} alt="" />
                  ),
                  endAdornment: (
                    <img src={locationIcon} alt="" />
                  )
                }}
                inputProps={{ style: { paddingLeft: "8px", }}}
              />
              <StyledInputField
                fullWidth
                variant="outlined"
                placeholder={`${configJSON.streetAddress2Placeholder}`}
                data-test-id="street2TestId"
                name="street2"
                value={this.state.formData.street2}
                onChange={this.handleOnchange}
                className="marginTop16"
              />

              <Box className="cityStateBox">
                <Box>
                  <InputsLabel>{configJSON.cityLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                  <StyledInputField
                    fullWidth
                    variant="outlined"
                    placeholder={`${configJSON.enterYourText} ${configJSON.cityLabel}`}
                    data-test-id="cityTestId"
                    name="city"
                    value={this.state.formData.city}
                    onChange={this.handleOnchange}
                  />
                </Box>
                <Box>
                  <InputsLabel>{configJSON.stateLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                  <StyledInputField
                    fullWidth
                    variant="outlined"
                    placeholder={`${configJSON.statePlaceholder}`}
                    data-test-id="stateTestId"
                    name="state"
                    value={this.state.formData.state}
                    onChange={this.handleOnchange}
                    InputProps={{
                      startAdornment: (
                        <img src={searchIcon} alt="" />
                      ),
                    }}
                    inputProps={{ style: { paddingLeft: "8px", }}}
                  />
                </Box>
                <Box>
                  <InputsLabel className="marginTop16">{configJSON.zipCodeLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                  <StyledInputField
                    fullWidth
                    variant="outlined"
                    placeholder={`${configJSON.zipCodePlaceholder}`}
                    data-test-id="zipCodeTestId"
                    name="zipCode"
                    value={this.state.formData.zipCode}
                    onChange={this.handleOnchange}
                  />
                </Box>
              </Box>
            </Box>

            <CustomDivider />

            <Box className="datesBox">
              <Box>
                <InputsLabel>{configJSON.dateOfBirthLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <StyledInputField
                  fullWidth
                  variant="outlined"
                  placeholder={`${configJSON.dateOfBirthPlaceholder}`}
                  data-test-id="dateOfBirthTestId"
                  name="dateOfBirth"
                  value={this.state.formData.dateOfBirth}
                  onChange={this.handleOnchange}
                  InputProps={{
                    endAdornment: (
                      <img className="calendarIcon" src={calendarIcon} alt="" />
                    ),
                  }}
                />
              </Box>
              <Box>
                <InputsLabel>{configJSON.stateOfBirthLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <StyledInputField
                  fullWidth
                  variant="outlined"
                  placeholder={`${configJSON.stateOfBirthPlaceholder}`}
                  data-test-id="stateOfBirthTestId"
                  name="stateOfBirth"
                  value={this.state.formData.stateOfBirth}
                  onChange={this.handleOnchange}
                  InputProps={{
                    startAdornment: (
                      <img src={searchIcon} alt="" />
                    ),
                  }}
                  inputProps={{ style: { paddingLeft: "8px", }}}
                />
              </Box>
              <Box>
                <InputsLabel className="marginTop16">{configJSON.socialSecurityLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                <StyledInputField
                  fullWidth
                  variant="outlined"
                  placeholder={`${configJSON.socialSecurityPlaceholder}`}
                  data-test-id="socialSecurityNoTestId"
                  name="socialSecurityNo"
                  value={this.state.formData.socialSecurityNo}
                  onChange={this.handleOnchange}
                />
              </Box>
            </Box>

            <CustomDivider />

            <Box className="documentsBox">
              <UploadDocument 
                id='' 
                navigation='' 
                label="Professional Headshot" 
                instruction="** Please use a photo with a plain white background" 
                isMandatory={true}
                name="professionHeadshot"
              />
              <UploadDocument 
                id='' 
                navigation='' 
                label="Government Issued Photo " 
                instruction="** Please provide a photo or scanned document" 
                isMandatory={true}
                name="governmentIssuedPhoto"
              />
              <UploadDocument 
                id='' 
                navigation='' 
                label="Copy of your Resume" 
                instruction="** Word or PDF Format" 
                isMandatory={true}
                name="copyOfResume"
              />
            </Box>
          </Box>
            : <>
              <Box className="middleSection">
                <TopProgressBar />
                <Typography className="middleSectionHeading">{configJSON.middleSectionHeading2}</Typography>
                
                <Box className="areYouBillingBox">
                  <InputsLabel>{configJSON.areYouBillingLabel} <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol></InputsLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    data-test-id="areYouBillingRadioChange"
                    name="areYouBillingRadioValue"
                    value={this.state.formData.areYouBillingRadioValue}
                    style={{marginLeft: "8px"}}
                    onChange={(event) => this.handleSelectchange(event.target.value, "areYouBillingRadioValue")}
                  >
                    <FormControlLabel className="radioFormController" value={configJSON.personalSsnSinLabel} control={<Radio className="radio" />} label={configJSON.personalSsnSinLabel} />
                    <FormControlLabel className="radioFormController" value={configJSON.einBusinessTaxIDLabel} control={<Radio className="radio" />} label={configJSON.einBusinessTaxIDLabel} />
                  </RadioGroup>
                  {this.CustomInputField("companyName", this.state.formData.companyName, true, configJSON.companynameLabel, `${configJSON.enterYourText} ${configJSON.companynameLabel}`, this.handleOnchange, "marginTop12")}
                  {this.CustomInputField("einBusinessTaxID", this.state.formData.einBusinessTaxID, true, configJSON.einBusinessTaxIDLabel, `${configJSON.enterYourText} ${configJSON.einBusinessTaxIDLabel}`, this.handleOnchange, "marginTop12")}
                </Box>
                
                <CustomDivider />
                
                {this.CustomInputField("nameOfBank", this.state.formData.nameOfBank, true, configJSON.nameOfBankLabel, `${configJSON.enterYourText} ${configJSON.nameOfBankLabel}`, this.handleOnchange, "bankAccountDetailBox")}
                {this.CustomInputField("addressOfBank", this.state.formData.addressOfBank, true, configJSON.addressOfBankLabel, `${configJSON.enterYourText} ${configJSON.addressOfBankLabel}`, this.handleOnchange, "margin36Box")}
                {this.CustomInputField("accountHolderName", this.state.formData.accountHolderName, true, configJSON.accountHolderNameLabel, `${configJSON.enterYourText} ${configJSON.accountHolderNameLabel}`, this.handleOnchange, "margin36Box")}

                <Box className="typeOfAccountMainBox">
                  {this.CustomSelect("typeOfAccount", this.state.formData.typeOfAccount, "Select one", "Select one", this.state.typeOfAccountList, configJSON.typeOfAccountLabel, configJSON.typeOfAccountLabelOptional, true)}
                  {this.CustomSelect("typeOfAccountBusiness", this.state.formData.typeOfAccountBusiness, "Select one", "Select one", this.state.typeOfAccountBusinessList, configJSON.typeOfAccountLabel, configJSON.typeOfAccountBusinessLabelOptional, true)}
                  {this.CustomInputField("routingNumber", this.state.formData.routingNumber, true, configJSON.routingNumberLabel, configJSON.routingNumberPlaceholer, this.handleOnchange, "")}
                  {this.CustomInputField("accountNumber", this.state.formData.accountNumber, true, configJSON.accountNumberLabel, configJSON.accountNumberPlaceholer, this.handleOnchange, "")}
                </Box>

                <Box className="documentsBoxPage2">
                  <Box>
                    <InputsLabel>Void Check / </InputsLabel>
                    <UploadDocument 
                      id=''
                      navigation=''
                      label="Banking Information"
                      instruction="** JPEG or PDF Format, Under than 25MB"
                      isMandatory={true}
                      name="voidcheckBankingInformation"
                    />
                    <Box className="downloadBox">
                      <img src={downloadIcon} alt="" />
                      <Typography className="exampleOfDocText">{configJSON.exampleOfVoidCheque}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <InputsLabel>Please fill out and submit a W9</InputsLabel>
                    <UploadDocument 
                      id=''
                      navigation=''
                      label={`Independent Contractor Form`}
                      instruction="** Please provide filled W9 form "
                      isMandatory={true}
                      name="w9IndependentContractForm"
                    />
                    <Box className="downloadBox">
                      <img src={downloadIcon} alt="" />
                      <Typography className="exampleOfDocText">{configJSON.exampleOfW9Form}</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="confirmationBox">
                  {this.renderCheckbox("cryon", configJSON.confirmationMessage, "confirmationCheckboxLabel")}
                </Box>
              </Box>
            </>
          }
          

          <Box className="bottomSection">
            <Typography className="saveAndExitBtn">{configJSON.saveAndExitTxt}</Typography>
            <Box className="rightActoinBtn">
              {pageNo === 2 && <Typography className="previousBtn" data-test-id='btnPrevious' onClick={() => this.handleNext(1)}> <img src={leftIcon} style={{marginRight: "8px"}} alt="icon"/> {configJSON.backText}  </Typography>}
              <Typography className="nextBtn" data-test-id='btnNext' onClick={() => this.handleNext(pageNo === 2 ? 3 : 2)}> {pageNo === 1 ? configJSON.nextText : configJSON.finishText} {pageNo === 1 ? <KeyboardArrowRight style={{marginLeft: "8px"}} /> : <img src={tickIcon} style={{marginLeft: "8px"}} />} </Typography>
            </Box>
          </Box>
        </Box>

        <OnboardingFooter>
            <Typography className='needHelpText'>Need Help?</Typography>
            <Box className='scheduleCallBox'>
                <Typography className='scheduleCallText'>Schedule a Call</Typography>
                <img src={callLogo} alt="" />
            </Box>
        </OnboardingFooter></>}

        <StyledDialog
            onClose={this.handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={this.state.isModalOpen}
            data-test-id='dialogClose'
        >
          <DialogContent dividers>
            <Typography className="modalHeading">{configJSON.modalHeading}</Typography>
            <img className="modalSuccessIcon" src={successIcon} alt="" />
            <Typography className="modalSubHeading">{configJSON.modalSubHeading}</Typography>
            <Typography className="goToPlatformText">{configJSON.goToPlatformText}</Typography>
          </DialogContent>
        </StyledDialog>
      </CustomWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled("div")({
  "& .mainContainer": { 
    backgroundColor: "#FFFFFF",
    width: "808px",
    margin: "118px auto 86px auto",
    boxSizing: "border-box",
    border: "1px solid #D6D3D1",
    borderRadius: "24px",
  },
  "& .topSection": {
    padding: "24px 24px 24px 40px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderBottom: "1px solid #E7E5E4",
  },
  "& .topSectionTitle": {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "36px",
    color: "#2C2524",
  },
  "& .topSectionText": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    color: "#78716C",
    marginTop: "2px",
  },
  "& .currentPageText": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    color: "#44403C",
  },

  "& .middleSection": {
    padding: "0 40px 40px 40px",
    boxSizing: "border-box",
  },
  "& .middleSectionHeading": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: '#78716C',
    marginTop: "24px",
  },
  "& .topInputBox": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: "12px",
    marginTop: "24px",
  },
  "& .optionalLabel": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#78716C",
    marginLeft: "4px",
  },
  "& .margin36Box": {
    marginTop: "36px",
  },
  "& .genderEthnicityBox": {
    width: "100%",
    marginTop: "36px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "16px",
    marginBottom: "36px",
  },
  "& .genderEthnicitySingleBox": {
    width: "100%",
  },
  "& .emailContactInputBox": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "16px",
  },
  "& .marginTop16": {
    marginTop: "16px",
  },
  "& .prefContactMethodBox": {
    marginBottom: "36px",
  },
  "& .prefContactMethodCheckboxes": {
    marginLeft: "8px",
  },
  "& .formControl": {
    marginTop: "4px",
    marginRight: "24px",
    width: "fit-content",
  },
  "& .checkboxInitial": {
    width: "20px",
    display: "flex",
    height: "20px",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #78716C",
    borderRadius: "6px",
  },
  "& .checkedCheckbox": {
    display: "flex",
    width: "20px",
    height: "20px",
    border: "1px solid #1A4A42",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1A4A42",
    borderRadius: "6px",
  },
  "& .checkboxLabel": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: '#0F172A',
    marginLeft: "6px",
    marginRight: "12px",
  },
  "& .cityStateBox": {
    width: "100%",
    marginTop: "16px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "16px",
  },
  "& .calendarIcon": {
    cursor: "pointer",
  },
  "& .datesBox": {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "16px",
    marginBottom: "36",
  },
  "& .documentsBox": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "16px",
  },
  "& .bottomSection": {
    padding: "30px 40px 30px 40px",
    boxSizing: "border-box",
    borderTop: "1px solid #D6D3D1",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .saveAndExitBtn": {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    color: "#78716C",
    cursor: "pointer",
  },
  "& .nextBtn": {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    color: "#FFFFFF",
    padding: "10.5px 52px 10.5px 52px",
    backgroundColor: "#E46760",
    borderRadius: "16px",
    cursor: "pointer",
  },
  "& .areYouBillingBox": {
    marginTop: "24px",
    marginBottom: "36px",
  },
  "& .radioFormController": {
    width: "fit-content",
    marginTop: "16px",
  },
  "& .radio": {
    color:"#1A4A42", 
    padding: "0", 
    marginRight: "6px",
    marginTop: "-4px",
  },
  "& .marginTop12": {
    marginTop: "12px",
  },
  "& .typeOfAccountMainBox": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "16px",
    marginTop: "36px",

  },
  "& .documentsBoxPage2": {
    display: "grid", 
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "16px",
    marginTop: "36px",
  },
  "& .downloadBox": {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    borderRadius: "16px",
    border: "2px solid #E46760",
    padding: "4px 10px 4px 10px",
  },
  "& .exampleOfDocText": {
    color: "#E46760",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginLeft: "6px",
  },
  "& .confirmationBox": {
    width: "100%",
    border: "1px solid #1A4A42",
    padding: "24px 24px 24px 24px",
    borderRadius: "20px",
    marginTop: "60px",
    boxSizing: "border-box",
  },
  "& .confirmationCheckboxLabel": {
    color: "#2C2524",
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 400,
    letterSpacing: "0px",
    fontFamily: "Poppins",
  },
  "& .rightActoinBtn": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  "& .previousBtn": {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E7E5E4",
    borderRadius: "16px",
    padding: "10px 16px 10px 16px",
    marginRight: "20px",
    color: "#A8A29E",
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "23.48px",
    letterSpacing: "1px",
    cursor: "pointer",
  },
});

const CustomDivider = styled(Box)({
  border: "1px solid #E7E5E4",
  width: "100%",
  margin: "24px 0 24px 0",
})

const TopProgressBar = styled(Box)({
  width: "65%",
  height: "6px",
  backgroundColor: "#1A4A42",
})

export const StyledInputField = styled(TextField)({
  marginBottom: "0px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
      borderWidth: "1px",
    },
  },
  "& .MuiInputBase-root":{
    fontFamily: 'Poppins',
    height: '44px',
    margin: 'auto',
    borderRadius: '24px',
    backgroundColor: "#FFFFFF",
    fontSize: '16px',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "24px",
    borderColor: "#E6C79C",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#E6C79C",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: 'Poppins',
    color: '#2C2524',
    padding: '10px 12px 10px 16px',
    "&::placeholder": {
      fontSize: "16px",
      fontFamily: 'Poppins',
      color: '#94A3B8',
    },
    "&:not(:placeholder-shown)": {
      fontFamily: 'Poppins',
      color: '#2C2524',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    color:'red',
    textTransform:'uppercase',
    lineHeight: "19.2px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Poppins",
    color: "#DC2626",
  }, 
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
  
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #FFFFFF",
  },
  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
});

export const InputsLabel = styled(InputLabel)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  color: "#2C2524",
  textTransform: 'capitalize',
  marginBottom: '4px',
  display: "flex",
});

export const ImportantSymbol = styled(Typography)({
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#DC2626",
    marginLeft: "2px",
});

export const SelectField = styled(FormControl)({
  "& .MuiSelect-icon.Mui-disabled":{
      color:'#64748B',
  },
  "& .MuiSelect-icon":{
      color:'#A8A29E'
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
      borderColor: "#E6C79C",
      borderWidth: "1px"
  },
  "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
          borderWidth: "1px",
          borderColor: "#E6C79C",
      },
      "&:hover fieldset": {
          borderColor: "#E6C79C",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#E6C79C",
      },
  },
  "& .MuiOutlinedInput-input": {
      padding: "12px 10px 12px 16px",
  },
  "& .MuiInputBase-input": {
      lineHeight: "19.2px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171"
  },
  "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#E6C79C",
      borderRadius: "24px"
  }
  ,
  "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: "12px",
  },
  "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px"
  },
  '@media (max-width:660px )': {
      minWidth: "unset",
      maxWidth: "unset",
  },
});

export const CustomCheckbox = styled(Checkbox)({
  padding: "0",
  "& .MuiSvgIcon-root": {
    color: "#1A4A42",
  }
})

const StyledDialog = styled(Dialog) ({
  "& .MuiDialog-paper": {
    width: '630px',
    borderRadius: "24px",
    boxSizing: 'border-box',
  },
  "& .MuiDialogContent-root": {
      paddingTop: '48px',
      paddingBottom: '24px',
  },
  "& .modalHeading": {
    width: "fit-content",
    fontFamily: "Poppins",
    fontSize: "29px",
    fontWeight: 500,
    lineHeight: "40.6px",
    letterSpacing: "0.5px",
    color: "#2C2524",
    margin: "auto",
  },
  "& .modalSuccessIcon": {
    display: "block",
    margin: "32px auto 32px auto",

  },
  "& .modalSubHeading": {
    width: "444px",
    margin: "auto",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#2C2524",
  },
  "& .goToPlatformText": {
    width: "fit-content",
    margin: "auto",
    marginTop: "32px",
    backgroundColor: "#1A4A42",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "23.48px",
    letterSpacing: "1px",
    padding: "16.5px 93.5px",
    borderRadius: "24px",
    cursor: "pointer",
  },
});

// Customizable Area End
