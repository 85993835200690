import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../config");

interface GetMatchMakingListType {
  "id": string,
  "type": string,
  "attributes": {
    "title": string,
    "body": string,
    "other_text": string | null,
    "options": GetMatchMakingOption[]
  }
}

interface GetMatchMakingOption {
  "id": number,
  "options_text": string
}

interface SubmittedOptionType {
  "patient_id": number,
  "selected_options": SubmittedOption[],
  "other_text": string,
}

interface SubmittedOption {
  "id": number,
  "option_text": string,
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperSteps: string[],
  optionList: GetMatchMakingOption[],
  selectedOptions: number[],
  submittedList: SubmittedOption[]
  isHovered: boolean,
  isOtherInputEnable: boolean,
  otherInput: string,
  isLoading: boolean,
  heading: string,
  subHeading: string,
  patient_id: number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MatchMakingMultiSelectController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement>;
  getMatchMakingListApiCallId: string = "";
  submitSelectOptionsApiCallId: string = "";
  getSubmittedListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.inputRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepperSteps: ["About You", "Verify Insurance", "Choose Provider"],
      optionList: [],
      selectedOptions: [],
      submittedList: [],
      isHovered: false,
      isOtherInputEnable: false,
      otherInput: "",
      isLoading: false,
      heading: "",
      subHeading: "",
      patient_id: 158,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId) {
        if (apiRequestCallId === this.getMatchMakingListApiCallId) {
          this.receiveMatchMakingList(responseJson);
        } else if (apiRequestCallId === this.submitSelectOptionsApiCallId) {
          this.receiveSubmittedData(responseJson);
        } else if (apiRequestCallId === this.getSubmittedListApiCallId) {
          this.receiveGetSubmittedListData(responseJson);
        }
      }
    }
  }

  receiveMatchMakingList = (responseJson: { data: GetMatchMakingListType[] }) => {
    if (responseJson && responseJson.data) {
      let attributes = responseJson.data && responseJson.data[0] && responseJson.data[0].attributes;

      this.setState({
        heading: attributes.title,
        subHeading: attributes.body,
        optionList: attributes.options,
        isLoading: false,
      })
    } else {
      this.setState({ isLoading: false, })
    }
  };

  receiveSubmittedData = (responseJson: { message: string }) => {
    if (responseJson && responseJson.message === "Health and Wellness updated successfully!") {
      this.handleNavigate("Diagnose")
    }
  };

  receiveGetSubmittedListData = (data: { selected_options: SubmittedOption[], other_text: string }) => {
    if (data && data.selected_options) {
      let selected_options = data.selected_options;
      this.setState((prevState) => ({
        otherInput: data.other_text,
        submittedList: selected_options,
        selectedOptions: [
          ...prevState.selectedOptions,
          ...selected_options.map((item) => item.id),
        ],
      }));
    }
  }

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    this.getMatchMakingList();
    this.getSubmittedList();
  }

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  toggleSelection = (option: number) => {
    this.setState((prevState) => {
      const selected = prevState.selectedOptions.includes(option)
        ? prevState.selectedOptions.filter((item) => item !== option)
        : [...prevState.selectedOptions, option];

      return { selectedOptions: selected }
    });
  };

  getContinueBackgroundColor = () => {
    const { isHovered, selectedOptions } = this.state;
    if (isHovered) {
      return "#B6524D";
    } else if (selectedOptions.length > 0 || (this.state.otherInput && !this.state.isOtherInputEnable)) {
      return "#E46760";
    } else {
      return "#D6D3D1";
    }
  }

  enableOtherInput = () => {
    this.setState({ isOtherInputEnable: true }, () => {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    });
  };

  handleOtherInputChange = (value: string) => {
    this.setState({
      otherInput: value,
    })
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (this.inputRef.current && !this.inputRef.current.contains(event.target as Node)) {
      this.setState({ isOtherInputEnable: false });
    }
  };

  renderLastInputText = () => {
    return this.state.otherInput || configJSON.otherSpecifyText
  }

  getMatchMakingList() {
    this.setState({ isLoading: true });

    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMatchMakingListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.matchMakingGetListEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleContinue = () => {
    if (this.state.selectedOptions.length > 0 || (this.state.otherInput && !this.state.isOtherInputEnable)) {
      this.submitSelectedOptions();
    }
  };

  submitSelectedOptions = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const data = {
      "patient_id": this.state.patient_id,
      "option_ids": this.state.selectedOptions,
      "other_text": this.state.otherInput,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitSelectOptionsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addOptionsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubmittedList() {
    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubmittedListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubmittedListEndpoint}?patient_id=${this.state.patient_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSkip = () => {
    this.setState({
      selectedOptions: [],
      otherInput: "",
    }, () => {
      this.submitSelectedOptions();
    })
  }

  handleBack = () => {
    this.handleNavigate("PatientSignupForm2");
  }

  getOptionStyle = (optionId: number) => {
    const isSelected = this.state.selectedOptions.includes(optionId);
    return {
      backgroundColor: isSelected ? "#E46760" : "#E7E5E4",
      color: isSelected ? "#FFFFFF" : "#44403C",
    };
  };

  getOtherInputStyle = () => {
    return {
      backgroundColor: this.state.otherInput ? "#E46760" : "#E7E5E4",
      color: this.state.otherInput ? "#FFFFFF" : "#44403C",
    };
  };

  getContinueTextColor = () => {
    return (this.state.selectedOptions.length > 0 || (this.state.otherInput && !this.state.isOtherInputEnable))
      ? "#FFFAF6"
      : "#78716C";
  };
  // Customizable Area End
}
