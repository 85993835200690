// Customizable Area Start
import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import FlowDecisionScreenController, { Props, configJSON } from "./FlowDecisionScreenController.web";
import RegistrationFooter from "../components/RegistrationFooter.web";
import CustomStepper from "../components/CustomStepper.web";
import Loader from '../../../../components/src/Loader.web';
// Customizable Area End

export default class FlowDecisionScreen extends FlowDecisionScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <SidebarLine />
        {
          this.state.isLoading ? (
            <Loader loading={this.state.isLoading} />
          ) : (
            <CustomWrapper>
              <CustomStepper
                stepperSteps={this.state.stepperStep}
                bgColor="#E46760"
                color="#FFFFFF"
                activeStep={1}
              />

              {this.state.patient_name && <Typography className="flowDecisionHeading">{this.state.patient_name}, what would you like to do first?</Typography>}

              <Box className="selectMainBox">
                {
                  this.state.flowDecisionData && this.state.flowDecisionData.map((item, index) => {
                    return (
                      <Box className="selectBox" key={item.id}>
                        <Box className="selectBoxTop">
                          <Typography className="selectBoxTitle">{item.attributes.title}</Typography>
                          <Typography className="selectBoxSubTitle">{item.attributes.description}</Typography>
                          <img className="selectBoxImage" src={item.attributes.image.image} alt="" />
                        </Box>
                        <Button className="selectBoxBtn" data-test-id="btnHandleNext" onClick={() => this.handleNext(index)}>{item.attributes.button_text}</Button>
                      </Box>
                    )
                  })
                }
              </Box>

              <Box className="actionBtnsBox">
                <Typography className="backText" data-test-id="btnBack" onClick={this.handleBack}>{configJSON.backText}</Typography>
              </Box>
              <RegistrationFooter />
            </CustomWrapper>
          )
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  marginLeft: "32px",
  boxSizing: "border-box",
  "& .flowDecisionHeading": {
    width: "fit-content",
    margin: "60px auto auto auto",
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    color: "#2C2524",
    "@media (max-device-width: 980px)": {
      fontSize: "32px",
      lineHeight: "40px",
    },
    "@media (max-device-width: 745px)": {
      margin: "48px auto auto auto",
      fontSize: "28px",
      lineHeight: "28px",
    },
    "@media (max-device-width: 650px)": {
      margin: "36px auto auto auto",
      fontSize: "24px",
    },
    "@media (max-device-width: 560px)": {
      width: "80%",
      fontSize: "22px",
    },
    "@media (max-device-width: 430px)": {
      width: "90%",
    },
  },
  "& .selectMainBox": {
    width: "940px",
    margin: "32px auto auto auto",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "32px",
    boxSizing: "border-box",
    "@media (max-device-width: 980px)": {
      width: "97.5%",
    },
    "@media (max-device-width: 745px)": {
      width: "60%",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    "@media (max-device-width: 560px)": {
      width: "80%",
    },
    "@media (max-device-width: 430px)": {
      width: "90%",
    },
  },
  "& .selectBox": {
    padding: "32px 32px",
    border: "1px solid #A8A29E",
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    boxSizing: "border-box",
    "@media (max-device-width: 980px)": {
      padding: "24px 24px",
    },
    "@media (max-device-width: 880px)": {
      padding: "18px 18px",
    },
    "@media (max-device-width: 745px)": {
      padding: "12px 12px",
    },
  },
  "& .selectBoxTop": {
    boxSizing: "border-box",
    padding: "0 21px 0 21px",
    "@media (max-device-width: 980px)": {
      padding: "0 18px 0 18px",
    },
    "@media (max-device-width: 880px)": {
      padding: "0 6px 0 6px",
    },
  },
  "& .selectBoxTitle": {
    minHeight: "82px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "29px",
    lineHeight: "40.6px",
    letterSpacing: "0.5px",
    color: "#2C2524",
    textAlign: "center",
    "@media (max-device-width: 800px)": {
      fontSize: "22px",
      lineHeight: "28px",
      minHeight: "60px",

    },
  },
  "& .selectBoxSubTitle": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0",
    marginTop: "24px",
    color: "#2C2524",
    textAlign: "center",
    "@media (max-device-width: 800px)": {
      lineHeight: "24px",
    },
  },
  "& .selectBoxImage": {
    display: "block",
    margin: "auto",
    marginTop: "36px",
  },
  "& .selectBoxBtn": {
    marginTop: "44px",
    backgroundColor: "#E46760",
    width: "100%",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    borderRadius: "24px",
    color: "#FFFFFF",
    padding: "16px",
    textTransform: 'capitalize',
    "@media (max-device-width: 745px)": {
      padding: "8px",
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: "#E46760",
      color: "#FFFFFF",
    }
  },

  "& .actionBtnsBox": {
    boxSizing: "border-box",
    position: "relative",
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "64px auto 88px auto",
    "@media (max-device-width: 980px)": {
      width: "100%",
      padding: "0 15px 0 15px",
    },
    "@media (max-device-width: 840px)": {
      margin: "48px auto 48px auto",
    },
    "@media (max-device-width: 745px)": {
      display: "grid",
      gridTemplateRows: "repeat(1, 1fr)",
      gap: 20,
      width: "fit-content",
      padding: "0",
    },
    "@media (max-device-width: 490px)": {
      margin: "30px auto 30px auto",
    },
  },
  "& .backText": {
    fontFamily: "Raleway",
    fontWeight: 700,
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontSize: "20px",
    color: "#78716C",
    cursor: "pointer",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 745px)": {
      margin: "auto",
    },
  },
});

const SidebarLine = styled(Box)({
  width: "32px",
  backgroundColor: "#1A4A42",
  height: "100vh",
  position: "fixed",
});

// Customizable Area End
