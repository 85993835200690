import React from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
export const configJSON = require("./config");

interface FormData {
  firstName: string,
  middleName: string,
  lastName: string,
  formerName: string,
  preferredName: string,
  gender: string,
  ethnicity: string,
  personalEmail: string,
  contactNumber: string,
  street1: string,
  street2: string,
  city: string,
  state: string,
  zipCode: string,
  dateOfBirth: string,
  stateOfBirth: string,
  socialSecurityNo: string,

  areYouBillingRadioValue: string,
  companyName: string,
  einBusinessTaxID: string,
  nameOfBank: string,
  addressOfBank: string,
  accountHolderName: string,
  typeOfAccount: string,
  typeOfAccountBusiness: string,
  routingNumber: string,
  accountNumber: string,

}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageNo: number,
  formData: FormData,
  genderList: {id: number, name: string}[],
  ethnicityList: {id: number, name: string}[],
  typeOfAccountList: {id: number, name: string}[],
  typeOfAccountBusinessList: {id: number, name: string}[],
  isModalOpen: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OnboardingForm extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pageTopRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pageTopRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pageNo: 1,
      formData: {
        firstName: "",
        middleName: "",
        lastName: "",
        formerName: "",
        preferredName: "",
        gender: "Select",
        ethnicity: "Select",
        personalEmail: "",
        contactNumber: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
        dateOfBirth: "",
        stateOfBirth: "",
        socialSecurityNo: "",

        areYouBillingRadioValue: "personalSsnSinLabel",
        companyName: "",
        einBusinessTaxID: "",
        nameOfBank: "",
        addressOfBank: "",
        accountHolderName: "",
        typeOfAccount: "Select one",
        typeOfAccountBusiness: "Select one",
        routingNumber: "",
        accountNumber: "",
      },
      genderList: [
        {id: 1, name: "Male"},
        {id: 2, name: "Female"},
      ],
      ethnicityList: [
        {id: 1, name: "Filipino"},
        {id: 2, name: "Asian American"},
        {id: 2, name: "Middle Eastern"},
      ],
      typeOfAccountList: [
        {id: 1, name: "Checking"},
        {id: 2, name: "Saving"},
      ],
      typeOfAccountBusinessList: [
        {id: 1, name: "Checking"},
        {id: 2, name: "Saving"},
      ],
      isModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let pageNo = await getStorageData("onboardingForPageNo");
    if(pageNo == 3){
      this.setState({pageNo: Number(pageNo), isModalOpen: true})
    } else if(pageNo){
      this.setState({pageNo: Number(pageNo)})
    } 
  }

  handleOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleSelectchange = (value: string | unknown, name: string) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleNext = (pageNo: number) => {
    setStorageData("onboardingForPageNo", pageNo)
    if(pageNo == 3){
      this.setState({ isModalOpen: true, pageNo: pageNo})
    }else {
      this.setState({ pageNo: pageNo }, () => {
        if (this.pageTopRef.current) {
          this.pageTopRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  };

  handleCloseModal = () => {
    this.setState({isModalOpen: false})
  };
  // Customizable Area End
}
