// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Box, styled } from "@material-ui/core";
import { totalLifeLogo } from "../assets";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CareTeamHeader extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <StyledWrapper>
                <img src={totalLifeLogo} alt="" />
            </StyledWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    backgroundColor: "#FFFFFF",
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    padding: "22px 0 22px 10%",
    borderBottom: "1px solid #E7E5E4",
    "@media (max-device-width: 980px)": {
        width: "100%",
    },
    "@media (max-device-width: 716px)": {
        flexDirection: "column",
        gap: 20,
    },
})
// Customizable Area End
