Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfemploymentcontractconsole2";
exports.labelBodyText = "cfemploymentcontractconsole2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.helloMeredithTitle = "Hello Meredith!";
exports.excitedText = "We're excited to have you onboard.";
exports.offerSummaryTitle = "Offer Summary";
exports.offerSummaryDesc = "Entire Agreement is subject to the Summary herein Terms and Conditions (“T&C”) and Privacy Policy included below and is contingent upon the successful completion of a background check. This Agreement commences as of the Effective Date and shall continue until terminated. An email will be sent with the completed Agreement, once accepted";
exports.offerSummaryDescFullTime = "Entire Agreement is subject to the Summary herein Terms and Conditions (“T&C”), Privacy Policy and Employee Handbook included below and is contingent upon the successful completion of a background check. This Agreement commences as of the Effective Date and shall continue until terminated. An email will be sent with the completed Agreement, once accepted";
exports.offerSummaryDescPartTime = "Entire Agreement is subject to the Summary herein Terms and Conditions (“T&C”), Privacy Policy and Employee Handbook included below and is contingent upon the successful completion of a background check. This Agreement commences as of the Effective Date and shall continue until terminated. An email will be sent with the completed Agreement, once accepted";
exports.offerDetailPartiesTitle = "Parties";
exports.offerDetailShortDesc = `Individually referred to as "Party`;
exports.serviceProviderText = "The Service Provider is an independent contractor, not an employee, partner, joint venturer, or agent of the Company and Service Provider shall not claim such authority. The Service Provider agrees to report all compensation as income and pay all self-employment and other applicable taxes.";
exports.offerDetailScheduleTitle = "Schedule";
exports.offerDetailRemunerationTitle = "Remuneration";
exports.offerDetailRemunerationShortDesc = "All Hourly Rates are subject to prorating by minutes, unless otherwise specified.";
exports.offerDetailBenefitsTitle = "Benefits";
exports.iAgreeText = "I agree with these";
exports.termsAndConditionText = "Terms and Conditions";
exports.andSymbol = "&";
exports.privacyPolicyText = "Privacy Policy.";
exports.emplyeeHandbookText = "Employee Handbook"
exports.comaText = ","
exports.acceptAgreementText = "Accept Agreement";

exports.generalInformationText = "General Information";
exports.sectionText = "Section";
exports.of2Text = "of 2";
exports.middleSectionHeading = "Please add your general information in the fields below.";
exports.firstNameLabel = "First Name";
exports.enterYourText = "Enter your";
exports.importantSymbol = "*";
exports.middleNameLabel = "Middle Name";
exports.ifAppliesLabel = "(if applies)";
exports.lastNameLabel = "Last Name";
exports.formerNameLabel = "Former/Maiden Name";
exports.preferredNameLabel = "Preferred Name";
exports.optionalText = "(Optional)";
exports.personalEmailLabel = "Personal Email";
exports.contactNumberLabel = "Contact Phone Number";
exports.contactNumberPlaceholder = "000-000-0000";
exports.homeAddressLabel = "Home Address";
exports.homeAddressPlaceholder = "Street Address";
exports.streetAddress2Placeholder = "Street Address Line 2";
exports.cityLabel = "City";
exports.stateLabel = "State";
exports.statePlaceholder = "Alabama";
exports.zipCodeLabel = "Zip Code";
exports.zipCodePlaceholder = "00000";
exports.dateOfBirthLabel = "Date of Birth";
exports.dateOfBirthPlaceholder = "MM - DD - YYYY";
exports.stateOfBirthLabel = "State of Birth";
exports.stateOfBirthPlaceholder = "Alabama";
exports.socialSecurityLabel = "Social Security Number";
exports.socialSecurityPlaceholder = "(Ex. 123456789)";
exports.genderLabel = "Gender";
exports.ethnicityLabel = "What is your ethnicity?";
exports.prefContactMethodLabel = "Preferred Contact Method";
exports.phoneLabel = "Phone";
exports.emailLabel = "Email";
exports.smsLabel = "SMS";
exports.saveAndExitTxt = "Save and Exit";
exports.nextText = "Next";

exports.financialInformationHeading = "Financial Information";
exports.middleSectionHeading2 = "The following information will be used for payments.";
exports.areYouBillingLabel = "Are you billing under your Personal SSN/SIN or EIN/Business Tax ID?";
exports.personalSsnSinLabel = "Personal SSN/SIN";
exports.einBusinessTaxIDLabel = "EIN/Business Tax ID";
exports.companynameLabel = "Company Name";
exports.nameOfBankLabel = "Name of Bank";
exports.addressOfBankLabel = "Address of Bank";
exports.accountHolderNameLabel = "Name on the Account";
exports.typeOfAccountLabel = "Type of Account";
exports.typeOfAccountLabelOptional = "Checking or Saving";
exports.typeOfAccountBusinessLabelOptional = "Business or Personal";
exports.routingNumberLabel = "Routing Number";
exports.routingNumberPlaceholer = "12124220";
exports.accountNumberLabel = "Account Number";
exports.accountNumberPlaceholer = "0009090";
exports.exampleOfVoidCheque = "Example of a void cheque";
exports.exampleOfW9Form = "Download a blank W9 Form";
exports.confirmationMessage = "I confirm that all the information I have provided is accurate.";
exports.finishText = "Finish"
exports.backText = "Back";
exports.modalHeading = "We’re reviewing your information";
exports.modalSubHeading = "You can now log into the TL platform and take a look around";
exports.goToPlatformText = "Go to platform";
// Customizable Area End