// Customizable Area Start
import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { skipIcon } from "../assets";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    backText: string,
    continueText: string,
    skipThisStepText: string,
    handleBack: () => void,
    handleContinue: () => void,
    handleSkip: () => void,
    getContinueTextColor: () => string,
    getContinueBackgroundColor: () => string,
    onMouseEnter: (event: React.MouseEvent<HTMLSpanElement>) => void,
    onMouseLeave: (event: React.MouseEvent<HTMLSpanElement>) => void,
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class RegistrationActionBtns extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            backText,
            continueText,
            skipThisStepText,
            handleBack,
            handleContinue,
            handleSkip,
            getContinueTextColor,
            getContinueBackgroundColor,
            onMouseEnter,
            onMouseLeave,
        } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <StyledWrapper>
                <Box className="actionBtnsBox">
                    <Typography className="backText" data-test-id="btnBack" onClick={handleBack}>{backText}</Typography>
                    <Typography className={`continueText`} style={{
                        color: getContinueTextColor(),
                        backgroundColor: getContinueBackgroundColor(),
                    }}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        data-test-id="continueBtn"
                        onClick={handleContinue}
                    >
                        {continueText}
                    </Typography>
                    <Typography className="skipThisStepText" data-test-id="btnSkip" onClick={handleSkip}>{skipThisStepText} <img src={skipIcon} alt="" /></Typography>
                </Box>
            </StyledWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    "& .actionBtnsBox": {
        position: "relative",
        width: "80%",
        margin: "64px auto 88px auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        "@media (max-device-width: 980px)": {
            padding: "0 15px 0 15px",
            width: "100%",
        },
        "@media (max-device-width: 840px)": {
            margin: "48px auto 68px auto",
        },
        "@media (max-device-width: 716px)": {
            width: "fit-content",
            display: "grid",
            gridTemplateRows: "auto auto auto",
            gap: 20,
        },
        "@media (max-device-width: 490px)": {
            margin: "30px auto 40px auto",
        },
    },
    "& .backText": {
        cursor: "pointer",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "23.48px",
        letterSpacing: "1px",
        color: "#78716C",
        order: "0",
        fontFamily: "Raleway",
        "@media (max-device-width: 840px)": {
            fontSize: "18px",
        },
        "@media (max-device-width: 716px)": {
            margin: "auto",
        },
    },
    "& .continueText": {
        fontFamily: "Raleway",
        order: "-1",
        fontWeight: "700",
        lineHeight: "23.48px",
        cursor: "pointer",
        letterSpacing: "1px",
        padding: "16.5px 149px",
        borderRadius: "24px",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "20px",
        "@media (max-device-width: 840px)": {
            padding: "10px 120px",
            fontSize: "18px",
        },
        "@media (max-device-width: 716px)": {
            position: "static",
            left: "unset",
            transform: "none",
            margin: "auto",
        },
    },
    "& .skipThisStepText": {
        color: "#807C7C",
        display: "flex",
        alignItems: "center",
        fontFamily: "Raleway",
        fontWeight: 700,
        lineHeight: "23.48px",
        letterSpacing: "1px",
        cursor: "pointer",
        order: "1",
        fontSize: "20px",
        "@media (max-device-width: 840px)": {
            fontSize: "18px",
        },
        "@media (max-device-width: 716px)": {
            margin: "auto",
        },
    },
    "& .skipThisStepText img": {
        marginLeft: "8px",
    },
});
// Customizable Area End
