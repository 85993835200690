import React from "react";



// Customizable Area Start
import { Box, styled, Typography, Button} from "@material-ui/core";
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';
import CustomStepper from "./components/CustomStepper.web";
import RegistrationFooter from "./components/RegistrationFooter.web";
// Customizable Area End
import CoverageDecisionController, {
    Props,
  } from "./CoverageDecisionController.web";

  export default class CoverageDecision extends CoverageDecisionController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      return (
        // Customizable Area Start
        <StyledWrapper>
          <section className='SideBar'></section>
          
            <Box className="topHeader" style={{ borderBottom: "1px solid #E7E5E4" }}>
              <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
            </Box>
            <Box className="mainContainer">
            <CustomStepper
                        stepperSteps={this.state.stepState}
                        bgColor="#E46760"
                        color="#FFFFFF"
                        activeStep={1}
                      />
            <Typography className="mainHeading"> Check Your Coverage</Typography>
            <Box className="subHeadingContainer">
             <Typography className="subHeading">
                <span className="boldText">your insurance plan may cover up to 100% of your sessions. </span>
                Please select your preferred payment method
             </Typography>
            </Box>
            <Box className="insuranceContainer">
                 <Typography className="insuranceText">Insurance</Typography>
            <Box className="insuranceTypeContainer">
         {this.state.insuranceTypes.map((item,index)=>(
            <Button className={this.state.index==index?'btnSelected':'btnOutined'} 
            onClick={()=>this.setState({index:index})} data-test-id='insurancebtn' key={index}>{item}</Button>
         ))}
            </Box>
            </Box>
            <Button className="btnOutined" style={{marginTop:'23px'}}>I want to pay with cash</Button>
            <Typography className="bottomText">I’m not sure</Typography>
            <Typography className="footerText">I don't have my insurance details on me</Typography>
            <Button className="backButton" data-test-id='backBtn' onClick={()=>this.handleNavigation('FlowDecision')}>Back</Button>
            </Box>
<RegistrationFooter/></StyledWrapper>)}}
    // Customizable Area Start
const StyledWrapper=styled(Box)({
    "& .backButton":{width:'53px',height:'23px',fontFamily:'Raleway',fontSize:'20px',fontWeight:700,color:'#78716C',textTransform:'capitalize',position:'relative',right:'480px',bottom:'80px'},
    "& .SideBar": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 99999 },
    "& .topHeader": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff", justifyContent: 'space-between' },
    "& .logoImg": { marginLeft: "100px", width: "162px", height: "41.24px" },
   "& .mainContainer":{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',marginTop:'32px'},
   "& .mainHeading":{fontSize:'50px',fontWeight:700,color:'#2C2524',fontFamily:'Raleway',marginTop:'60px'},
   "& .subHeadingContainer":{width:'823px',marginTop:'32px',textAlign:'center'},
   "& .subHeading":{fontFamily:'Poppins',fontSize:'24px',fontWeight:400},
   "& .boldText":{
    fontWeight:700
   },
   "& .insuranceContainer":{
    width:'460px',
    height:'340px',
    border:'1px solid #D6D3D1',
    borderRadius:'24px',
    backgroundColor:'#FFFAF6',
    marginTop:'32px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    alignItems:'center'
   },
   "& .insuranceText":{
    fontSize:'29px',
    fontWeight:500,
    fontFamily:'Poppins',
    color:'#2C2524'
   },
   "& .insuranceTypeContainer":{
    width:'392px',
    display:'flex',
    gap:'12px',
    flexDirection:'column'
   },
   "& .btnOutined":{
    width:'392px',
    height:'56px',
    borderRadius:'24px',
    border:'2px solid #E46760',
    textTransform:'capitalize',
    fontFamily:'Raleway',
    fontWeight:700,
    color:'#E46760',
    fontSize:'20px',
    backgroundColor:'white'

   },
   "& .btnSelected":{
    width:'100%',
    height:'56px',
    borderRadius:'24px',
    border:'2px solid #E46760',
    textTransform:'capitalize',
    fontFamily:'Raleway',
    fontWeight:700,
    color:'#fff',
    fontSize:'20px',
    backgroundColor:'#E46760'
   },
"& .bottomText":{
    fontFamily:'Raleway',
    fontSize:'20px',
    fontWeight:700,
    color:'#807C7C',
    textDecoration:'undetline',
    marginTop:'24px'
},
"& .footerText":{
    marginTop:'40px',
    fontFamily:'Raleway',
    textDecoration:'underline',
    color:'#E46760',
    fontWeight:700,
    fontSize:'20px',
    marginBottom:'60px'
}

})
    // Customizable Area End


