import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { Afternoon, Morning, Night } from "../assets";
// Customizable Area Start
export const configJSON = require("../config");

interface Provider {
  name: string;
  profileImg: string;
  fullProfileText: string;
  qualification: string;
  specialties: string[];
  initialSessionPrice: number;
  sessionDuration: string;
  nextAvailability: string;
  selectProviderText: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperStep: string[],
  isLoading: boolean,
  patient_id: number,
  timeOfDay: { time: string, image: string }[],
  providerList: Provider[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChooseProviderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepperStep: ["About You", "Verify Insurance", "Choose Provider"],
      isLoading: false,
      patient_id: 158,
      timeOfDay: [
        { time: configJSON.morningTxt, image: Morning },
        { time: configJSON.afternoonTxt, image: Afternoon },
        { time: configJSON.nightTxt, image: Night },
      ],
      providerList: [
        {
          name: "Lucy Liu",
          profileImg: "https://picsum.photos/200/300",
          fullProfileText: "Full Profile",
          qualification: "LMFT",
          specialties: ["Couple Therapy", "Anxiety"],
          initialSessionPrice: 50,
          sessionDuration: "60 mins",
          nextAvailability: "Aug 15",
          selectProviderText: configJSON.selectProviderTxt
        },
        {
          name: "John Doe",
          profileImg: "https://picsum.photos/200/301",
          fullProfileText: "Full Profile",
          qualification: "PhD",
          specialties: ["Depression", "Relaxation"],
          initialSessionPrice: 70,
          sessionDuration: "50 mins",
          nextAvailability: "Aug 20",
          selectProviderText: configJSON.selectProviderTxt
        }
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  handleBack = () => {
    this.handleNavigate("DesignCareTeam")
  };

  joinWaitList = () => {
    this.handleNavigate("WaitList");
  }
  // Customizable Area End
}
