// Customizable Area Start
import React from "react";
import { Box, FormControlLabel, Radio, RadioGroup, styled, Typography, FormControl } from "@mui/material";
import CareTeamController, { Props, configJSON } from "./CareTeamController.web";
import CustomStepper from "../components/CustomStepper.web";
import { InputsLabel, StyledInputField } from "../../../cfemploymentcontractconsole2/src/OnboardingForm.web";
import { searchIcon } from "../../../cfemploymentcontractconsole2/src/assets";
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import CareTeamHeader from "../components/CareTeamHeader";
import { brainImage } from "../assets";
import { KeyboardArrowUp } from "@mui/icons-material";
// Customizable Area End

export default class CareTeam extends CareTeamController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box ref={this.pageTopRef}>
        <SidebarLine />
        <CustomWrapper>
          <CareTeamHeader />
          {
            !this.state.isSearching
              ? <>
                <CustomStepper
                  stepperSteps={this.state.stepperStep}
                  bgColor="#E46760"
                  color="#FFFFFF"
                  activeStep={2}
                />

                <Typography className="mainHeading">{configJSON.letsCreateYourDesignHeading}</Typography>
                <Typography className="whereDoYouLive">{configJSON.whereDoYouLive}</Typography>
                <Typography className="whereDoYouLiveSubHeading">{configJSON.whereDoYouLiveSubHeading}</Typography>

                <Box className="middleMainBox">
                  <InputsLabel>{configJSON.selectYourStateLabel}</InputsLabel>
                  <StyledInputField
                    fullWidth
                    variant="outlined"
                    placeholder={configJSON.selectYourStatePlaceholder}
                    data-test-id="stateTestId"
                    name="state"
                    // value={this.state.formData.street1}
                    // onChange={this.handleOnchange}
                    InputProps={{
                      startAdornment: (
                        <img src={searchIcon} alt="" />
                      )
                    }}
                    inputProps={{ style: { paddingLeft: "8px", } }}
                  />
                  <Typography className="selectYourStateHelperText">{configJSON.selectYourStateHelperText}</Typography>

                  <Box className="radioBtnBox">
                    <InputsLabel>{configJSON.radioBtnLabel}</InputsLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      data-test-id="doYouPrefferRadioChange"
                      name="doYouPrefferRadioTest"
                      value={this.state.doYouPrefferRadio}
                      style={{ marginLeft: "8px" }}
                      onChange={(event) => this.handleRadiochange(event.target.value)}
                    >
                      <FormControlLabel className="radioFormControl" value={configJSON.videoSessionTxt}
                        control={
                          <Radio
                            className="radio"
                            disableRipple
                            color="default"
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            value={"video"}
                          />
                        } label={<Typography className="radioFormLabel">{configJSON.videoSessionTxt}</Typography>} />
                      <FormControlLabel className="radioFormControl" value={configJSON.phoneCallsTxt}
                        control={
                          <Radio
                            className="radio"
                            disableRipple
                            color="default"
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            value={"phone"}
                          />
                        }
                        label={<Typography className="radioFormLabel">{configJSON.phoneCallsTxt}</Typography>} />
                    </RadioGroup>
                  </Box>

                  <Box className="providerBox">
                    <Box>
                      <InputsLabel>{configJSON.providerTypeTxt}</InputsLabel>
                      <Box ref={(ref) => (this.dropdownRef = ref)} className="hide-show-class"
                        style={{
                          position: 'relative',
                          border: '2px solid #E6C79C',
                          borderBottom: !this.state.openDropDown ? "none" : "2px solid #E6C79C",
                          overflow: 'hidden',
                          cursor: "pointer",
                          borderRadius: "24px",
                        }}>
                        <input
                          data-test-id="providerTypeTestId"
                          onClick={() => { this.setState({ openDropDown: !this.state.openDropDown }) }}
                          readOnly
                          value={this.state.providerType}
                          className='drodownInput'
                          placeholder='Select One'
                          type="text"
                        />
                        {this.state.openDropDown ? < KeyboardArrowUp style={{ width: 24, position: 'absolute', top: 10, right: 10, color: "#78716C" }} /> : <KeyboardArrowDown style={{ width: 24, position: 'absolute', top: 10, right: 10, color: "#78716C" }} />}
                        {this.state.openDropDown === true && <Box style={this.state.openDropDown === true ? { display: "block" } : { display: "none" }}>
                          {this.state.providerList.map((element: string, index: number) => {
                            return <Typography
                              key={index}
                              data-test-id="handleProvider"
                              className="providerTypeSingle"
                              onClick={() => { this.handleProviderType(element) }}
                              style={{
                                borderBottom: index !== this.state.providerList.length - 1 ? '1px solid lightgray' : 'none',
                              }}>{element}</Typography>
                          })}
                        </Box>
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="actionBtnsBox">
                  <Typography className="backText" data-test-id="btnBack" onClick={this.handleBack}>{configJSON.backText}</Typography>
                  <Typography className="continueText" style={{
                    color: this.getContinueTextColor(),
                    backgroundColor: this.getContinueBackgroundColor(),
                  }}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    data-test-id="seeRecommendationBtn"
                    onClick={this.handleSeeRecommendation}
                  >
                    {configJSON.seeMyRecommendationsBtn}
                  </Typography>
                </Box>
              </>
              : <Box className="searchMainBox">
                <Typography className="searchingHeading">{configJSON.searchingHeading1}</Typography>
                <Typography className="searchingHeading">{configJSON.searchingHeading2}</Typography>
                <img className="searchingImage" src={brainImage} alt="" />
                <Typography className="searchingDidYouKnow">{configJSON.didYouKnowHeading}</Typography>
                <Typography className="searchingDidYouKnowTxt">{configJSON.didYouKnowTxt}</Typography>
              </Box>
          }
        </CustomWrapper>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  marginLeft: "32px",
  boxSizing: "border-box",
  paddingBottom: 70,
  "& .mainHeading": {
    width: "fit-content",
    margin: "48px auto auto auto",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "50px",
    color: "#2C2524",
    lineHeight: "60px",
  },
  "& .whereDoYouLive": {
    width: "fit-content",
    margin: "40px auto auto auto",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0px",
    color: "#2C2524",
    textAlign: "center",
  },
  "& .whereDoYouLiveSubHeading": {
    boxSizing: "border-box",
    width: "649px",
    margin: "24px auto auto auto",
    padding: "0 120px",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
    color: "#44403C",
    textAlign: "center",
  },
  "& .middleMainBox": {
    width: "501px",
    margin: 'auto',
    marginTop: "32px",
  },
  "& .selectYourStateHelperText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#78716C",
    marginTop: "4px",
  },
  "& .radioBtnBox": {
    marginTop: "32px",
  },
  "& .radioFormControl": {
    width: "fit-content",
    marginTop: "6px",
  },
  "& .radio": {
    color: "#78716C",
    padding: "0",
    marginRight: "8px",
  },
  "& .radioFormLabel": {
    width: "fit-content",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    lineHight: "24px",
    letterSpacing: "0px",
    color: "#2C2524",
  },
  "& .providerBox": {
    marginTop: "32px",
    minHeight: "235px",
  },
  "& .actionBtnsBox": {
    position: "relative",
    width: "80%",
    margin: "58px auto 88px auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    "@media (max-device-width: 980px)": {
      padding: "0 15px 0 15px",
      width: "100%",
    },
    "@media (max-device-width: 840px)": {
      margin: "48px auto 48px auto",
    },
    "@media (max-device-width: 745px)": {
      padding: "0",
      width: "fit-content",
      display: "grid",
      gridTemplateRows: "repeat(1, 1fr)",
      gap: 20,
    },
    "@media (max-device-width: 490px)": {
      margin: "30px auto 30px auto",
    },
  },
  "& .backText": {
    cursor: "pointer",
    lineHeight: "23.48px",
    fontSize: "20px",
    letterSpacing: "1px",
    fontWeight: 700,
    color: "#78716C",
    fontFamily: "Raleway",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 745px)": {
      margin: "auto",
    },
  },
  "& .continueText": {
    fontFamily: "Raleway",
    order: "-1",
    fontWeight: "700",
    lineHeight: "23.48px",
    cursor: "pointer",
    letterSpacing: "1px",
    padding: "16.5px 60px",
    borderRadius: "24px",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "20px",
    "@media (max-device-width: 840px)": {
      padding: "10px 120px",
      fontSize: "18px",
    },
    "@media (max-device-width: 716px)": {
      position: "static",
      left: "unset",
      transform: "none",
      margin: "auto",
    },
  },
  "& .searchMainBox": {
    marginTop: "140px",
  },
  "& .searchingHeading": {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    textAlign: "center",
    color: "#2C2524",
  },
  "& .searchingImage": {
    display: "block",
    margin: "auto",
    marginTop: "90px",
  },
  "& .searchingDidYouKnow": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0px",
    textAlign: "center",
    marginTop: "45px",
    color: "#2C2524",
  },
  "& .searchingDidYouKnowTxt": {
    width: "518px",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0px",
    textAlign: "center",
    marginTop: "45px",
    color: "#2C2524",
  },
  "& .stateSelect": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Poppins",
    color: "#2C2524"
  },
  '& .drodownInput': {
    borderTop: 'none !important',
    borderLeft: 'none !important',
    borderRight: 'none !important',
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0%",
    cursor: "pointer",
    width: '100%',
    border: '2px solid #E6C79C',
    padding: 15,
    borderRadius: "24px",
    height: "44px",
    color: "#2C2524",
  },
  '& .drodownInput:hover, .drodownInput:focus': {
    borderBottom: '2px solid #E6C79C !important',
    outline: 'none !important',
  },
  "& .providerTypeSingle": {
    padding: "8px 0 8px 16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#44403C",
    backgroundColor: "#FFFFFF",
    ":hover": {
      backgroundColor: "#F5F5F4",
      color: "#2C2524",
    },
  },
});

const SidebarLine = styled(Box)({
  width: "32px",
  backgroundColor: "#1A4A42",
  height: "100vh",
  position: "fixed",
});

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#1A4A42',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#1A4A42',
  },
});
// Customizable Area End
