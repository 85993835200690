import React from 'react';

// Customizable Area Start
import {
    Box,
    Step,
    StepLabel,
    Stepper,
    styled,
    Typography,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Autocomplete,
    TextField,
    FormGroup,
    Checkbox,
    Modal
} from '@mui/material';
import { SuccessModal, CheckboxIcon, Checkbox_checked } from "./assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1A4A42',
        borderRadius: "50%",
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#1A4A42',
        },
    },
});

const SchedulingOption = (props: boolean,
    dayIndex: number,
    startTime: string,
    endTime: string,
    handleStartTimeChange: (dayIndex: number, newStartTime: string | null) => void,
    handleEndTimeChange: (dayIndex: number, newEndTime: string | null) => void,
) => {
    const timeOptions: string[] = [
        "8 AM", "9 AM", "10 AM", "11 AM", "12 AM", "1 PM", "2 PM", "3 PM",
        "4 PM", "5 PM", "6 PM", "7 PM", "8 PM"
    ];

    const getDisabledTimes = (): string[] => {
        const startTimeIndex = timeOptions.indexOf(startTime);
        return startTimeIndex === -1 ? [] : timeOptions.slice(0, startTimeIndex);
    };
    const isStartTimeEnabled = !props
    const isEndTimeDisabled = startTime === '' || !props;


    return (
        <>
            <Autocomplete
                data-test-id="TestAutoComplete"
                className={props ? 'Enabled' : 'Disabled'}
                disablePortal
                options={timeOptions}
                value={startTime}
                onChange={(event, newValue) => handleStartTimeChange(dayIndex, newValue)}
                sx={{ width: 300 }}
                disabled={isStartTimeEnabled}
                renderInput={(params) => <TextField {...params} placeholder="8 AM" />}
                popupIcon={<KeyboardArrowDownIcon style={{ fontSize: "20px" }} />}
            />
            <Typography> - </Typography>
            <Autocomplete
                className={props ? 'Enabled' : 'Disabled'}
                data-test-id="TestAutoCompleteEndTime"
                disablePortal
                options={timeOptions.filter(time => !getDisabledTimes().includes(time))}
                value={endTime}
                onChange={(event, newValue) => handleEndTimeChange(dayIndex, newValue)}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} placeholder="8 PM" />}
                disabled={isEndTimeDisabled}
                popupIcon={<KeyboardArrowDownIcon style={{ fontSize: "20px" }} />}

            />
        </>
    );
};
// Customizable Area End

import JoinWaitListController, {
    Props,
} from "./JoinWaitListController";
class JoinWaitList extends JoinWaitListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    StyledRadio = (props: any) => {

        const classes = useStyles();

        return (
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                {...props}
            />
        );
    };

    infoText = () => {
        return (
            this.state.providerPreference ? <Typography style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Poppins" }}> Please tell us more about what would work for you:</Typography>
                : <Typography style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Poppins" }}> Please tell us your ideal session times:</Typography>)
    };


    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <StyledWrapper>
                <Box className='leftLine'></Box>
                <Box className="content">
                    <Box className="leftSide">
                        <Box className="stepper-width">
                            <Stepper alternativeLabel activeStep={this.state.activeStep}>
                                {this.state.steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        <Box style={webStyles.header}>
                            <Typography style={webStyles.HeaderText}>
                                Join Our Waitlist
                            </Typography>
                            <Typography style={{ fontWeight: 400, fontSize: "24px", fontFamily: "Poppins" }}>Sorry, there are no providers who match your requirements at this time.
                            </Typography>
                            {this.infoText()}
                        </Box>
                        {this.state.providerPreference === true ? <Box style={webStyles.header}>
                            <Typography style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Poppins", marginBottom: "18px" }}>Provider Preferences:</Typography>
                            <Box sx={webStyles.preferences}>
                                <Box sx={webStyles.subPreferences}>
                                    <Box>
                                        <FormControl component="fieldset">
                                            <FormLabel style={{ fontWeight: 700, fontSize: "20px", color: "#2C2524 !important", textAlign: 'left' }}>
                                                Gender</FormLabel>
                                            <RadioGroup aria-label="gender" name="customized-radios">
                                                {[{ name: "Either", value: "either" }, { name: "Female", value: "female" }, { name: "Male", value: "male" }].map((element) => { return <FormControlLabel data-test-id="handleAllCheckBoxTest" checked={this.state.GenderValue === element.value} onClick={() => this.handleGender(element.value)} sx={webStyles.radioOptions} value={element.value} control={<this.StyledRadio />} label={element.name} /> })}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl component="fieldset">
                                            <FormLabel sx={{ fontWeight: 700, fontSize: "20px", color: "#2C2524 !important", textAlign: 'left' }}> Specialties</FormLabel>
                                            <RadioGroup aria-label="gender" name="customized-radios">
                                                {[{ name: "Anxiety", value: "anxiety" }, { name: "Depression", value: "depression" }, { name: "Addiction", value: "addiction" }, { name: "Grief and Loss", value: "grief_and_loss" }, { name: "Chronic Pain", value: "chronic_pain" }, { name: "Cognitive Decline", value: "cognitive_decline" }].map((item: any) => { return <FormControlLabel data-test-id="testSpeciality" checked={this.state.Speciality === item.value} onClick={() => { this.handleSpeciality(item.value) }} sx={webStyles.radioOptions} value={item.value} control={<this.StyledRadio />} label={item.name} /> })}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl component="fieldset">
                                            <FormLabel sx={{ fontWeight: 700, fontSize: "20px", color: "#2C2524 !important", textAlign: 'left' }}>Type of Therapy</FormLabel>
                                            <RadioGroup aria-label="gender" name="customized-radios">
                                                {[{ name: "Individual", value: "individual" }, { name: "Couple", value: "couple" }, { name: "Family", value: "family" }, { name: "Group", value: "group" }].map((item) => { return <FormControlLabel sx={webStyles.radioOptions} checked={this.state.TypeOfTherapy === item.value} data-test-id="testTherapyType" onClick={() => { this.handleTherapyType(item.value) }} value={item.value} control={<this.StyledRadio />} label={item.name} /> })}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box sx={webStyles.feedBack}>
                                    <Typography style={webStyles.feedbackText}>Anything else you'd like us to know</Typography>
                                    <Box style={{ position: "relative", marginBottom: "16px" }}>
                                        <textarea className='textarea-div' data-test-id="handleChangeText" value={this.state.inputValue} onChange={this.handleChange} maxLength={500} style={{ marginTop: "8px", borderRadius: "8px", border: "1px solid #D6D3D1", width: "100%", height: "100px", padding: 8, fontWeight: 400, fontSize: "13px", fontFamily: "Poppins" }} />
                                        <Typography style={{ position: "absolute", bottom: "-25px", right: "2px", fontWeight: "400", fontSize: "12px", color: "#44403C" }}>
                                            {this.state.inputValue.length}/500
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box> : <Box style={webStyles.header}>
                            <Typography style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Poppins", marginBottom: "18px" }}>Scheduling Preferences:</Typography>
                            <Box sx={webStyles.preferences}>
                                <Box sx={{ textAlign: "start" }}>
                                    <Box sx={{ display: "flex", flexDirecyion: "row", gap: "10px" }}>
                                        <Typography style={{ fontWeight: 700, fontSize: "20px", color: "#A8A29E" }}>Central Time</Typography>
                                        <Typography style={{ fontWeight: 700, fontSize: "20px", color: "#A8A29E" }}>(UTC-6)</Typography>
                                    </Box>
                                    <Box style={{ display: "flex", flexDirection: "row", marginTop: "26px", marginBottom: "20px" }}>
                                        <FormGroup>
                                            <FormControlLabel checked={this.state.allChecked} data-test-id="handleAllCheckBoxTest1" onClick={this.handleAllTimeCheckBox} control={<Checkbox sx={{ fontWeight: 400, fonSize: "20px", color: "#2C2524", fontFamily: "Poppins" }} icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Checkbox_checked} style={{ width: "20px", height: "20px" }} />} />} label="Any Time" />
                                        </FormGroup>
                                    </Box>
                                    {this.state.schedulingPreference.map((element: any, index: number) => {
                                        return <Box sx={webStyles.timeSchedule}>
                                            <FormGroup>
                                                <FormControlLabel checked={element.checked} className={element.checked ? 'checkBoxText' : 'DisabledcheckBoxText'} data-test-id="handleCheckBox" onChange={(e) => this.handleCheckboxChange(e, index)} control={<Checkbox icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Checkbox_checked} style={{ width: "20px", height: "20px" }} />} />} label={element.label}
                                                />
                                            </FormGroup>
                                            {SchedulingOption(element.checked, index, this.state.startTime[index], this.state.endTime[index], this.handleStartTimeChange, this.handleEndTimeChange)}
                                        </Box>
                                    })}
                                </Box>
                            </Box>
                        </Box>}
                        <Box className='padding-class back-box' sx={webStyles.chooseProvider}>
                            <Box onClick={this.goBack} data-test-id="goBackTest" >
                                <Typography style={{ color: '#A8A29E', fontSize: 20, fontWeight: 700, lineHeight: 1, position: 'relative', top: 2, fontFamily: "Raleway", cursor: "pointer" }}>
                                    Back
                                </Typography>
                            </Box>
                            <Button onClick={this.handleNextClick} data-test-id="loadMoreTest" style={{ width: "462px", backgroundColor: "#E46760", borderRadius: "24px", height: "56px" }}>
                                <Typography style={{ textTransform: "capitalize", fontWeight: 700, fontSize: "20px", color: "#FFFFFF", position: 'relative', top: 3 }}>{this.state.providerPreference ? 'Next' : 'Submit'}</Typography>
                            </Button>
                            <Box data-test-id="goBackTest" sx={{ width: "50px" }}>

                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Modal open={this.state.ShowSuccessModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', '& .MuiModal-backdrop': { backdropFilter: 'blur(4px)' } }}
                    data-test-id="modalClose"
                    onClose={this.closeModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box sx={webStyles.modalView}>
                        <Typography style={webStyles.modalHead}>
                            You are on the waitlist!
                        </Typography>
                        <Box style={{ marginTop: "40px" }} >
                            <img src={SuccessModal} width="148px" height="148px"></img>
                        </Box>
                        <Typography style={{ marginTop: "40px", fontFamily: "Poppins", paddingInline: "41px", fontWeight: 400, fontSize: "20px", marginBottom: "40px" }}>We will let you know when a qualified provider becomes available</Typography>
                        <Box sx={{ padding: "32px 32px 0px", borderTop: "1px solid #E7E5E4" }}>
                            <Button data-test-id="loadMoreTest" style={{ width: "356px", backgroundColor: "#E46760", borderRadius: "24px", minHeight: 56 }}>
                                <Typography onClick={this.handleNextClick} data-test-id="handleLogin" style={{ textTransform: "capitalize", fontWeight: 700, fontSize: "20px", color: "#FFFFFF", position: 'relative', top: 3 }}>Login</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </StyledWrapper>

        );
        // Customizable Area End
    }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    '& .leftLine': {
        backgroundColor: "#1A4A42",
        width: 32,
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99999,
        borderRadius: '0px 16px 16px 0px'
    },
    '& .textarea-div:focus-visible': {
        border: 'none',
        outline: 'none'
    },
    '& .checkBoxText .MuiFormControlLabel-label': {
        position: 'relative',
        top: 2,
        fontWeight: 700,
        fontSize: "20px",
        color: "#2C2524 !important"
    },
    '& .DisabledcheckBoxText .MuiFormControlLabel-label': {
        position: 'relative',
        top: 2,
        fontWeight: 700,
        fontSize: "20px",
        color: "#807C7C"
    },
    '& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root': {
        color: '#2C2524 !important'
    },
    '& .MuiFormControlLabel-label': {
        position: 'relative',
        top: 2,
    },
    "& .checkBoxText ,& .DisabledcheckBoxText": {
        position: 'relative',
        top: 2,
        width: "150px"
    },
    "& .MuiCheckbox-root.Mui-checked": { color: "#1A4A42" },
    "& .MuiAutocomplete-clearIndicator": { display: "none" },
    "& .MuiInputBase-root.MuiOutlinedInput-root": { border: "1px solid #E6C79C", borderRadius: 24, padding: '3.5px 4px 1px 10px', textAlign: "center" },
    "& .Enabled .MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input": { textAlign: "center", paddingRight: 65, color: "#2C2524" },
    "& .Disabled .MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input": { textAlign: "center", paddingRight: 65, color: "#A8A29E" },
    '& .MuiAutocomplete-root .MuiOutlinedInput-root, .MuiInputBase-input.MuiOutlinedInput-input': { paddingRight: '15px !important' },
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .checkBoxText.MuiTypography-root": { fontWeight: 700, fontSize: "20px", color: "#2C2524", fontFamily: "Poppins" },
    "& .containerStyles": { display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", minHeight: "78px", margin: "auto", flex: 1, backgroundcolor: "#F5F5F4" },
    '& .MuiStep-root.MuiStep-horizontal': { minWidth: 'max-content' },
    '& .MuiStepConnector-horizontal': { top: '15px !important', },
    "& .MuiTab-root.Mui-selected": { color: "#E46760" },
    "& .MuiTabs-indicator": { display: "none" },
    '& .MuiStepLabel-label': { fontWeight: 700, fontSize: '16px !important', fontFamily: "Poppins", },
    "& .header": { height: "auto", minHeight: "78px", width: "100%", display: "flex", alignItems: "center", backgroundColor: "#fff" },
    "& .logoImg": { marginLeft: "144px", width: "162px", height: "41.24px" },
    "& .content": { display: "flex", width: "100%", height: "auto", flex: 1 },
    "& .MuiStepIcon-root.Mui-active": { color: "#E46760", height: "32px", width: "32px" },
    "& .MuiStepIcon-root.Mui-completed": { color: "#1A4A42", height: "32px", width: "32px" },
    "& .contentWrapper": { width: '100%', maxWidth: "560px", marginTop: "55.92px", margin: "55.92px auto auto auto" },
    "& .leftSide": {
        height: "auto", width: "100%", textAlign: "center", padding: "32px", display: "flex",
        flexDirection: "column",
        backgroundColor: "#F5F5F4",
        '& .stepper-width': {
            maxWidth: "600px",
            width: '100%',
            fontfamily: "Poppins",
            margin: "0 auto"
        },
        "& .image": {
            width: "100%",
            height: "auto",
            display: "flex",
            maxWidth: "373.89px",
            maxHeight: "337.08px",
            marginLeft: "auto",
            marginRight: "auto",
            flex: 1
        },
    },
    '& .tab-inner-div button': {
        minHeight: 40,
        height: 40,
        padding: '8px'
    },
    '& .tab-inner-div': {
        minHeight: 40,
        height: 40,
        '& > div > div': {
            justifyContent: 'space-between'
        }
    },
    "& .secondarybtn": {
        display: "flex",
        justifyContent: "center",
        lineHeight: "23.48px",
        cursor: "pointer",
        marginTop: '33px',
        letterSpacing: "1px",
        background: "none",
        fontWeight: 700,
        fontFamily: "Raleway",
        fontSize: "20px",
        color: "#E46760",
        border: "none",
    },
    "& .heading": {
        fontFamily: "Raleway",
        color: "#FFFFFF",
        fontSize: "50px",
        fontWeight: 700,
        lineHeight: "60px",
        letterSpacing: "0.5px",
        textAlign: "center",
        marginBottom: "16px",
        marginTop: "0"
    },

    "& .paragraph": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "20px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#FFFFFF",
        margin: 0,
        "& p": {
            margin: 0
        }
    },
    "& .paragraphRight": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#2C2524",
        margin: 0,
        "& p": {
            margin: 0
        }
    },

    "& .headingRight": {
        fontFamily: "Raleway",
        color: "#2C2524",
        fontWeight: 700,
        fontSize: "50px",
        lineHeight: "60px",
        letterSpacing: "0.5px",
        textAlign: "center",
        marginBottom: "16px",
        marginTop: "0"
    },

    "& .button": {
        width: "100%",
        maxWidth: "540px",
        display: "flex",
        justifyContent: "center",
        textDecoration: "none",
        fontFamily: "Raleway",
        fontWeight: 700,
        borderRadius: "24px",
        backgroundColor: "#E46760",
        color: "#FFFFFF",
        fontSize: "20px",
        lineHeight: "23.48px",
        padding: "16.5px 0",
        border: "none",
        cursor: "pointer",
        margin: "46px auto 0",
    },

    "@media screen and (max-width: 1480px)": {
        "& .logoImg": {
            marginLeft: "30px",
        },
        "& .paragraph": {
            fontSize: "18px",
        },
        "& .paragraphRight": {
            fontSize: "18px",
        },
        "& .heading": {
            fontSize: "40px",
        },
        "& .headingRight": {
            fontSize: "40px",
        },
    },

    '@media(max-width: 1440px)': {

        '& .card-profile': {
            height: '100px !important',
            minWidth: '100px !important',
            width: '100px !important',
        },
        '& .back-box': {
            maxWidth: '94%',
        },
        "& .logoImg": {
            paddingLeft: "24px",
        },
        '& .tab-parent-box': {
            margin: '20px 12px !important'
        },
        '& .calender-box': {
            paddingInline: '12px !important'
        },
        '& .date-box': {
            marginBlock: 32
        },
        '& .time-box': {
            padding: 'unset !important',
            maxWidth: 'unset !important',
        }
    },

    '@media (max-width: 1299px)': {
        '& .card-footer': {
            flexDirection: "column"
        },
    },
    '@media (max-width: 1170px)': {
        '& .tab-parent-box': {
            maxWidth: '284px !important'
        },
        '& .tab-inner-div button': {
            padding: '5px !important',
            fontSize: '13px !important',
        },
    },

    "@media screen and (max-width: 1100px)": {
        "& .logoImg": {
            marginLeft: "30px",
        },
        "& .paragraph": {
            fontSize: "18px",
        },
        "& .paragraphRight": {
            fontSize: "18px",
        },
        "& .heading": {
            fontSize: "38px",
        },
        '& .padding-class': {
            paddingLeft: '24px !important',
            paddingRight: '24px !important'

        },
        "& .headingRight": {
            fontSize: "38px",
        },
    },

    "@media screen and (max-width: 991px)": {
        "& .logoImg": {
            marginLeft: "30px",
        },
        "& .paragraph": {
            fontSize: "16px",
            lineHeight: "1.5",
            letterSpacing: "0.5",
        },
        "& .paragraphRight": {
            fontSize: "16px",
            lineHeight: "1.5",
            letterSpacing: "0.5",
        },
        "& .heading": {
            fontSize: "26px",
        },
        "& .headingRight": {
            fontSize: "26px",
        },

        "& .leftSide": {
            padding: "30px",
        },
    },

    "@media screen and (max-width: 767px)": {
        "& .leftSide": {
            maxWidth: "100%",
        },
        "& .button": {
            fontSize: "18px",
        },
        "& .content": {
            flexWrap: "wrap",
        },
    },
});

const webStyles = {
    root: {
        maxWidth: "384px",
        maxHeight: "442px",
        width: '100%',
        boxShadow: "unset",
        borderRadius: 16,
        border: "1px solid #D6D3D1"
    },
    timeSchedule: {
        display: "flex", flexDirection: "row", gap: "12px", marginTop: "12px", alignItems: "center",
        '& .MuiAutocomplete-popupIndicator': {
            position: 'relative',
            top: '3px', right: "8px"
        }
    },
    modalView: { maxWidth: "600px", width: "100%", padding: "32px 0px", textAlign: "center", backgroundColor: "#FFFFFF", borderRadius: "24px", },
    modalHead: { fontWeight: 500, fontSize: "29px", fontFamily: "Poppins" },
    header: { marginTop: "60px" },
    feedbackText: { fontWeight: "700", fontSize: "20px", fontFamily: "Poppins", alignItems: "start" },
    feedBack: { marginTop: "32px", textAlign: "start" },
    radioOptions: { fontWeight: 400, fontSize: "16px", color: "#2C2524", },
    preferences: {
        padding: "32px", maxWidth: "601px", width: "100%", margin: "0 auto", border: "1px solid #A8A29E", borderRadius: "24px"
    },
    subPreferences: {
        display: "flex", flexDirection: "row", justifyContent: "space-between",
    },
    HeaderText: { fontFamily: "Raleway", fontWeight: 700, fontSize: "50px" },

    chooseProvider: { display: "flex", flexDirection: "row", justifyContent: "Space-between", paddingInline: "112px", paddingTop: '60px', paddingBottom: '37px', alignItems: 'center' },

};
export default JoinWaitList;
// Customizable Area End