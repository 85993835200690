// Customizable Area Start
import React from "react";
import { Box, styled, Typography,Button } from "@mui/material";
import TellUsAboutYouController, { Props, configJSON } from "./TellUsAboutYouController.web";
import RegistrationFooter from "../components/RegistrationFooter.web";
import CustomStepper from "../components/CustomStepper.web";
import SkipNextIcon from '@mui/icons-material/SkipNext';
// Customizable Area End

export default class TellUsAboutYou extends TellUsAboutYouController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
     <>
     <StyleWrapper>

     <section className='leftBorder'></section>
        <Box sx={{backgroundColor:'#FFFAF6',}}>
        <CustomStepper
                        stepperSteps={this.state.stepperStep}
                        bgColor="#E46760"
                        color="#FFFFFF"
                        activeStep={0}
                      />
        </Box>
        <Box className="mainContainer">
               <Typography className="mainHeading">{this.state.title}</Typography>
               <Typography className="subHeading">{this.state.body}</Typography>
        <Box className='btnContainer'>
{this.state.options.map((option:any,index:any)=>(
<Button className={this.state.option_id==option.id?'btnSelected':'btn'} onClick={()=>this.setState({index:index,option_id:option.id})} data-test-id='optionbtn' key={option.id}>{option.option_text}</Button>
))}
        </Box>
        <Box className='navBtnContainer'>
        <Button className="back-btn" onClick={()=>this.handleNavigate('LastQuestion')} data-test-id='back'>Back</Button>
        <Button className="continue-btn" onClick={()=>this.handleContinue()} data-test-id='continue'>Continue</Button>
        <Button className="skip-btn" endIcon={<SkipNextIcon/>} onClick={()=>this.handleNavigate('FlowDecision')} data-test-id='skip'>Skip this Step</Button>
        </Box >
        
        </Box>
        <RegistrationFooter/>
     </StyleWrapper>
     </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyleWrapper=styled(Box)({
    "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 99999 },
  "& .mainContainer":{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    marginTop:'60px',
    marginBottom:'120px'

  },
  "& .mainHeading":{
    fontFamily:'Raleway',
    fontSize:'41px',
    fontWeight:600
  },
  "& .subHeading":{
    fontFamily:'Poppins',
    fontSize:'24px',
    color:'#2C2524',
    marginTop:'32px'
  },
  "& .btnContainer":{
    marginTop:'32px',
    padding:'24px 0px 24px 0px ',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    width:'700px',
    gap:'12px'
  },
  "& .btn":{
    width:'340px',
    height:'48px',
    borderRadius:'24px',
    border:'1px solid #D6D3D1',
    backgroundColor:'#F5F5F4',
  justifyContent:'flex-start',
    fontFamily:'Poppins',
    fontSize:'20px',
    color:'#57534E',
    textTransform:'initial',
    "&:hover":{
        backgroundColor:'#F5F5F4',
    }
  },
  "& .btnSelected":{
    width:'340px',
    height:'48px',
    borderRadius:'24px',
    border:'1px solid #D6D3D1',
    backgroundColor:'#E46760',
  justifyContent:'flex-start',
    fontFamily:'Poppins',
    fontSize:'19px',
    color:'#fff',
    fontWeight:700,
    textTransform:'initial',
    "&:hover":{
        backgroundColor:'#E46760',
    }
  },
  "& .navBtnContainer":{
    width:'80%',
    display:'flex',
    justifyContent:'space-between',
    marginTop:'70px',
    alignItems:'center'
  },
  "& .back-btn":{
    width:'200px',
    justifyContent:'flex-start',
    textTransform:'capitalize',
    fontSize:'20px',
    fontWeight:700,
    color:'#78716C',
    fontFamily:'Raleway',
    height:'23px'

  },
  "& .continue-btn":{
    width:'390px',
    height:'56px',
    borderRadius:'24px',
    backgroundColor:'#E46760',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'20px',
    fontWeight:700,
    fontFamily:'Raleway',
    "&:hover":{
        backgroundColor:'#E46760',
    }

  },
  "& .skip-btn":{
width:'200px',
height:'24px',
textTransform:'capitalize',
    fontSize:'20px',
    fontWeight:700,
    color:'#78716C',
    fontFamily:'Raleway',
    justifyContent:'flex-end',
  }

})


// Customizable Area End
