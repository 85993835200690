// Customizable Area Start
import React from "react";
import { Box, Checkbox, FormControlLabel, styled, TextField, Typography } from "@mui/material";
import Loader from '../../../../components/src/Loader.web';
import RegistrationHeadings from "../components/RegistrationHeadings.web";
import RegistrationFooter from "../components/RegistrationFooter.web";
import RegistrationActionBtns from "../components/RegistrationActionBtns.web";
import CustomStepper from "../components/CustomStepper.web";
import CheckIcon from "@mui/icons-material/Check";
import LastQuestionScreenController, { Props, configJSON } from "./LastQuestionScreenController.web";
// Customizable Area End

export default class LastQuestionScreen extends LastQuestionScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <SidebarLine />
        {
          this.state.isLoading ? (
            <Loader loading={this.state.isLoading} />
          ) : (
            <StyledWrapper>
              <CustomStepper
                bgColor="#E46760"
                color="#FFFFFF"
                activeStep={0}
                stepperSteps={this.state.stepperStep}
              />
              <RegistrationHeadings
                width="100%"
                heading={this.state.heading}
                subHeading={this.state.subHeading}
                chooseFollowingSubHeading={configJSON.selectAllSubHeading}
                chooseFollowing={configJSON.chooseFollowingHeading}
              />

              <Box className="lastQuestionList">
                {
                  this.state.lastQuestionList && this.state.lastQuestionList.map((option) => {
                    return (
                      <FormControlLabel
                        data-test-id="formControlLabel"
                        className="formControl"
                        control={
                          <Checkbox
                            value={option.id}
                            checked={this.state.selectedOptions.includes(option.id)}
                            onChange={this.handleCheckboxChange}
                            style={{ marginRight: "16px" }}
                            data-test-id="checkboxChange"
                            icon={<span className="checkboxUnchecked" />}
                            sx={{ padding: 0 }}
                            checkedIcon={
                              <span className="checkboxChecked" >
                                <CheckIcon style={{ color: "white", fontSize: "16px" }} />
                              </span>
                            }
                          />
                        }
                        label={<Typography className="checkboxLabel">{option.option_text}</Typography>}
                      />
                    )
                  })
                }
                <FormControlLabel
                  data-test-id="formControlLabelOther"
                  className="formControl"
                  control={
                    <Checkbox
                      data-test-id="otherCheckboxChange"
                      checked={this.state.otherCheckbox}
                      onChange={this.handleOtherCheckboxChange}
                      icon={<span className="checkboxUnchecked" />}
                      sx={{ padding: 0 }}
                      style={{ marginRight: "16px" }}
                      checkedIcon={
                        <span className="checkboxChecked" >
                          <CheckIcon style={{ color: "white", fontSize: "16px" }} />
                        </span>
                      }
                    />
                  }
                  label={<Typography className="checkboxLabel">{"Other"}</Typography>}
                />
                {
                  this.state.otherCheckbox && <CustomInput
                    variant="outlined"
                    style={{ width: "324px" }}
                    placeholder={configJSON.otherSpecifyText}
                    data-test-id={`otherInputTestId`}
                    name="otherInput"
                    value={this.state.otherTextInput}
                    onChange={(event) => this.handleOtherTextInputChange(event.target.value)}
                  />
                }
              </Box>

              <RegistrationActionBtns
                data-test-id="testRegistrationActionBtns"
                backText={configJSON.backText}
                continueText={configJSON.continueText}
                skipThisStepText={configJSON.skipThisStepText}
                handleBack={this.handleBack}
                handleContinue={this.handleContinue}
                handleSkip={this.handleSkip}
                getContinueTextColor={this.getContinueTextColor}
                getContinueBackgroundColor={this.getContinueBackgroundColor}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
              />
              <RegistrationFooter />
            </StyledWrapper>
          )
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  marginLeft: "32px",
  boxSizing: "border-box",
  "& .lastQuestionList": {
    minWidth: "324px",
    maxWidth: "fit-content",
    margin: "56px auto 0 auto",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: 12,
    "@media (max-device-width: 660px)": {
      margin: "40px auto 0 auto",
    },
  },
  "& .formControl": {
    width: "fit-content",
    marginLeft: "0px",
  },
  "& .checkboxUnchecked": {
    display: "flex",
    width: "20px",
    height: "20px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px",
    border: "1px solid #78716C",
  },
  "& .checkboxChecked": {
    display: "flex",
    height: "20px",
    width: "20px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #1A4A42",
    borderRadius: "6px",
    backgroundColor: "#1A4A42",
  },
  "& .checkboxLabel": {
    color: "#0F172A",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Poppins",
    fontSize: "20px",
    "@media (max-device-width: 716px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 490px)": {
      lineHeight: "20px",
      fontSize: "16px",
    },
  },
});

const SidebarLine = styled(Box)({
  height: "100vh",
  backgroundColor: "#1A4A42",
  width: "32px",
  position: "fixed",
});

const CustomCheckbox = styled(Checkbox)({
  padding: "0",
  "& .MuiSvgIcon-root": {
    color: "#1A4A42",
  },
});

const CustomInput = styled(TextField)({
  marginBottom: "0px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#E6C79C",
    },
  },
  "& .MuiInputBase-root": {
    height: '44px',
    margin: 'auto',
    borderRadius: '24px',
    backgroundColor: "#FFFFFF",
    fontSize: '20px',
    fontFamily: 'Poppins',
    "@media (max-device-width: 716px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 490px)": {
      lineHeight: "20px",
      fontSize: "16px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "24px",
    borderColor: "#E6C79C",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#E6C79C",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-input": {
    color: '#2C2524',
    padding: '10px 12px 10px 16px',
    fontFamily: 'Poppins',
    "&::placeholder": {
      fontSize: "20px",
      color: '#94A3B8',
      fontFamily: 'Poppins',
      "@media (max-device-width: 716px)": {
        fontSize: "18px",
      },
      "@media (max-device-width: 490px)": {
        lineHeight: "20px",
        fontSize: "16px",
      },
    },
    "&:not(:placeholder-shown)": {
      color: '#2C2524',
      fontFamily: 'Poppins',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    color: 'red',
    lineHeight: "19.2px",
    textTransform: 'uppercase',
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },

  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: "1px solid #FFFFFF",
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
  },
});
// Customizable Area End
