import React from "react";

// Customizable Area Start
import { Box, styled, Typography, Button, TextField, InputAdornment, Checkbox, Radio } from "@material-ui/core";
import { HeaderLogoImg } from '../../landingpage/src/assets';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { KeyboardArrowRight,KeyboardArrowLeft, KeyboardArrowDown } from "@mui/icons-material";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


// Customizable Area End

import Cfemploymentcontractconsole2Controller, {
    Props,
} from "./Cfemploymentcontractconsole2Controller";

export default class CredentialFormSection2 extends Cfemploymentcontractconsole2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <StyledWrapper>
                <section className='leftBorder'></section>
                <Box>
                    <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
                        <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                        <Box className='iconContainer'>
                            <PersonOutlineIcon className="icon" />
                        </Box></Box>
                    <Box className="mainContainer">
                        <Box className="formContainer">
                            <Box className="headingContainer">
                                <Box className="heading">
                                    <Typography className="headingContent">Credentialing - Practice Information</Typography>
                                    <Typography className="subHeading">Section 1.2</Typography>
                                </Box>
                                <Typography className="navigation">2 of 8</Typography>
                            </Box>
                            <Box sx={{ paddingLeft: '42.5px', borderTop: '1px solid solid' }}>
                                <Box className="seperator"></Box>
                            </Box>

                            <Box className="bodyContainer">
                                <Typography className="body-heading">Please add your private practice information in the fields below.</Typography>
                                <Box className="securityContainer">
                                    <Typography className="nametext">Social Security Number
                                        <span className="mandotary">*</span>
                                    </Typography>
                                    <TextField variant="outlined" className="securityField" fullWidth placeholder="(Ex. 123456789)" />
                                </Box>
                                <Box className="choiceContainer">
                                    <Typography className="nametext">Are you going to use your private practice for pay and tax purposes?
                                        <span className="mandotary">*</span>
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box className="choice"><Radio name="yes" /> <Typography className="yes-or">Yes</Typography></Box>
                                        <Box className="choice"><Radio name='no' /> <Typography className="yes-or">No</Typography></Box>
                                    </Box>

                                </Box>
                                <hr style={{ width: '728px', border: '1px solid #E7E5E4', marginTop: '24px' }} />
                                <Box className="companydetailContainer">
                                    <Box className="detail">
                                        <Typography className="nametext">Company Name<span className="mandotary">*</span> </Typography>
                                        <TextField fullWidth variant="outlined" placeholder="Placeholder" />
                                    </Box>
                                    <Box className="detail">
                                        <Typography className="nametext">Doing business as
                                            <span className="non-mandotary">(if applicable)</span><span className="mandotary">*</span> </Typography>
                                        <TextField fullWidth variant="outlined" placeholder="Placeholder" />
                                    </Box>
                                    <Box className="detail">
                                        <Typography className="nametext">Federal Tax ID/EIN<span className="mandotary">*</span> </Typography>
                                        <TextField fullWidth variant="outlined" placeholder="Placeholder" />
                                    </Box>
                                </Box>
                                <Box className="dateContainer">
                                    <Typography className="nametext">Start Date of Business
                                        <span className="mandotary">*
                                        </span></Typography>
                                    <TextField variant="outlined" fullWidth placeholder="MM-DD-YYYY"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <InsertInvitationOutlinedIcon />
                                                </InputAdornment>
                                            )
                                        }} />
                                </Box>
                                <Box className="addressContainer">

                                    <Box className="homeAdress">
                                        <Typography className="nametext">Business Address<span className="mandotary">*</span></Typography>
                                        <TextField className="street" fullWidth variant="outlined" placeholder="Street Address"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FmdGoodOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start" >
                                                        <SearchOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: '728px', marginTop: '16px' }} >
                                        <TextField className="street" fullWidth variant="outlined" placeholder="Street Address line 2" />
                                    </Box>
                                    <Box className="box">
                                        <Box className="city">
                                            <Box className="subbox">
                                                <Typography className="nametext">City<span className="mandotary">*</span></Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Enter your City"
                                                    className="cityfield" />
                                            </Box>
                                            <Box className="subbox">
                                                <Typography className="nametext">State<span className="mandotary">*</span></Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Alabama"
                                                    className="cityfield"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchOutlinedIcon color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Box>
                                        </Box>
                                        <Box className="city">
                                            <Box className="subbox">
                                                <Typography className="nametext">Zip Code<span className="mandotary">*</span></Typography>
                                                <TextField fullWidth variant="outlined" placeholder="00000"
                                                    className="cityfield" />
                                            </Box>
                                            <Box className="subbox">
                                                <Typography className="nametext">Time Zone<span className="mandotary">*</span></Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Please Select"
                                                    className="cityfield"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <KeyboardArrowDown color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <hr style={{ width: '728px', border: '1px solid #E7E5E4', marginTop: '24px' }} />

                                <Box className="addressContainer">

                                    <Box className="homeAdressnew">
                                        <Typography className="nametext">Medical Record Correspondence Address<span className="mandotary">*</span></Typography>
                                        <Box className="optionContainer">
                                            <Checkbox /> <Typography className="option">Same address as business address</Typography>
                                        </Box>
                                        <Box className="optionContainer">
                                            <Checkbox /> <Typography className="option">Same address as home address</Typography>
                                        </Box>
                                        <TextField className="street gray" fullWidth variant="outlined" placeholder="Same as [business]"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FmdGoodOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start" >
                                                        <SearchOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: '728px', marginTop: '20px' }} >
                                        <TextField className="gray" fullWidth variant="outlined" placeholder="Same as [business]" />
                                    </Box>
                                    <Box className="box">
                                        <Box className="city">
                                            <Box className="subbox">
                                                <Typography className="nametext" style={{ color: '#78716C' }}>City</Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Same as [business]"
                                                    className="gray" />
                                            </Box>
                                            <Box className="subbox">
                                                <Typography className="nametext" style={{ color: '#78716C' }}>State</Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Same as [business]"
                                                    className="gray"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchOutlinedIcon color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Box>
                                        </Box>
                                        <Box className="city">
                                            <Box className="subbox">
                                                <Typography className="nametext" style={{ color: '#78716C' }}>Zip Code</Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Same as [business]"
                                                    className="gray" />
                                            </Box>
                                            <Box className="subbox">
                                                <Typography className="nametext" style={{ color: '#78716C' }}>Time Zone</Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Same as [business]"
                                                    className="gray"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <KeyboardArrowDown color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <hr style={{ width: '728px', border: '1px solid #E7E5E4', marginTop: '100px' }} />
                                <Box className="addressContainer">

                                    <Box className="homeAdressnext">
                                        <Typography className="nametext">Primary Treating Location<span className="mandotary">*</span></Typography>
                                        <Typography className="treatment-data">Please note, you are responsible for updating your treating location in
                                            settings in the event you change treatment locations.<span style={{ fontWeight: 700 }}> It is strictly prohibited
                                                for treatment to be done outside of the United States.</span></Typography>
                                        <Box className="optionContainer">
                                            <Checkbox /> <Typography className="option">Same address as business address</Typography>
                                        </Box>
                                        <Box className="optionContainer">
                                            <Checkbox /> <Typography className="option">Same address as home address</Typography>
                                        </Box>
                                         <TextField className="street gray" fullWidth variant="outlined" placeholder="Same as [business]"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FmdGoodOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start" >
                                                        <SearchOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: '728px', marginTop: '20px' }} >
                                        <TextField className="gray" fullWidth variant="outlined" placeholder="Same as [business]" />
                                    </Box>
                                    <Box className="box">
                                        <Box className="city">
                                            <Box className="subbox">
                                                <Typography className="nametext" style={{ color: '#78716C' }}>City</Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Same as [business]"
                                                    className="gray" />
                                            </Box>
                                            <Box className="subbox">
                                                <Typography className="nametext" style={{ color: '#78716C' }}>State</Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Same as [business]"
                                                    className="gray"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchOutlinedIcon color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Box>
                                        </Box>
                                        <Box className="city">
                                            <Box className="subbox">
                                                <Typography className="nametext" style={{ color: '#78716C' }}>Zip Code</Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Same as [business]"
                                                    className="gray" />
                                            </Box>
                                            <Box className="subbox">
                                                <Typography className="nametext" style={{ color: '#78716C' }}>Time Zone</Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Same as [business]"
                                                    className="gray"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <KeyboardArrowDown color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="choiceContainer">
                                        <Typography className="nametext">Do you have more than one treating location?
                                            <span className="mandotary">*</span>
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Box className="choice"><Radio name="yes" /> <Typography className="yes-or">Yes</Typography></Box>
                                            <Box className="choice"><Radio name='no' /> <Typography className="yes-or">No</Typography></Box>
                                        </Box>

                                    </Box>
                                </Box>
                                <Box className="addressContainernew" sx={{marginTop:'250px'}}>

                                    <Box className="homeAdress">
                                        <Typography className="nametext">Additional Treatment Location <span className="mandotary">*</span></Typography>
                                        <TextField className="street" fullWidth variant="outlined" placeholder="Street Address"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FmdGoodOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start" >
                                                        <SearchOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: '728px', marginTop: '16px' }} >
                                        <TextField className="street" fullWidth variant="outlined" placeholder="Street Address line 2" />
                                    </Box>
                                    <Box className="box">
                                        <Box className="city">
                                            <Box className="subbox">
                                                <Typography className="nametext">City<span className="mandotary">*</span></Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Enter your City"
                                                    className="cityfield" />
                                            </Box>
                                            <Box className="subbox">
                                                <Typography className="nametext">State<span className="mandotary">*</span></Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Alabama"
                                                    className="cityfield"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchOutlinedIcon color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Box>
                                        </Box>
                                        <Box className="city">
                                            <Box className="subbox">
                                                <Typography className="nametext">Zip Code<span className="mandotary">*</span></Typography>
                                                <TextField fullWidth variant="outlined" placeholder="00000"
                                                    className="cityfield" />
                                            </Box>
                                            <Box className="subbox">
                                                <Typography className="nametext">Time Zone<span className="mandotary">*</span></Typography>
                                                <TextField fullWidth variant="outlined" placeholder="Please Select"
                                                    className="cityfield"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <KeyboardArrowDown color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="addbtnContainer">
                                    <Button startIcon={<AddCircleOutlineIcon/>} className="addbtn">Add Treatment Location </Button>
                                </Box>
                                </Box>
                            </Box>
                                <Box className="savenextContainer">
                                    <Typography className="save-exit">Save and Exit</Typography>
                                <Box className="back-next-btn-container">
                                    <Button className="back-btn" startIcon={<KeyboardArrowLeft/>}>Back</Button>
                                    <Button className="next-btn"endIcon={<KeyboardArrowRight/>}>Next</Button>
                                </Box>
                                </Box>
                                <Box className='footer'>
            <Box  className='footerContainer'>
              <Typography className="footer-content">Need help?{' '}
              </Typography>
              <Button variant="outlined" endIcon={<PhoneCallbackIcon color="disabled"/>} className="call-btn">Schedule a Call</Button>

            </Box>
          </Box>
                        </Box>
                    </Box>

                

            </StyledWrapper>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
    "& .leftBorder": { backgroundColor: "#E46760", width: 32, height: '100vh', position: 'fixed', borderRadius: '0px 16px 16px 0px', top: 0, left: 0, zIndex: 99999 },
    "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff", justifyContent: 'space-between' },
    "& .logoImg": { marginLeft: "40px", width: "162px", height: "41.24px" },
    "& .iconContainer": { height: '44px', width: '44px', borderRadius: '50%', border: '1px solid #D1DBD9', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '20px' },
    "& .icon": { color: '#76928E', height: '24px', width: '24px' },
    "& .mainContainer": { width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' },
    "& .formContainer": { marginTop: '40px', width: '808px', height: '2655px', border: '1px solid #D6D3D1', borderTopRightRadius: '24px', backgroundColor: '#fff', borderTopLeftRadius: '24px' },
    "& .headingContainer": {
        height: '116px',
        paddingLeft: '40px', paddingRight: '24px', display: 'flex', justifyContent: 'space-between',
        alignItems: 'center',
    },
    "& .heading": {
        width: '854px',
        height: '68px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '2px',
        color: '#2C2524'

    },
    "& .headingContent": {
        fontWeight: 700,
        fontSize: '24px',

    },
    "& .subHeading": {
        fontSize: '20px',
        color: '#78716C'
    },
    "& .navigation": {
        fontSize: '20px',
        fontColor: '#44403C',
        width: '88px'
    },
    "& .seperator": { width: '480px', height: '6px', backgroundColor: '#1A4A42' },
    "& .bodyContainer": {
        paddingRight: '40px', paddingLeft: '40px', paddingBottom: '40px',
        display: 'flex',
        flexDirection: 'column'
    },
    "& .body-heading": {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#78716C',
        marginTop: '24px'
    },
    "& .securityContainer": {
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'column',
        width: '356px',
        height: '72px',

    },
    "& .nametext": {
        fontWeight: 700,
        fontSize: '16px',
        color: '#2C2524',
        fontFamily: 'Poppins'
    },
    "& .mandotary": { color: 'red', fontWeight: 400 },
    "& .non-mandotary": { fontSize: '16px', color: '#78716C', fontWeight: 400 },
    "&  .MuiOutlinedInput-root": {
        height: '44px',
        borderRadius: '24px',
        borderColor: '#E6C79C',

    },
    "& .MuiOutlinedInput-notchedOutline ": {
        borderColor: '#E6C79C !important'
    },
    "& .yes-or": {
        fontSize: '16px',
        fontFamily: 'Poppins',
        display: 'flex',
        gap: '8px'
    },
    "& .choiceContainer": {
        marginTop: '24px',
    },
    "& .choice": {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    "& .companydetailContainer": {
        marginTop: '24px',
        width: '728px',
        height: '252px',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    },
    "& .detail": { display: 'flex', flexDirection: 'column' },
    "& .dateContainer": {

        width: '356px',
        height: '84px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '24px'

    },
    "& .addressContainer": {
        marginTop: '24px',
        height: '320px',
        width: '936px',
        display: 'flex',
        flexDirection: 'column'
    },
    "& .homeAdress": {
        width: '728px',
        height: '72px',
        display: 'flex',
        flexDirection: 'column',
    },

    "& .box": {
        width: '728px',
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        height: '146px'

    },

    "& .city": {
        width: '728px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .subbox": {
        width: '356px'
    },
    "& .optionContainer": {
        display: 'flex',
        gap: '8px',
        alignItems: 'center'
    },
    "& .option": {
        fontSize: '16px',
        fontFamily: 'Poppins',
        color: '#0F172A',

    },
    "& .homeAdressnew": {
        width: '728px',
        height: '144px',
        display: 'flex',
        flexDirection: 'column',
    },
    "& .gray": {
        "&  .MuiOutlinedInput-root": {
            height: '44px',
            borderRadius: '24px',
            borderColor: '#D6D3D1',
            backgroundColor: '#F5F5F4'

        },
        "& .MuiOutlinedInput-notchedOutline ": {
            borderColor: '#D6D3D1 !important',

        },

    },

    "& .homeAdressnext": {
        width: '728px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
    },
    "& .treatment-data": {
        fontFamily: 'Poppins',
        fontSize: '13px',
        color: '#2C2524'
    },
    "& .addressContainernew": {
        marginTop: '250px',
        height: '320px',
        width: '936px',
        display: 'flex',
        flexDirection: 'column'
    },
    "& .addbtnContainer":{
        width:'728px',
        display:'flex',
        justifyContent:'flex-end',
       

    },
    "& .addbtn":{
        width:'249px',
        height:'32px',
       color:'#fff',
       textTransform:'capitalize',
        fontFamily:'Poppins',
        fontSize:'16px',
        fontWeight:700,
        borderRadius:'16px',
        backgroundColor:'#E46760'
    },
    "& .savenextContainer":{
        width:'808px',
        height:'104px',
        border:'1px solid #E7E5E4',
        backgroundColor:'#fff',
        alignItems:'center',
        display:'flex',
        justifyContent:'space-around',
        borderBottomLeftRadius:'24px',
        borderBottomRightRadius:'24px',
        marginBottom:'40px'
    },
    "& .save-exit":{
        fontFamily:'Raleway',
        fontSize:'20px',
        fontWeight:700,
        color:'#78716C'
    },
    "& .back-next-btn-container":{
        width:'300px',
        display:'flex',
        gap:'10px'
    },
    "& .back-btn":{
        width:'115px',
        height:'44px',
        border:'1px solid #E7E5E4',
        backgroundColor:'#fff',
        fontWeight:700,
        fontFamily:'Raleway',
        fontSize:'20px',
        textTransform:'capitalize',
        color:'#A8A29E',
        borderRadius:'16px'
    },
    "& .next-btn":{
        width:'184px',
        height:'44px',
        borderRadius:'16px',
        backgroundColor:'#E46760',
        fontFamily:'Raleway',
        fontSize:'20px',
        color:'white',
        textTransform:'capitalize',
        fontWeight:700

    },
    "& .footer":{
    height:'100px',
    width:'100%',
    display:'flex',
    justifyContent:'flex-end',
    backgroundColor:'#fff',
    alignItems:'center',
    paddingRight:'144px'
},
"& .footer-content":{
    fontSize:'16px',
    fontWeight:700,
    fontFamily:'Poppins',
    color:'#44403C'
},
"& .call-btn":{
  border:'2px solid #E46760',
  borderRadius: '16px',
  color: '#E46760',
textTransform:'initial',
marginLeft:"12px"
},
"& .call-btn:hover":{
  border:'2px solid #E46760',
},
"& .footerContainer":{
  display:'flex',
  alignItems:'center'
},



})
// Customizable Area End