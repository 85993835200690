// Customizable Area Start
import React from "react";
import { Box, styled } from "@material-ui/core";
import OnboardingHeaderController, { Props } from "./OnboardingHeaderController.web";
import { profileIcon, webLogo } from "./assets";
// Customizable Area End

export default class OnboardingHeader extends OnboardingHeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <HeaderWrapper>
        <img className="headerLogo" src={webLogo} alt="" />
        <img className="headerLogo" src={profileIcon} alt="" />
      </HeaderWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const HeaderWrapper = styled(Box)({
  width: "calc(100% - 32px)",
  borderBottom: "1px solid #E7E5E4",
  background: "#FFFFFF",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 40px 16px 20px",
  position: "fixed",
  top: 0,
  left: "32px",
  zIndex: 1000,
  marginBottom: "40px",
  "& .headerLogo": {
    cursor: "pointer",
  },
})
// Customizable Area End
