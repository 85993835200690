import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
// Customizable Area Start
export const configJSON = require("../config");

interface FlowDecisionAttributes {
  "title": string,
  "description": string,
  "button_text": string,
  "image": {
    "image": string,
  },
  "user_name": {
    "patient_name": string
  }
}

interface FlowDecisionType {
  "id": "1",
  "type": "flow_decision",
  "attributes": FlowDecisionAttributes,
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperStep: string[],
  isLoading: boolean,
  flowDecisionData: FlowDecisionType[],
  patient_id: number,
  patient_name: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FlowDecisionScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFlowDecisionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepperStep: ["Stage A", "Stage B", "Choose Provider"],
      isLoading: false,
      flowDecisionData: [],
      patient_id: 158,
      patient_name: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId) {
        if (apiRequestCallId === this.getFlowDecisionApiCallId) {
          this.receiveFlowDecisionData(responseJson);
        }
      }
    }
  }

  receiveFlowDecisionData = (responseJson: { data: FlowDecisionType[] }) => {
    console.log("khaldisahh", responseJson)
    if (responseJson && responseJson.data) {
      let data = responseJson.data;
      let firstAttributes = data && data[0] && data[0].attributes;
      let patient_name = firstAttributes && data[0].attributes.user_name && data[0].attributes.user_name.patient_name

      if (firstAttributes) {
        this.setState({
          patient_name: patient_name,
          flowDecisionData: data,
          isLoading: false,
        });
      }

    } else {
      this.setState({ isLoading: false, })
    }
  };

  async componentDidMount() {
    this.getFlowDecisionData();
  }

  getFlowDecisionData = () => {
    this.setState({ isLoading: true, });

    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFlowDecisionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFlowDecisionDataEndpoint}?patient_id=${this.state.patient_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  handleBack = () => {
    this.handleNavigate("TellUsAboutYou")
  };

  handleNext = (index: number) => {
    if (index === 1) {
      this.handleNavigate("CoverageDecision")
    } else {
      this.handleNavigate("DesignCareTeam")
    }
  }
  // Customizable Area End
}
