import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  newPassword: string;
  confirmPassword: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      newPassword: "",
      confirmPassword: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }  

  // Customizable Area Start
  handleNewPassword = (e: any) => {
    this.setState({ newPassword: e.target.value })
  };

  handleConfirmPassword = (e: any) => {
    this.setState({ confirmPassword: e.target.value })
  };

  passwordLength = () => {
    const lengthRequirement = this.state.newPassword.length >= 8;
    return lengthRequirement
  }

  differentCharacters = () => {
    const characterCombination = /[A-Za-z]/.test(this.state.newPassword) && /[0-9]/.test(this.state.newPassword) && /[^A-Za-z0-9]/.test(this.state.newPassword);
    return characterCombination
  }
  upperCaseCheck = () => {
    const uppercaseRequirement = /[A-Z]/.test(this.state.newPassword);
    return uppercaseRequirement
  }
  // Customizable Area End
}
