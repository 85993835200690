import React from "react";



// Customizable Area Start
import { Box, styled, Typography, Button, TextField ,InputAdornment ,Checkbox} from "@material-ui/core";
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';
import { imgVisbility, imgVisbilityOff } from "./assets";
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BusinessIcon from '@mui/icons-material/Business';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

// Customizable Area End

import Cfemploymentcontractconsole2Controller, {
  Props,
} from "./Cfemploymentcontractconsole2Controller";

export default class Cfemploymentcontractconsole2 extends Cfemploymentcontractconsole2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        <section className='leftBorder'></section>
        <Box className="mainContainer">
          <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
            <Box className='iconContainer'>
              <PersonOutlineIcon className="icon" />
            </Box>

          </Box>
          <Box className="headingContainer">
            <Typography className="main-heading">Hello Meredith!</Typography>
            <Typography className="sub-heading">We're excited to have you onboard.</Typography>
          </Box>
          <Box className="BOX">

            <Box className="details-container">
              <Box className="top-header">
                <Typography className="detail-heading">Offer Summary</Typography>
                <Typography className="detail-body">Entire Agreement is subject to the Summary herein Terms and Conditions (“T&C”), Privacy Policy and Employee Handbook included below and is contingent upon the successful completion of a background check. This Agreement commences as of the Effective Date and shall continue until terminated. An email will be sent with the completed Agreement, once accepted</Typography>

              </Box>
              <Box className="bodyContainer">
                <Box className="partiesContainer">

                  <Box className="parties">
                    <BusinessIcon className="business-icon" />
                    <Typography className="party-heading">Parties</Typography>
                  </Box>
                  <Box>
                    <Typography className="party-content">Individually referred to as "Party</Typography>
                  </Box>
                </Box>
                <Box className="partiesContentContainer">
                  <Box className="partiesContent">
                    <Typography className="parties-data">Company:</Typography>
                    <Typography className="parties-value">Total Life LLC</Typography>
                  </Box>
                  <Box className="partiesContent">
                    <Typography className="parties-data">First Name:</Typography>
                    <Typography className="parties-value">Meredith</Typography>
                  </Box>
                  <Box className="partiesContent">
                    <Typography className="parties-data">Last Name:</Typography>
                    <Typography className="parties-value">Gray</Typography>
                  </Box>
                  <Box className="partiesContent">
                    <Typography className="parties-data">Contract Type: </Typography>
                    <Typography className="parties-value">Employee (W2)</Typography>
                  </Box>
                  <Box className="partiesContent">
                    <Typography className="parties-data">Employment Type: </Typography>
                    <Typography className="parties-value">Part Time</Typography>
                  </Box>
                  <Box className="partiesContent">
                    <Typography className="parties-data">Service Provider Type: </Typography>
                    <Typography className="parties-value">Non Clinical Service Provider and Service Provider</Typography>
                  </Box>
                  <Box className="party-footer">
                    <Typography className="service-text">The Service Provider is an independent contractor, not an employee, partner, joint venturer, or agent of the Company and Service Provider shall not claim such authority. The Service Provider agrees to report all compensation as income and pay all self-employment and other applicable taxes. </Typography></Box>

                <Box className="partiesContent">
                  <Typography className="parties-data">Service Provider Type: </Typography>
                  <Typography className="parties-value">Clinical Service Provider</Typography>
                </Box>
                <Box className="party-footer">
                  <Typography className="service-text">Service Provider shall perform clinical behavioral health therapy, clinical documentation and related services for clients of the Company (“Clinical Services”)</Typography>

                </Box>
                </Box>

                <hr style={{ width: '680px', border: '1px solid #E7E5E4', marginTop: '12px' }} />
                <Box className="scheduleContainer">

                  <Box className="parties">
                    <EventNoteIcon className="business-icon" />
                    <Typography className="party-heading">Schedule</Typography>
                  </Box>
                  </Box>
                  <Box className="partiesContentContainer">
                  <Box className="partiesContent">
                    <Typography className="schedule-data">Effective Date:</Typography>
                    <Typography className="schedule-value">June 07, 2024</Typography>
                  </Box>
                  <Box className="partiesContent">
                    <Typography className="schedule-data">Minimum Hours Committed:</Typography>
                    <Typography className="schedule-value">20 hours per week</Typography>
                  </Box>
                  </Box>
                  <hr style={{ width: '680px', border: '1px solid #E7E5E4', marginTop: '12px' }} />
                  <Box className="scheduleContainer">

                    <Box className="parties">
                      <AttachMoneyIcon className="business-icon" sx={{mt:'3px'}} />
                      <Typography className="party-heading">Remuneration</Typography>
                    </Box>
                      <Typography className="party-content">All Hourly Rates are subject to prorating by minutes, unless otherwise specified.</Typography>
                    </Box>
                    <Box className="partiesContentContainer">
                  <Box className="partiesContent">
                    <Box sx={{width:'290px',display:'flex'}}>
                    <Typography className="Remuneration-data">60 Minute Follow Up:</Typography>
                    <Typography className="Remuneration-value">$40</Typography>

                    </Box>
                    <Box sx={{display:'flex',marginLeft:'46px'}}>
                    <Typography className="Remuneration-data">Individual Supervision:</Typography>
                    <Typography className="Remuneration-value">$150</Typography>

                    </Box>
                  </Box>
                  <Box className="partiesContent">
                    <Box sx={{width:'290px',display:'flex'}}>
                    <Typography className="Remuneration-data">45 Minute Follow Up:</Typography>
                    <Typography className="Remuneration-value">$40</Typography>

                    </Box>
                    <Box sx={{display:'flex',marginLeft:'46px'}}>
                    <Typography className="Remuneration-data">Group Supervision:</Typography>
                    <Typography className="Remuneration-value">$42</Typography>

                    </Box>
                  </Box>
                  <Box className="partiesContent">
                    <Box sx={{width:'290px',display:'flex'}}>
                    <Typography className="Remuneration-data">30 Minute Follow Up:</Typography>
                    <Typography className="Remuneration-value">$125</Typography>

                    </Box>
                    <Box sx={{display:'flex',marginLeft:'46px'}}>
                    <Typography className="Remuneration-data">Administrative Time:</Typography>
                    <Typography className="Remuneration-value">$49</Typography>

                    </Box>
                  </Box>
                  <Box className="partiesContent">
                    <Box sx={{width:'290px',display:'flex'}}>
                    <Typography className="Remuneration-data">No Show:</Typography>
                    <Typography className="Remuneration-value">$100</Typography>

                    </Box>
                    <Box sx={{display:'flex',marginLeft:'46px'}}>
                    <Typography className="Remuneration-data">Internal meeting rate:</Typography>
                    <Typography className="Remuneration-value">$15</Typography>

                    </Box>
                  </Box>
                  <Box className="partiesContent">
                    <Box sx={{width:'290px',display:'flex'}}>
                    <Typography className="Remuneration-data">Cancellation rate:</Typography>
                    <Typography className="Remuneration-value">$75</Typography>

                    </Box>
                    <Box sx={{display:'flex',marginLeft:'46px'}}>
                    <Typography className="Remuneration-data">Initial Session:</Typography>
                    <Typography className="Remuneration-value">$50</Typography>

                    </Box>
                  </Box>
                  <Box sx={{marginTop:'12px'}}>
                  <Box className="sick">
                    <Typography className="sick-data">Paid Sick Leave: </Typography>
                    <Typography className="sick-value">5 days or 40 hours per year</Typography>
                  </Box>
                  <Box className="sick">
                    <Typography className="sick-data">Unpaid Bereavement Leave: </Typography>
                    <Typography className="sick-value">5 days or 40 hours per year</Typography>
                  </Box>
                  <Box className="sick">
                    <Typography className="sick-data">Vacation Time:</Typography>
                    <Typography className="sick-value">2 weeks, 10 days or 80 hours</Typography>
                  </Box>

                  </Box>
                  
                  </Box>
                  <hr style={{ width: '680px', border: '1px solid #E7E5E4', marginTop: '12px' }} />
                  <Box className="scheduleContainer">

                    <Box className="parties">
                      <AttachMoneyIcon className="business-icon" sx={{mt:'3px'}} />
                      <Typography className="party-heading">Service Provider Fees</Typography>
                    </Box>
                    </Box>
                    <Box className="partiesContentContainer">
                  <Box className="partiesContent">
                    <Typography className="schedule-data">Credentialing Fee:</Typography>
                    <Typography className="schedule-value">$50 per payor</Typography>
                  </Box>
                  <Box className="party-footer">
                  <Typography className="service-text">Payable by Company, subject to terms and conditions.</Typography>

                </Box>
                <Box className="partiesContent">
                    <Typography className="schedule-data">Provider No Show Fee:</Typography>
                    <Typography className="schedule-value">$50 per session</Typography>
                  </Box>
                  <Box className="party-footer">
                  <Typography className="service-text">Payable by Provider, subject to terms and conditions.</Typography>

                </Box>
                
                  </Box>
                  
                 </Box>
              <Typography className="footer-text"><span className="footer-bold">Ownership of Work Product.</span> For all works or intellectual property owned by Service Provider prior to the Term of the Agreement in the field that Company operates or has engaged Service Provider to provide Services in, Service Provider shall include a list and detail here: 
              <span style={{textDecoration:'underline',color:'#E46760'}}>Add Work Product
                </span> </Typography>

              <Box className="title-date-container">
               <Box className="title-date-heading-container">
                   <Typography className="title-date-heading">Work Products by Service Provider </Typography>
               <Button className="add-product" disabled startIcon={<AddCircleOutlineIcon/>}>Add Product</Button>
               </Box>
               <Box className="column-heading">
                  <Box className="items">
                  <Typography className="item-text">Title</Typography>
                  </Box>
                  <Box className="items">
                  <Typography className="item-text">Date</Typography>
                  </Box>
                  <Box className="items">
<Typography className="item-text">ID Number/Brief Description</Typography>
                  </Box>
                  <Box sx={{width:'110px'}}>

                  </Box>
               </Box>
               <Box className="textfieldContainer">
                <TextField placeholder="Title" className="textfield" type="text" variant="outlined"/>
                <TextField placeholder="MM-DD-YYYY" className="textfield" type="text" variant="outlined"
                 InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InsertInvitationOutlinedIcon sx={{height:'20px',width:'20px',color:'#A8A29E'}}/>
                    </InputAdornment>
                  ),
                }}/>
                <TextField placeholder="ID or Brief Description" className="textfield description" type="text" variant="outlined" style={{fontSize:'13px'}}/>
                <Button className="confirm" endIcon={<CheckIcon/>}>Confirm</Button>

               </Box>
               <Box className="footer-heading">
                <Box className="bottom">
                     <Typography className="text">Authentic treatment</Typography>
                </Box>
                <Box className="bottom">
                     <Typography className="text">08-08-2024</Typography>
                </Box>
                <Box className="bottom">
                     <Typography className="text">76001523</Typography>
                </Box>
                <Box className="bottom-new">

                          <Box className="dotticon"><MoreHorizOutlinedIcon color="disabled"/></Box>
                </Box>
               </Box>
              </Box>
              <Box className="checkContainer">
            <Checkbox/>
            <Typography className="agreement">I agree with these <span className="underline">Terms and Conditions</span> ,<span className="underline">Privacy Policy</span> & 
            <span className="underline"> Employee Handbook</span></Typography>
              </Box>
            </Box>
            <Box className="Button-container">
                <Button className="submitBtn" disabled>Accept Agreement</Button>
            </Box>
            <Box className='footer'>
            <Box  className='footerContainer'>
              <Typography className="footer-content">Need help?{' '}
              </Typography>
              <Button variant="outlined" endIcon={<PhoneCallbackIcon color="disabled"/>} className="call-btn">Schedule a Call</Button>

            </Box>
          </Box>
 
          </Box>

        </Box>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  "& .leftBorder": { backgroundColor: "#E46760", width: 32, height: '100vh', position: 'fixed', borderRadius: '0px 16px 16px 0px', top: 0, left: 0, zIndex: 99999 },
  "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff", justifyContent: 'space-between' },
  "& .logoImg": { marginLeft: "40px", width: "162px", height: "41.24px" },
  "& .iconContainer": { height: '44px', width: '44px', borderRadius: '50%', border: '1px solid #D1DBD9', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '20px' },
  "& .icon": { color: '#76928E', height: '24px', width: '24px' },
  "& .headingContainer": {
    width: '100%',
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  "& .main-heading": {
    fontFamily: 'Raleway',
    fontWeight: 600,
    fontSize: '41px',
    color: '#2C2524'
  },
  "& .sub-heading": {
    fontFamily: 'Poppins',
    fontSize: '24px',
    color: '#2C2524',
    marginTop: '12px'
  },
  "& .details-container": {
    width: '810px',
    height: '1683px',
    
    backgroundColor: '#FFFFFF',
    marginTop: '24px',
    padding: '24px 40px',
    border: '1px solid #D6D3D1',
    borderTopLeftRadius:'24px',
    borderTopRightRadius:'24px'
  },
  "& .top-header": {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  "& .detail-heading": {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: '29px'
  },
  "& .detail-body": {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#78716C',
    marginTop: '12px'
  },
  "& .bodyContainer": {
    width: '730px',
    height: '980px',
    borderRadius: '20px',
    border: '1px solid #D6D3D1',
    padding: '24px 24px',
    marginTop: '24px'

  },
  "& .business-icon": {
    height: '24px',
    width: '24px',
    color: '#78716C'
  },

  "& .partiesContainer": {
    width: '100%',

  },
  "& .parties": {
    display: 'flex',
    gap: '10px'
  },
  "& .party-heading": {
    fontWeight: 700,
    fontSize: '20px',
    fontFamily: 'Poppins',

  },
  "& .party-content": {
    fontFamily: 'Poppins',
    fontSize: '13px',
    color: '#78716C',
    marginLeft: '35px'
  },
  "& .partiesContentContainer": {
    width: '100%',
    paddingLeft: '33px',
    marginTop: '8px'
  },
  "& .partiesContent": {
    display: 'flex',
    height: '24px',
    marginTop: '4px'
  },
  "& .parties-data": {
    width: '236px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#78716C'
  },
  "& .parties-value": {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#2C2524'
  },
  "& .service-text": {
    fontFamily: 'Poppins',
    color: '#78716C',
    fontSize: '13px'
  },
  "& .party-footer": {
    marginTop: '4px',
    width: '90%'
  },
  "& .scheduleContainer":{
    marginTop:'12px'
  },
  "& .schedule-data": {
    width: '236px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#78716C'
  },
  "& .schedule-value": {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#2C2524'
  },
  "& .Remuneration-data":{
    width: '200px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#78716C'
  },
  "& .Remuneration-value":{
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#2C2524'
  },
"& .sick":{
  display:'flex',
  gap:'12px'
},
"& .sick-data": {
  fontFamily: 'Poppins',
  fontSize: '16px',
  color: '#78716C'
},
"& .sick-value": {
  fontFamily: 'Poppins',
  fontSize: '16px',
  color: '#2C2524'
},
"& .footer-text":{
marginTop:'24px',
fontSize:'13px',
fontFamily:'Poppins',
width:'91%',
color:'#78716C'
},
"& .footer-bold":{
fontWeight:700,
color:'#2C2524'

},
"& .title-date-container":{
  marginTop:'24px',
  width:'100%',
  height:'260px',
  borderRadius:'24px',
  border:'1px solid #D6D3D1'
},
"& .title-date-heading-container":{
  height:'64px',
  backgroundColor:'#F5F5F4',
  display:'flex',
  paddingLeft:'20px',
  paddingRight:'20px',
  justifyContent:'space-between',
  alignItems:'center',
  borderTopRightRadius:'24px',
  borderTopLeftRadius:'24px'

},

"& .title-date-heading":{
  fontWeight:700,
  color:'#2C2524',
  fontSize:'20px',
  fontFamily:'Poppins'

},
"& .add-product":{
  height:'32px',
  width:'136px',
  borderRadius:'16px',
  textTransform:'initial',
  backgroundColor:'#E7E5E4'
},
"& .column-heading":{
  height:'64px',
  display:'flex',
  justifyContent:'space-around',
  alignItems:'center'
},
"& .items":{
  width:'165px'
},
"& .item-text":{
  fontFamily:'Poppins',
  fontSize:'16px',
  fontWeight:700,
  color:'#2C2524'
},
"& .textfieldContainer":{
  height:'64px',
  display:'flex',
  alignItems:'center',
  justifyContent:'space-around'
},
"& .textfield": {
  width: '173px',
  height: '32px',
  borderColor: '#E6C79C', 
  borderRadius: '24px',
  '& .MuiOutlinedInput-root': {
    width: '173px',
    height: '32px',
    borderColor: '#E6C79C', 
    borderRadius: '24px',
    '& fieldset': {
      width: '173px',
      height: '32px',
      borderColor: '#E6C79C', 
      borderRadius: '24px',
    },
    '&:hover fieldset': {
      width: '173px',
      height: '32px',
      borderColor: '#E6C79C', 
      borderRadius: '24px', 
    },
    '&.Mui-focused fieldset': {
      width: '173px',
      height: '32px',
      borderColor: '#E6C79C', 
      borderRadius: '24px',
  }, 
}},
"& .confirm":{
  width:'118px',
  height:'32px',
  color:'#fff',
  backgroundColor:'#1A4A42',
  borderRadius:'16px',
  textTransform:'capitalize',
  fontWeight:700
  
},
"& .description":{
  fontSize:'13px',
  '& .MuiInputBase-input::placeholder': {
      fontSize: '13px', 
    },
  
},
"& .footer-heading":{
  borderTop:'1px solid #D6D3D1',
  height:'56px',
  display:'flex',
  justifyContent:'space-around',
  alignItems:'center'
},
"& .bottom":{
  width:'173px',
}
,"& .bottom-new":{
  width:'136px',
  height:'32px',
  display:'flex',
  justifyContent:'flex-end'
},
"& .text":{
  fontFamily:'Poppins',
  fontSize:'16px'
},
"& .dotticon":{width:'34px',height:'34px',borderRadius:'50%',border:'1px solid #E7E5E4',justifyContent:'center',display:'flex',alignItems:'center'}
,
"& .checkContainer":{
  width:'100%',
  height:'78px',
  border:'1px solid #D6D3D1',
  borderRadius:'20px',
  paddingLeft:'24px',
   marginTop:'24px',
  display:'flex',
  gap:'8px',
  alignItems:'center'
},

"& .agreement":{
  fontFamily:'Poppins',
  fontSize:'16px'
},
"& .underline":{
  textDecoration:'underline',
  color:'#E46760'
},
"& .button-container":{
  width:'892px',
  height:'104px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  border:'1px solid #D6D3D1',
  borderTop:'none',
  borderBottomRightRadius:'24px',
  borderBottomLeftRadius:'24px',
  marginBottom:'50px',
  backgroundColor:'#fff'
},
"& .BOX":{ width: '100%', display: 'flex', justifyContent: 'center',alignItems:'center' ,flexDirection:'column'}
,"& .submitBtn":{
width:'340px',
height:'44px',
backgroundColor:'#E7E5E4',
borderRadius:'16px',
textTransform:'capitalize',
fontFamily:'Raleway',
fontSize:'20px',
fontWeight:700,
color:'#A8A29E'
},
"& .footerContainer":{
  marginRight:'144px',
  display:'flex',
  alignItems:'center'
},
"& .footer":{
    height:'100px',
    width:'100%',
    display:'flex',
    justifyContent:'flex-end',
    backgroundColor:'#fff',
    alignItems:'center'
},
"& .footer-content":{
    fontSize:'16px',
    fontWeight:700,
    fontFamily:'Poppins',
    color:'#44403C'
},
"& .call-btn":{
  border:'2px solid #E46760',
  borderRadius: '16px',
  color: '#E46760',
textTransform:'initial',
marginLeft:"12px"
},
"& .call-btn:hover":{
  border:'2px solid #E46760',
},

})



// Customizable Area End
