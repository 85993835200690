Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;

exports.thankYouSharingHeading = "Thank you for sharing!";
exports.pleaseShareDetailsHeading = "Please share a few more details about your health and wellness.";
exports.chooseFollowingHeading = "Choose from the following options"
exports.selectAllSubHeading = "(select all that apply)"
exports.backText = "Back";
exports.continueText = "Continue";
exports.skipThisStepText = "Skip this Step";
exports.otherSpecifyText = "Other (specify)";
exports.matchMakingGetListEndpoint = "account_block/health_wellness";
exports.addOptionsEndpoint = "account_block/health_wellness";
exports.getSubmittedListEndpoint = "account_block/health_wellness/show_patient_hw_options";
exports.getApiMethod= "GET";
exports.postApiMethod= "POST";
exports.apiContentType = "application/json";

exports.supportDesignEndpoint = "account_block/support_designeds/get_support_designed";
exports.submitDesignEndpoint = "account_block/support_designeds/create_patient_support_designed";
exports.getSubmittedDesignEndPoint = "account_block/support_designeds/get_patient_support_designed";

exports.lastQuestionEndpoint = "account_block/last_questions/get_last_questions";
exports.submitlastQuestionEndpoint = "account_block/last_questions/create_patient_last_question";
exports.getSubmittedlastQuestionEndPoint = "account_block/last_questions/get_patient_last_question";

exports.getFlowDecisionDataEndpoint = "account_block/flow_decisions/get_flow_decision"

exports.letsCreateYourDesignHeading = "Let's Design Your Care Team!";
exports.whereDoYouLive = "Where do you live?";
exports.whereDoYouLiveSubHeading = "Knowing your location lets us match you with clinicians licensed in your state.";
exports.selectYourStatePlaceholder = "Select your State";
exports.selectYourStateLabel = "Select your current State";
exports.selectYourStateHelperText = "if you are a snowbird select the state that you will be attending sessions from"
exports.radioBtnLabel = "Do you prefer video sessions or phone calls?";
exports.videoSessionTxt = "Video sessions";
exports.phoneCallsTxt = "Phone Calls";
exports.providerTypeTxt = "Provider Type";
exports.selectOneTxt = "Select one";
exports.seeMyRecommendationsBtn = "See my recommendations";
exports.searchingHeading1 = "We're assembling your Care team,";
exports.searchingHeading2 = "please stand by...";
exports.didYouKnowHeading = "Did you know?";
exports.didYouKnowTxt = "Therapy gives you a safe space to explore your thoughts, feelings, and concerns.";
exports.chooseProviderHeading = "Choose your Provider";
exports.sortByText = "Sort by:";
exports.recommendedTxt = "Recommended";
exports.loadMoreBtnTxt = "Load More";
exports.joinWaitlistTxt = "Join Waitlist";
exports.selectProviderTxt = "Select Provider";
exports.chooseADateTxt = "Choose a Date";
exports.centralTimeTxt = "Central Time";
exports.utcTxt = "(UTC-6)";
exports.morningTxt = "Morning";
exports.afternoonTxt = "Afternoon";
exports.nightTxt = "Night";

exports.tellUsAboutYou='/account_block/tell_us_abouts/get_tell_us_about'
exports.patientCreateEndpoint='/account_block/tell_us_abouts/create_tell_us_about'
exports.getPatientOption=(id)=>(`/account_block/tell_us_abouts/get_patient_tell_us_about?patient_id=${id}`)

exports.providerRef = "Provider Preferences:";
exports.anythingElseTxt = "Anything else you'd like us to know";
exports.nextBtnTxt = "Next";
exports.submitTxt = "Submit";
exports.anyTimeTxt = "Any Time";
exports.schedulePref = "Scheduling Preferences:";
exports.youAreOnWaitListTxt = "You are on the waitlist!";
exports.willLetYouKnowTxt = "We will let you know when a qualified provider becomes available";
exports.loginTxt = "Login";
// Customizable Area End