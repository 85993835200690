import React from "react";



// Customizable Area Start
import { Box, styled, Typography, Button, TextField ,InputAdornment} from "@material-ui/core";
import { HeaderLogoImg, } from '../../../blocks/landingpage/src/assets';
import {HelpRequestChat} from './assets'
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import { KeyboardArrowDown } from "@mui/icons-material";
// Customizable Area End
import HelpRequestController, {
    Props,
  } from "./HelpRequestController.web";

  export default class  HelpRequest extends HelpRequestController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      return (
        // Customizable Area Start
        <StyledWrapper>
          <section className='leftBorder'></section>
          
            <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
              <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
            </Box>
            <Box className="mainContainer">
                <Typography className="mainHeading">Need Help? 
                    <span style={{color:'#1A4A42'}}>Let us Help You
                        </span></Typography>
                <Box className="subHeadingContainer">
                   <Typography className="subHeading">Whether you have a quick question or need more personalized assistance, we're here to help you every step of the way. </Typography>
                </Box>
                <Typography className="optionsHeading">Choose from our convenient options:</Typography>
            <Button className="chatBtn">New Chat 
                <img src={HelpRequestChat} style={{marginLeft:'8px'}}/>
            </Button>
            <Box className="callBackOptionsContainer">
                <Box className="card">
                 <Typography className="cardHeading">Call us Now</Typography>
                 <Box className="cardBody">
                       <Typography className="body">Get instant support from a real person</Typography>
                 </Box>
                 <Button className="cardBtn" endIcon={<PhoneForwardedIcon sx={{color:'#E46760',height:'30px',width:'30px'}}/>}>1-800-567-LIFE</Button>
                </Box>
                <Box className="card">
                      <Box className="headingBox">
                      <Typography className="cardHeading">Request a call back</Typography>
              <Typography className="cardSubHeading">Chose a time that best suits you</Typography>
                  <Typography className="cardText">*Calls will be returned today or on the next business day</Typography>
                      </Box>
                      <Box sx={{width:'400px'}}>
                     <TextField fullWidth placeholder="Choose a time frame" variant="outlined"
                      
                     InputProps={{
                        endAdornment:(
                            <InputAdornment position="end">
                                <KeyboardArrowDown color="disabled"/>
                            </InputAdornment>
                        ),
                     }}/>
                      </Box>
                      <Button className="confirmBtn">
                      Confirm Request
                      </Button>
                </Box>
            </Box>
            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',marginTop:'100px'}}>

            <Button className="backBtn" onClick={()=>this.handleNavigate('CoverageDecision')} data-test-id='backBtn'>Back</Button>
            </Box>
            </Box>

        </StyledWrapper>
        // Customizable Area End
      )
    }
}

// Customizable Area Start
const StyledWrapper=styled(Box)({
    "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 99999 },
    "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff", justifyContent: 'space-between' },
    "& .logoImg": { marginLeft: "100px", width: "162px", height: "41.24px" },
   "& .mainContainer":{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    backgroundColor:'#FFFAF6',
    height:'100%'

   },
   "& .mainHeading":{
    fontFamily:'Raleway',
    fontWeight:700,
    fontSize:'50px',
    color:'#2C2524',
    marginTop:'75px'
   },
   "& .subHeadingContainer":{
    width:'638px',
    height:'60px',
    textAlign:'center',
    marginTop:'12px'
   },
   "& .subHeading":{
    fontFamily:'Poppins',
    fontSize:'20px',
    color:'#44403C'
   },
   "& .optionsHeading":{
    marginTop:'12px',
    fontFamily:'POppins',
    fontSize:'20px',
    fontWeight:700
   },
   "& .chatBtn":{
    width:'500px',
    height:'56px',
    backgroundColor:'#E46760',
    color:'#fff',
    borderRadius:'24px',
    fontFamily:'Raleway',
    fontWeight:700,
    fontSize:'20px',
    textTransform:'capitalize',
    marginTop:'40px' 
   },
   "& .callBackOptionsContainer":{
    width:'984px',
    height:'287px',
    display:'flex',
    gap:'24px',
    marginTop:'56px'
   },
   "& .card":{
    width:'480px',
    height:'287px',
    backgroundColor:'#fff',
    paddingTop:'20px',
    paddingBottom:'20px',
    justifyContent:'space-between',
    border:'1px solid #D6D3D1',
    borderRadius:'24px',
    display:'flex',
    flexDirection:'column',
    alignItems:"center"
    
   },
   "& .cardHeading":{
    fontFamily:'Poppins',
    fontSize:'35px',
    fontWeight:500,
    color:'#2C2524'
   },
   "& .cardBody":{
    width:'348px',
    height:'60px',
    textAlign:'center'
   },
   "& .body":{
    fontFamily:'Poppins',
    fontSize:'20px',
    color:'#44403C',},
    "& .cardBtn":{
width:'396px',
height:'56px',
borderRadius:'24px',
backgroundColor:'#F5F5F4',
textTransform:'initial',
color:'#2C2524',
fontFamily:'Poppins',
fontSize:'29px',
fontWeight:700,},
"& .headingBox":{
    width:'365px',
    height:'107px',
    display:'flex',
flexDirection:'column',
alignItems:'center',
justifyContent:'space-between'
},
"& .cardSubHeading":{
    fontSize:'20px',
    fontFamily:'Poppins',
    color:'#44403C'
},
"& .cardText":{
    fontFamily:'Poppins',
    fontSize:'12px',
    color:'#44403C'
},
"&  .MuiOutlinedInput-root": {
        height: '56px',
        borderRadius: '24px',
        borderColor: '#E6C79C',

    },
    "& .MuiOutlinedInput-notchedOutline ": {
        borderColor: '#E6C79C !important'
    },

"& .confirmBtn":{
    width:'396px',
    height:'56px',
    backgroundColor:'#E7E5E4',
    borderRadius:'24px',
    textTransform:'capitalize',
    fontFamily:'Raleway',
    fontWeight:700,
    fontSize:'20px',
    color:'#A8A29E',

},
'& .MuiInputBase-input::placeholder': {
    fontSize: '20px',
  },
"& .backBtn":{
    width:'51px',
    height:'23px',
    textTransform:'capitalize',
    fontWeight:700,
    fontSize:'20px',
    color:'#78716C',
    marginLeft:'291px'
}
})
// Customizable Area End