import React from "react";

// Customizable Area Start
import { HeaderLogoImg } from '../../landingpage/src/assets';

import {
  Box,
  Button,
  styled,
  Typography,TextField
} from '@mui/material';
import {validIcon,inValidIcon} from './assets'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// Customizable Area End
import ForgotPasswordController ,{Props} from "./ProviderLoginController.web";


export default class ProviderLogin extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleSubmitClass = () => {
    if (this.upperCaseCheck() && this.differentCharacters() && this.passwordLength() && this.state.newPassword===this.state.confirmPassword) {
      return true
    }
    else {
      return false
    }
  }

  // Customizable Area End
  
render(){
    return(
        // Customizable Area Start
        <StyledWrapper>
            
        <section className='leftBorder'></section>
        <Box className="mainContainer">
          <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
            <Box className='iconContainer'>
           <PersonOutlineIcon className="icon"/>
            </Box>
          </Box>
          <Box className='subContainer'>
            <Box className='headingContainer'>
            <Typography className="mainHeading">Welcome to Total Life</Typography>
            <Typography className="subHeading">Please create a secure password </Typography>

            </Box>
            <Box className='formContainer'>
            <Box className='ContentContainer'>
                <Typography className="contentHeading">Rules for setting a new secure password:</Typography>
                <Box className='content'>
                    <Box className='textContainer'>

                <img src={this.passwordLength() ? validIcon : inValidIcon} className="validate" />
              <Typography className={this.passwordLength() ?"text":"invalidtext"}>
                Use at least 8 characters</Typography>
                    </Box>
                    <Box className='textContainer'>
                    <img src={this.differentCharacters() ? validIcon : inValidIcon} className="validate" />     
              <Typography className={this.differentCharacters() ?"text":"invalidtext"}>Use combination of different characters</Typography>
                    </Box>
                    <Box className='textContainer'>
                    <img src={this.upperCaseCheck() ? validIcon : inValidIcon} className="validate" />
              <Typography className={this.upperCaseCheck() ?"text":"invalidtext"} >
                Use at least one uppercase</Typography>
                </Box>
                    </Box>

            </Box>
            <Box className='textfieldContainer'>
            <Box className='emailContainer'>
              <Typography className="emailText"> New Password </Typography>
              <TextField className="emailField" type="password" placeholder="Enter your Password" onChange={this.handleNewPassword} data-test-id="handleCurrentPassword"/>
            </Box>
            <Box className='passwordContainer'>
              <Typography className="emailText">Confirm Password</Typography>
              <TextField className="emailField" placeholder="Enter your password" type="password" onChange={this.handleConfirmPassword} data-test-id="handleConfirmPassword"/>
            </Box>
          </Box>
          {this.handleSubmitClass()?<Button data-test-id="testSubmit"
                className="Button-style"
              // onClick={this.submitBooking}
              >Submit</Button>:<Button className="Button-style-disabled" data-test-id="testSubmit" disabled>Submit</Button>}
            </Box>
          </Box>
          <Box className='footer'>
            <Box  className='footerContainer'>
              <Typography className="footer-content">Need help?{' '}
              </Typography>
              <Button variant="outlined" endIcon={<PhoneCallbackIcon color="disabled"/>} className="call-btn">Schedule a Call</Button>

            </Box>
          </Box>
          </Box>
        </StyledWrapper>
        // Customizable Area End
    )
}

}
// Customizable Area Start
const StyledWrapper=styled(Box)({
    "& .leftBorder": { backgroundColor: "#E46760", width: 32, height: '100vh', position: 'fixed', borderRadius: '0px 16px 16px 0px', top: 0, left: 0, zIndex: 99999 },
    "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff",justifyContent:'space-between'},
    "& .logoImg": { marginLeft: "40px", width: "162px", height: "41.24px" },
    "& .subContainer":{
        marginTop:'50px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        width:'100%'
    },
    "& .mainHeading":{
        fontFamily:'Raleway',
        fontSize:'50px',
        fontWeight:700,
        color:'#2C2524'
    },
    "& .subHeading":{
        fontFamily:'Poppins',
        fontSize:'24px',
        fontWeight:400,
        fontColor:'#44403C',
        marginTop:'30px',
        textAlign:'center',
        color: '#2C2524',

    },
"& .formContainer":{
    width:'580px',
    height:'450px',
    border:'1px solid #D6D3D1',
    marginTop:'30px',
    borderRadius:'24px',
     padding:'40px 60px 40px 60px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    backgroundColor:'#fff',
    marginBottom:'100px'
},
"& .login":{
    fontFamily:'Poppins',
    fontSize:'29px',
    fontWeight:500,
    color:'#2C2524'
},
"& .contentContainer":{
    width:'80%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
},
"& .contentHeading":{
    fontFamily:'Poppins',
    fontWeight:700,
    fontSize:'20px'
},
"& .content":{
    marginTop:'10px',
    display:'flex',
    flexDirection:'column',
    width:'100%',
    paddingLeft:'40px'
},
"& .text":{
    fontFamily:'Poppins',
    fontSize:'16px',
    fontWeight:400,
    color:'#44403C',
},
"& .textfieldContainer":{
  width:'460px',
  height:'165px',
  display:'flex',
  flexDirection:'column',
  marginTop:'40px'
},
"& .emailText":{
  fontFamily:'Poppins',
  fontWeight:700,
  fontSize:'16px'
},

"& .emailField":{
  width:'100%',
  height:'44px',
      borderColor: '#E6C79C',  
  '& .MuiOutlinedInput-root': {
          borderRadius: '24px', 
          height: '100%', 
          borderColor: '#E6C79C',      
        },
        '& fieldset': {
          borderColor: '#E6C79C',
        },
        "&:hover fieldset": {
            borderColor: "#E6C79C", 
          },
          "&.Mui-focused fieldset": {
            borderColor: "#E6C79C",
          },
          "&.Mui-focused:hover": {
            borderColor: "#E6C79C",
          },
          
},
"& .passwordContainer":{
  marginTop:'20px'
},
"& .loginbtn":{
  width:'100%',
  height:'60px',
  backgroundColor:'#E7E5E4',
  borderRadius:'24px',
  textTransform:'capitalize',
  marginTop:'40px'
},

"& .login-btn-text":{
  fontFamily:'Raleway',
  fontSize:'20px',
fontWeight:700,
color:'#A8A29E'
},
"& .footer":{
    height:'100px',
    width:'100%',
    display:'flex',
    justifyContent:'flex-end',
    backgroundColor:'#fff',
    alignItems:'center'
},
"& .footer-content":{
    fontSize:'16px',
    fontWeight:700,
    fontFamily:'Poppins',
    color:'#44403C'
},
"& .call":{
    textDecoration:'underline',
    color:'#E46760',
    fontWeight:400
},
"& .validate": { width: "20px", height: "20px" },
"& .textContainer":{display:'flex'},
" & .invalidtext":{
    fontFamily:'Poppins',
    fontSize:'16px',
    fontWeight:400,
    color:'#DC2626'
},
"& .Button-style": { background: "#E46760", color: "#FFFAF6", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", width: '100%', marginTop: 40 },
'& .Button-style:hover': { background: "#E46760", color: "#FFFAF6" },
"& .Button-style-disabled": { background: "#E7E5E4", color: "#A8A29E", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", width: '100%', marginTop: 40 },

"& .mainContainer":{
  backgroundColor:'#fcf4f2'
},
"& .footerContainer":{
  marginRight:'144px',
  display:'flex',
  alignItems:'center'
},
"& .call-btn":{
  border:'2px solid #E46760',
  borderRadius: '16px',
  color: '#E46760',
textTransform:'initial',
marginLeft:"12px"
},
"& .call-btn:hover":{
  border:'2px solid #E46760',
},
"& .iconContainer":{
  height:'44px',
  width:'44px',
  borderRadius:'50%',border:'1px solid #D1DBD9',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  marginRight:'20px'
},
"& .icon":{
color:'#76928E',height:'24px',width:'24px'
}
  
})
  // Customizable Area End
