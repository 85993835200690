// Customizable Area Start
import React from "react";
import ContractReviewController, { Props, configJSON } from "./ContractReviewController.web";
import { benefitsLogo, callLogo, checkboxUncheck, dollarLogo, partiesLogo, scheduleLogo } from "./assets";
import { Box, styled, Typography } from "@material-ui/core";
// Customizable Area End


import OnboardingHeader from "./OnboardingHeader.web";

export default class ContractReview extends ContractReviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetailsList = (labelArray: string[], detailArray: string[]) => {
    return (
      <Box className="offerDetailDataContainer">
        <Box className="offerDetailDataLeft">
          {
            labelArray.map((item) => {
              return (
                <Typography className="offerDetailDataLeftTypo" key={item}>{item}:</Typography>
              )
            })
          }
        </Box>
        <Box>
          {
            detailArray.map((item) => {
              return (
                <Typography className="offerDetailDataRightTypo" key={item}>{item}</Typography>
              )
            })
          }
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { offerDetails, scheduleLabels, scheduleData, remunerationLabels, remunerationData } = this.getOfferDetails();
    // Customizable Area End
    return (
      // Customizable Area Start
      <CustomWrapper>
        <SidebarLine />
        <OnboardingHeader />
        <Box className="container">
          <Typography className="helloTitle">{configJSON.helloMeredithTitle}</Typography>
          <Typography className="excitedText">{configJSON.excitedText}</Typography>
          <Box className="offerSummaryContainer">
            <Box className="offerSummaryTop">
              <Typography className="offerSummaryTitle">{configJSON.offerSummaryTitle}</Typography>
              <Typography className="offerSummaryDesc">{configJSON.offerSummaryDesc}</Typography>

              <Box className="offerDetailsContainer">
                <Box className="offerDetailMainBox">
                  <img className="offerDetailsIcon" src={partiesLogo} alt="partiesLogo" />
                  <Box className="offerDetailBox">
                    <Typography className="offerDetailTitle">{configJSON.offerDetailPartiesTitle}</Typography>
                    <Typography className="offerDetailShortDesc">{configJSON.offerDetailShortDesc}</Typography>
                    {this.renderDetailsList(this.state.offerDetailLabels, offerDetails)}
                    <Typography className="serviceProviderText">{configJSON.serviceProviderText}</Typography>
                  </Box>
                </Box>

                <CustomDivider />

                <Box className="offerDetailMainBox">
                  <img className="offerDetailsIcon" src={scheduleLogo} alt="scheduleLogo" />
                  <Box className="offerDetailBox">
                    <Typography className="offerDetailTitle">{configJSON.offerDetailScheduleTitle}</Typography>
                    {this.renderDetailsList(scheduleLabels, scheduleData)}
                  </Box>
                </Box>

                <CustomDivider />

                <Box className="offerDetailMainBox">
                  <img className="offerDetailsIcon" src={dollarLogo} alt="dollarLogo" />
                  <Box className="offerDetailBox">
                    <Typography className="offerDetailTitle">{configJSON.offerDetailRemunerationTitle}</Typography>
                    <Typography className="offerDetailShortDesc">{configJSON.offerDetailRemunerationShortDesc}</Typography>
                    {this.renderDetailsList(remunerationLabels, remunerationData)}
                  </Box>
                </Box>

                {
                  this.state.role === "Full Time Employee" && <>
                    <CustomDivider />
                    <Box className="offerDetailMainBox">
                      <img className="offerDetailsIcon" src={benefitsLogo} alt="benefitsLogo" />
                      <Box className="offerDetailBox">
                        <Typography className="offerDetailTitle">{configJSON.offerDetailBenefitsTitle}</Typography>
                        {this.renderDetailsList(["Benefits Plan"], ["Effective on September 07, 2024"])}
                      </Box>
                    </Box>
                  </>
                }
              </Box>

              <Box className="termsAndCondContainer">
                <img src={checkboxUncheck} alt="" />
                {
                  (this.state.role === "Full Time Employee" || this.state.role === "Part Time Employee")
                  ? <>
                    <Typography className="termsAndCondTimeText">
                        {configJSON.iAgreeText} <span className="termsAndCondSpanText">{configJSON.termsAndConditionText}</span> {configJSON.comaText} <span className="termsAndCondSpanText">{configJSON.privacyPolicyText}</span> {configJSON.andSymbol} <span className="termsAndCondSpanText">{configJSON.emplyeeHandbookText}</span>
                    </Typography>
                  </>
                  : <>
                    <Typography className="termsAndCondText">
                    {configJSON.iAgreeText} <span className="termsAndCondSpanText">{configJSON.termsAndConditionText}</span> {configJSON.andSymbol} <span className="termsAndCondSpanText">{configJSON.privacyPolicyText}</span>
                    </Typography>
                  </>
                }
              </Box>
            </Box>
            <Box className="offerSummaryBottom">
              <Typography className="acceptAgreementBtn">{configJSON.acceptAgreementText}</Typography>
            </Box>
          </Box>
        </Box>
        <OnboardingFooter>
                <Typography className='needHelpText'>Need Help?</Typography>
                <Box className='scheduleCallBox'>
                    <Typography className='scheduleCallText'>Schedule a Call</Typography>
                    <img src={callLogo} alt="" />
                </Box>
        </OnboardingFooter>
      </CustomWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  backgroundColor: "#F5F5F4",
  "& .container": {
    marginLeft: "32px",
    padding: "110px 0 91px 0",
  },
  "& .helloTitle": {
    width: "fit-content",
    margin: "auto",
    fontFamily: "Raleway",
    fontSize: "41px",
    fontWeight: "600",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    color: "#2C2524",
  },
  "& .excitedText": {
    width: "fit-content",
    margin: "auto",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "36px",
    color: "#2C2524",
    marginTop: "12px",
  },
  "& .offerSummaryContainer": {
    marginTop: "24px",
  },
  "& .offerSummaryTop": {
    width: "808px",
    margin: "auto",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D6D3D1",
    borderBottom: "none",
    padding: "24px 40px 40px 40px",
    borderRadius: "24px 24px 0 0" ,
  },
  "& .offerSummaryTitle": {
    width: "fit-content",
    margin: "auto",
    fontFamily: "Poppins",
    fontSize: "29px",
    fontWeight: "500",
    lineHeight: "40.6px",
    letterSpacing: "0.5px",
    color: "#2C2524",
  },
  "& .offerSummaryDesc": {
    color: "#78716C",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "19.5px",
    marginTop: "12px",
    letterSpacing: "0.6px",
  },
  "& .offerDetailsContainer": {
    border: "1px solid #D6D3D1",
    borderRadius: "12px",
    padding: "24px 24px 24px 24px",
    marginTop: "24px",
  },
  "& .offerDetailMainBox": {
    display: "flex",
    alignItems: "flex-start",
  },
  "& .offerDetailsIcon": {
    marginTop: "4px",
  },
  "& .offerDetailBox": {
    marginLeft: "10px",
  },
  "& .offerDetailTitle": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "30px",
    color: "#2C2524",
  },
  "& .offerDetailShortDesc": {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "19.5px",
    color: "#78716C",
  },
  "& .offerDetailDataContainer": {
    marginTop: "12px",
    display: "flex",
  },
  "& .offerDetailDataLeft": {
    minWidth: "150px",
    marginRight: "55px",
  },
  "& .offerDetailDataLeftTypo": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#78716C",
    marginBottom: "4px",
  },
  "& .offerDetailDataRightTypo": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#2C2524",
    marginBottom: "4px",
  },
  "& .serviceProviderText": {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "19.5px",
    color: "#78716C",
  },
  "& .termsAndCondContainer": {
    padding: "24px 0 24px 24px",
    border: "1px solid #D6D3D1",
    borderRadius: "20px",
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
  },
  "& .termsAndCondText": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    color: "#0F172A",
    marginLeft: "8px",
  },
  "& .termsAndCondTimeText": {
    lineHeight: "30px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    marginLeft: "8px",
    color: "#0F172A",
  },
  "& .termsAndCondSpanText": {
    color: "#E46760",
    textDecoration: "underline",
  },
  "& .offerSummaryBottom": {
    width: "808px",
    margin: "auto",
    backgroundColor: "#FFFFFF",
    padding: "24px 40px 30px 40px",
    borderRadius: "0 0 24px 24px" ,
    border: "1px solid #D6D3D1",
  },
  "& .acceptAgreementBtn": {
    width: "340px",
    height: "44px",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 16px 10px 16px",
    borderRadius: "16px",
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    color: "#A8A29E",
    backgroundColor: "#E7E5E4",
    cursor: "pointer",
  },
});

const CustomDivider = styled(Box)({
  border: "1px solid #E7E5E4",
  width: "100%",
  margin: "12px 0 12px 0"
});

export const OnboardingFooter = styled(Box)({
  padding: "26px 144px 26px 0",
  backgroundColor: "#FFFFFF",
  borderTop: "1px solid #E7E5E4",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  "& .needHelpText": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "30px",
      color: "#2C2524",
      marginRight: "12px",
  },
  "& .scheduleCallBox": {
      display: "flex",
      alignItems: "center",
      border: "2px solid #E46760",
      padding: "10px 16px 10px 16px",
      borderRadius: "16px",
      cursor: "pointer",
  },
  "& .scheduleCallText": {
      fontFamily: "Raleway",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "23.48px",
      letterSpacing: "1px",
      color: "#E46760",
      marginRight: "8px",
  },
});

export const SidebarLine = styled(Box)({
  width: "32px",
  height: "100vh",
  position: "fixed",
  backgroundColor: "#203447",
});

// Customizable Area End
