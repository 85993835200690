import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperStep: string[],
  isLoading: boolean,
  patient_id: number,
  doYouPrefferRadio: string,
  providerList: string[],
  providerType: string,
  isHovered: boolean,
  isSearching: boolean,
  openDropDown: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CareTeamController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pageTopRef: any;
  dropdownRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pageTopRef = React.createRef();
    this.dropdownRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepperStep: ["About You", "Verify Insurance", "Choose Provider"],
      isLoading: false,
      patient_id: 158,
      doYouPrefferRadio: "",
      providerList: ["Therapist", "Psychiatrist", "Nutritionist/Dietitian ", "Medical Doctor",],
      providerType: "",
      isHovered: false,
      isSearching: false,
      openDropDown: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  
  async componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  
  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target as Node)) {
      this.setState({ openDropDown: false });
    }
  };

  handleRadiochange = (value: string) => {
    this.setState({ doYouPrefferRadio: value })
  }

  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  getContinueBackgroundColor = () => {
    if (this.state.isHovered) {
      return "#B6524D";
    } else if (this.state.providerType !== "Select one" && this.state.doYouPrefferRadio) {
      return "#E46760";
    } else {
      return "#D6D3D1";
    }
  }

  getContinueTextColor = () => {
    return (this.state.providerType !== "Select one" && this.state.doYouPrefferRadio)
      ? "#FFFAF6"
      : "#78716C";
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false })
  };

  onMouseEnter = () => {
    this.setState({ isHovered: (this.state.providerType !== "Select one" && this.state.doYouPrefferRadio !== "") })
  };

  handleBack = () => {
    this.handleNavigate("FlowDecision")
  };

  handleSeeRecommendation = () => {
    if (this.state.providerType !== "Select one" && this.state.doYouPrefferRadio) {
      this.setState({ isSearching: true }, () => {
        if (this.pageTopRef.current) {
          this.pageTopRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

      setTimeout(() => {
        this.handleNavigate("ChooseProvider")
      }, 3000);
    }
  }
  handleProviderType = (value: string) => {
    this.setState({
      providerType: value,
      openDropDown: false,
    })
  };

  // Customizable Area End
}
