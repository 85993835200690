import React from 'react';
import {
    Box, styled, Button, Typography,
} from "@mui/material";
import CheckInsuranceCoverageController, { Props } from "./CheckInsuranceCoverageController";
import StepperData from '../../../components/src/StepperData.web';
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';
import { emergency } from '../../../blocks/email-account-registration/src/assets';
import { ScheduleCall } from './assets';

class CheckInsuranceCoverage extends CheckInsuranceCoverageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <StyledWrapperCheckInsurance>
                    <section className='leftBorder'></section>
                    <Box className="mainContainer">
                        <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
                            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                        </Box>

                        <Box className="headerStepper">
                            <StepperData
                                key={`stepper${this.state.activeStep}`}
                                listData={this.state.listData}
                                activeStep={this.state.activeStep}
                            />
                        </Box>
                        <Box className="heading">
                            <Typography className="title">Check Your Coverage</Typography>
                            <Typography className="information">your insurance plan may cover up to 100% of your sessions.</Typography>
                            <Typography className='subtext'>  Please select your preferred payment method</Typography>
                        </Box>
                        <Box className="boxes">
                            <Box className="box1">
                                <Box className="mainContentBox">
                                    <Box className="content">
                                        <Box>
                                            <Typography className='boxTitle'> Insurance</Typography>
                                            <Box>
                                                <Typography data-test-id="TestHandleInsurance" onClick={() => this.handleInsurance("Original Medicare")} className={this.state.insurancePlan === "Original Medicare" ? 'SelectedInsurance' : 'unSelectedInsurance'}> Original Medicare</Typography>
                                                <Typography data-test-id="TestHandleInsurance1" onClick={() => this.handleInsurance("Medicare Advantage")} className={this.state.insurancePlan === "Medicare Advantage" ? 'SelectedInsurance' : 'unSelectedInsurance'}> Medicare Advantage</Typography>
                                                <Typography data-test-id="TestHandleInsurance2" onClick={() => this.handleInsurance("Other Insurance")} className={this.state.insurancePlan === "Other Insurance" ? 'SelectedInsurance' : 'unSelectedInsurance'}> Other Insurance</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ margin: 'auto', width: '600px', }}>
                            <Typography className='unSelectedInsurance' > I want to pay with cash</Typography>
                            <Typography className='underLinedText'>I’m not sure</Typography>
                        </Box>
                        <Box className='chooseProvider'>
                            <Box onClick={this.goBack} data-test-id="goBackTest" >
                                <Typography style={{ color: '#A8A29E', fontSize: 20, fontWeight: 700, lineHeight: 1, position: 'relative', top: 20, fontFamily: "Raleway", cursor: "pointer" }}>
                                    Back
                                </Typography>
                            </Box>
                            <Box style={{ display: 'block', marginTop: "40px" }}>
                                <button
                                    className="secondary-button"
                                >I don't have my insurance details on me</button>
                            </Box>
                            <Box></Box>
                        </Box>
                    </Box>

                    <Box className="footer">
                        <p className="emergencyIcon"><img src={emergency} alt="emergency" />&nbsp;
                            If this is an emergency <span style={{
                                color: '#D97706',
                                marginLeft: '6px',
                                textDecoration: 'underline',
                                cursor:"pointer",
                            }}>please call 911

                            </span>

                        </p>
                        <Box className="footerRight">
                            <span className="label-700-20"
                            >
                                Need Help?
                            </span>
                            <CustomButton
                                className="callButton" data-test-id="callBTN">

                                Schedule a call
                                <img style={{ width: "18px", height: "18px" }} src={ScheduleCall} alt="call" />
                            </CustomButton>
                        </Box>
                    </Box>

                </StyledWrapperCheckInsurance>

            </>
        )
    }
}


const StyledWrapperCheckInsurance = styled('div')({
    width: "100%",
    margin: "0 auto",
    "& *": {
        boxSizing: "border-box",
    },
    "& .logoImg": {
        marginLeft: "144px",
        width: "162px",
        height: "41.24px"
    },
    "& .header": {
        display: "flex",
        alignItems: "center",
        height: "auto",
        minHeight: "78px",
        width: "100%",
        backgroundColor: "#fff"
    },
    '& .subtext': {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "24px",
        textAlign: "center",
        color: "#2C2524",
        lineHeight: "36px",
        marginBottom: "32px"
    },
    "& .information": {
        margin: "auto",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        color: "#2C2524",
        lineHeight: "36px",
        textAlign: "center",
    },
    "& .heading": {
        margin: "auto",
        fontWeight: 400,
    },
    "& .button": {
        margin: "auto",
        marginBottom: '32px',
        "display": "flex",
        "justifyContent": "center",
        width: "600px",
        padding: '16.5px',
        border: "none",
        borderRadius: '50px',
        color: "white",
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",
        letterSpacing: '1px',
        cursor: 'pointer',
    },

    "& .secondary-button": {
        display: "flex",
        justifyContent: "center",
        lineHeight: "23.48px",
        fontFamily: "Raleway",
        textDecoration: 'underline',
        background: "none",
        letterSpacing: "1px",
        fontWeight: 700,
        fontSize: "20px",
        color: "#E46760",
        border: "none",
        cursor: "pointer",
        margin: 'auto',
    },
    '& .mainContentBox': {
        padding: '32px 20px'
    },
    "& .box1": {
        width: '640px',
        margin: 'auto',
        background: '#FFFFFF',
        border: "1px solid #D6D3D1",
        borderRadius: "24px",
    },
    "& .boxes": {
        display: "flex",
        gap: "20px",
        height: "auto",
        width: "100%",
        marginBottom: "23px",
        flexDirection: "row",
    },
    "& .title": {
        width: "100%",
        fontWeight: 700,
        height: "auto",
        fontSize: "41px",
        maxWidth: "60%",
        textAlign: "center",
        margin: "60px auto 20px ",
        fontFamily: "Raleway",
        LineHeight: '60px',
        color: "#2C2524",
        LetterSpacing: '0.5px'
    },
    "& .leftBorder": {
        backgroundColor: "#1A4A42",
        width: 32,
        height: '100vh',
        position: 'fixed',
        borderRadius: '0px 16px 16px 0px',
        top: 0,
        left: 0,
        zIndex: 99999,
    },
    "& .headerStepper": {
        "display": "flex",
        "justifyContent": "center",
    },
    "& .boxTitle": {
        fontWeight: 500,
        letterSpacing: '0.5px',
        "fontFamily": "Poppins",
        "textAlign": "center",
        marginBottom: '32px',
        "fontSize": "29px",
        width: "100%",
        "color": "#2C2524",
    },
    '& .chooseProvider': {
        display: "flex", flexDirection: "row", justifyContent: "Space-between", alignItems: 'center', marginBottom: '149px', paddingInline: "112px",
        "@media screen and (max-width:1100px)": {
            paddingInline: "50px !important"
        },
    },
    '& .SelectedInsurance': {
        marginBottom: '12px',
        fontWeight: 700,
        letterSpacing: '0.5px',
        "fontFamily": "Poppins",
        "textAlign": "center",
        "fontSize": "20px",
        width: "100%",
        "color": "#FFFFFF",
        backgroundColor: "#E46760",
        borderRadius: "24px",
        padding: "16px",
        cursor: 'pointer',
    },
    '& .unSelectedInsurance': {
        fontWeight: 700,
        letterSpacing: '0.5px',
        "fontFamily": "Poppins",
        "textAlign": "center",
        marginBottom: '12px',
        "fontSize": "20px",
        width: "100%",
        "color": "#E46760",
        border: "2px solid #E46760",
        borderRadius: "24px",
        padding: "16px",
        cursor: "pointer"
    },
    '& .underLinedText': {
        fontWeight: 700,
        "textAlign": "center",
        fontSize: "20px",
        color: "#807C7C",
        marginTop: "24px",
        textDecoration: "underline",
        cursor: "pointer"
    },
    "& .footer": {
        minHeight: "96px",
        display: "flex",
        justifyContent: "center",
        borderTop: "1px solid #E7E5E4",
        alignItems: "center",
        "@media screen and (max-width:900px)": {
            paddingBottom: "12px",
            display: "block",
        },
    },
    "& .emergencyIcon": {
        alignItems: "center",
        display: "flex",
        fontFamily: "Poppins",
        fontWeight: "400",
        marginLeft: '141px',
        fontSize: "20px",
        marginRight: '459px',
        "@media screen and (max-width: 1550px)": {
            marginRight: '423px',
            marginLeft: '129px',
        },
        "@media screen and (max-width: 1500px)": {
            marginRight: '338px',
            marginLeft: '100px',
        },
        "@media screen and (max-width: 1400px)": {
            marginRight: '242px',
            marginLeft: '95px',
        },
        "@media screen and (max-width: 1250px)": {
            marginRight: '211px',
            marginLeft: '64px',
        },
        "@media screen and (max-width: 1200px)": {
            marginRight: '100px',
            marginLeft: '45px',
        },
        "@media screen and (max-width: 1100px)": {
            marginRight: '80px',
            marginLeft: '30px',
        },
        "@media screen and (max-width: 950px)": {
            marginRight: '35px',
            marginLeft: '15px',
        },
        "@media screen and (max-width: 900px)": {
            justifyContent: "center",
        },
        "@media screen and (max-width:500px)": {
            display: "block",
            textAlign: 'center',
        },
    },
    "& .footerRight": {
        display: "flex",
        marginRight: '141px',
        alignItems: "center",
        gap: "12px",
        "@media screen and (max-width:1500px)": {
            marginRight: '100px'
        },
        "@media screen and (max-width:1550px)": {
            marginRight: '129px'
        },
        "@media screen and (max-width:1400px)": {
            marginRight: '95px'
        },
        "@media screen and (max-width:1250px)": {
            marginRight: '64px'
        },
        "@media screen and (max-width:1200px)": {
            marginRight: '45px',
        },
        "@media screen and (max-width:1100px)": {
            marginRight: '30px',
        },
        "@media screen and (max-width:900px)": {
            justifyContent: "center",
        },
        "@media screen and (max-width:500px)": {
            textAlign: 'center',
            display: 'block'
        },
    }, "& .label-700-20": {
        fontWeight: 700,
        cursor: 'pointer',
        fontSize: "20px",
        fontFamily: "Poppins",
    },
    "& .callButton": {
        display: "flex",
        letterSpacing: "1px",
        gap: "8px",
        borderRadius: "16px",
        fontSize: "20px",
        border: "2px solid #E46760",
        padding: "10px 16px 10px 16px",
        color: "#E46760",
        alignItems: "center",
        fontFamily: "Raleway",
        fontWeight: 700,
        lineHeight: "36px",
        background: "#ffffff",
        "@media screen and (max-width:900px)": {
            padding: "6px"
        },
        "@media screen and (max-width:500px)": {
            margin: "auto"
        },
    },
})

const CustomButton = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    textTransform: 'none',
    fontSize: '20px',
    "&.call-button": {
        borderRadius: "16px",
        border: "2px solid #E46760",
        color: "#E46760",
        padding: "0 16px",
        height: "44px",
        gap: "8px",
        "@media screen and (max-width:700px)": {
            width: "158px",
            marginLeft: "-49px",
        },
        "@media screen and (max-width:500px)": {
            marginRight: "11px",
            padding: "0 10px",
        },
    },
    "&.call-button::hover": {
        color: "#E46760",
        border: "2px solid #E46760",
    },
}));


export default CheckInsuranceCoverage;