export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");

export const dollarLogo = require("../assets/dollar_logo.png").default;
export const partiesLogo = require("../assets/parties_logo.png").default;
export const scheduleLogo = require("../assets/schedule_logo.png").default;
export const callLogo = require("../assets/call_icon.png").default;
export const checkboxUncheck = require("../assets/checkbox_uncheck.png").default;
export const benefitsLogo = require("../assets/benefits_icon.png").default;
export const searchIcon = require("../assets/search_icon.png").default;
export const locationIcon = require("../assets/location_icon.png").default;
export const dropdownIcon = require("../assets/dropdown_icon.png").default;
export const uploadIcon = require("../assets/upload_icon.png").default;
export const calendarIcon = require("../assets/calendar_icon.png").default;
export const profileIcon = require("../assets/profile_icon.png").default;
export const webLogo = require("../assets/total_life_logo.png").default;
export const downloadIcon = require("../assets/download_icon.png").default;
export const tickIcon = require("../assets/tickIcon.png").default;
export const leftIcon = require("../assets/leftIcon.png").default;
export const successIcon = require("../assets/success_icon.png").default;
