import React from "react";

// Customizable Area Start
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';

import {
  Box,
  Button,
  styled,
  TextField,
  Typography,
} from '@mui/material';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <StyledWrapper>
        <section className='leftBorder'></section>
        <Box className="mainContainer">
          <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
          </Box>
          <Box className='headingContainer'>
            <Typography className="mainHeading">Welcome to Total Life</Typography>
            <Typography className="subHeading">Your path to wellness begins here</Typography>

          </Box>
          <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>

          <Box className='subContainer'>
           <Box sx={{display:'flex',justifyContent:'center'}}>
            <Typography className="login">Log in</Typography>
           </Box>
          <Box className='textfieldContainer'>
            <Box className='emailContainer'>
              <Typography className="emailText">Email</Typography>
              <TextField className="emailField" placeholder="Enter your email"/>
            </Box>
            <Box className='passwordContainer'>
              <Typography className="emailText">Password</Typography>
              <TextField className="emailField" placeholder="Enter your password" type="password"/>
            </Box>
          </Box>

          <Box className='details'>
            <Typography className="account">Create New Account</Typography>
            <Typography className="forgot">Forgot Password? <span className="underlinetext">Reset</span></Typography>
            <Typography className="help">Need help? <span className="underlinetext">Call us or request a callback</span></Typography>

          </Box>
          <Button className="loginbtn">
            

            Log in
            
            </Button>
          </Box>
          </Box>
          </Box>
      </StyledWrapper>
      // Customizable Area End
    );
  }

}

const StyledWrapper=styled(Box)({
"& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', borderRadius: '0px 16px 16px 0px', top: 0, left: 0, zIndex: 99999 },
  "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff" },
  "& .logoImg": { marginLeft: "144px", width: "162px", height: "41.24px" },
  "& .headingContainer":{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    marginTop:'50px'
  },
  "& .mainHeading":{
    fontFamily:"Raleway",
    fontWeight:700,
    fontSize:'50px'
  },
  "& .subHeading":{
    fontFamily:'Poppins',
    marginTop:'30px',
    fontSize:'24px',
    fontWeight:400
  },
  "& .subContainer":{
    border:'1px solid #D6D3D1',
    borderRadius:'24px',
    padding:'40px 60px 40px 60px',
    marginBottom:'150px',
    height:'530px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    marginTop:'30px'
  },
  "mainContainer":{
    display:'flex',
    justifyConter:'center'
  },
  "& .login":{
    fontSize:'30px',
    fontFamily:'Poppins',
    fontWeight:500
  },
"& .textfieldContainer":{
  width:'460px',
  height:'165px',
  display:'flex',
  flexDirection:'column',
  marginTop:'40px'
},
"& .emailText":{
  fontFamily:'Poppins',
  fontWeight:700,
  fontSize:'16px'
},

"& .emailField":{
  width:'100%',
  height:'44px',
  '& .MuiOutlinedInput-root': {
          borderRadius: '24px', 
          height: '100%',       
        },
        '& fieldset': {
          borderColor: '#E6C79C',
        },
},
"& .passwordContainer":{
  marginTop:'20px'
},
"& .details":{
  marginTop:'30px',
  width:'460px',
  height:'100px',
  display:'flex',
  flexDirection:'column',
},
"& .account":{
  fontFamily:'Poppins',
  fontSize:'16px',
  fontWeight:400,
  textDecoration:'underline',
  color:'#E46760'
},
"& .forgot":{
  fontFamily:'Poppins',
  fontSize:'16px',
  fontWeight:400,
  color:'#44403C',
  marginTop:'12px'
},
"& .help":{
  color:'#44403C',
  fontFamily:'Poppins',
  fontSize:'16px',
  fontWeight:400,
   marginTop:'12px',
 

},

"& .underlinetext":{
   textDecoration:'underline',
  color:'#E46760'
},
"& .loginbtn":{
  width:'100%',
  height:'60px',
  backgroundColor:'#E7E5E4',
  borderRadius:'24px',
  textTransform:'capitalize',
  marginTop:'40px',
  fontFamily:'Raleway',
  fontSize:'20px',
fontWeight:700,
color:'#A8A29E'
},
'& .loginbtn:hover': { background: "#E46760", color: "#FFF" },

"& .login-btn-text":{
  fontFamily:'Raleway',
  fontSize:'20px',
fontWeight:700,
color:'#A8A29E'
}


})
  // Customizable Area End

