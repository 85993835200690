import React from 'react';
import { Box, styled, Button, Typography, Checkbox, StepLabel, Stepper, Step, Modal } from "@mui/material";
import FollowUpSessionController, { Props } from "./FollowUpSessionController";
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';
import { CheckboxIcon, Checkbox_checked, Disabled_checkbox } from "../../customform/src/assets";
import { calenderIcon, confirmedIcon, iCallIcon, OutlookIcon, ProfileImage, } from './assets';

class FollowUpSession extends FollowUpSessionController {
    constructor(props: Props) {
        super(props);
    }
    handleHead = () => {
        if (this.state.BookFollowUpSession) {
            return <Box> <Typography className="titleForFollowUp">Book Follow-Up Session</Typography>
                <Typography className='subtext'> Therapy is an important step towards feeling better. Studies show that regular weekly therapy can improve your health. We recommend you select a few more sessions to block your therapist's availability.</Typography></Box>
        }
        else {
            return <Box> <Box className="titleHead"><Typography className="title">Your Care Plan </Typography><Typography className="titleHighlighted"> is confirmed</Typography><img style={{ height: "48px", width: "48px" }} src={confirmedIcon} /></Box>
                <Typography className='subtext'> Take this chance to review your upcoming session details before logging into the platform.</Typography></Box>
        }
    };

    render() {

        return (

            <StyledWrapperCheckInsurance>
                <section className='leftBorder'></section>
                <Box className="mainContainer">
                    <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
                        <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                    </Box>

                    <Box className="stepper-width">
                        <Stepper alternativeLabel activeStep={this.state.activeStep}>
                            {this.state.steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel><Typography sx={{fontFamily:'Poppins',fontSize:'16px',fontWeight:700}}>

                                        {label}
                                    </Typography>
                                        </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box className="heading">
                        {this.handleHead()}

                    </Box>

                    {this.state.BookFollowUpSession ? <Box className="box1">
                        <Typography className="showPolicy1">Provider:</Typography>
                        <Box className="profile-parent">
                            <Box>
                                <img className="Profile-image-style" src={ProfileImage} />
                            </Box>
                            <Box>
                                <Typography className="orderSummaryHeader1">Meredith J. Grey </Typography>
                            </Box>
                        </Box>
                        <Box className="marginTopStyle1">
                            <Typography className="boldStyle">Booked Session:</Typography>
                            <Box className="borderBoxStyle1">
                                <Checkbox className='disabledCheckBox' checked data-test-id="useSameInformation" icon={<img src={Disabled_checkbox} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Disabled_checkbox} style={{ width: "20px", height: "20px" }} />} />
                                <Box style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                    <Typography className='dateStyle'>Wed, Aug 15</Typography>{" "}
                                    <span className='dateStyle2'> - </span>{" "}
                                    <Typography className='dateStyle'>1:30 - 2:30 AM</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="AddmoreSession">
                            <Typography className="moreSession">Add more sessions:</Typography>
                            {this.state.checkBoxes.map((item: any, index: number) => {
                                return <Box className={item.checkbox === true ? "selectedBorderBoxStyle" : "borderBoxStyle"}>
                                    <Checkbox data-test-id="changeCheckBoxTest" checked={item.checkbox} icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Checkbox_checked} style={{ width: "20px", height: "20px" }} />} onChange={(item) => this.changeCheckBox(item, index)} />
                                    <Box style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                        <Typography className='dateStyle'>{item.checkBoxDayAndDate}</Typography>{" "}
                                        <span className='dateStyle2'> - </span>{" "}
                                        <Typography className='dateStyle'>{item.checkBoxTime}</Typography>
                                    </Box>
                                </Box>
                            })}
                        </Box>
                        <Box className="followUpStyleParent">
                            <Checkbox data-test-id="useSameInformation" icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Checkbox_checked} style={{ width: "20px", height: "20px" }} />} />
                            <Typography className='followUpStyle'>Continue without follow-ups</Typography>
                        </Box>
                    </Box> : <Box className="careplanParent"> <Box className="box2">
                        <Typography className='onlineSessionHead'>Online Session Details</Typography>
                        <Box>                        <Typography className="showPolicy1">Provider:</Typography>
                            <Box className="profile-parent">
                                <Box>
                                    <img className="Profile-image-style" src={ProfileImage} />
                                </Box>
                                <Box>
                                    <Typography className="orderSummaryHeader1">Meredith J. Grey </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="marginTopStyle">
                            <Typography className="boldStyle">Session:</Typography>
                            <Box className="SessionsParent"> <Box className="borderBoxStyle1">
                                <Typography className='dateStyle'>Wed, Aug 15</Typography>{" "}
                                <span className='dateStyle2'> - </span>{" "}
                                <Typography className='dateStyle'>1:30 - 2:30 AM</Typography>
                            </Box>
                                {this.state.addedSessions && this.state.addedSessions.map((item: any, index: number) => {
                                    return <Box className="borderBoxStyle">
                                        <Typography className='dateStyle'>{item.checkBoxDayAndDate}</Typography>{" "}
                                        <span className='dateStyle2'> - </span>{" "}
                                        <Typography className='dateStyle'>{item.checkBoxTime}</Typography>
                                    </Box>
                                })}
                            </Box>
                        </Box>
                    </Box>
                        <Box className="box2">
                            <Typography className='onlineCalenderHead' style={{ backgroundColor: "#FFFAF6" }}>Add to your calendar</Typography>
                            <Box className="SessionsParent"> {[{ name: "Calendar", icon: calenderIcon }, { name: "iCal", icon: iCallIcon }, { name: "Outlook", icon: OutlookIcon }].map((item) => {
                                return <Box className="borderBoxStyle2"><img className='calenderIcon' src={item.icon} />
                                    <Typography className='dateStyle' >{item.name}</Typography>
                                </Box>
                            })}
                            </Box>
                        </Box>
                    </Box>}
                </Box>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    {this.state.BookFollowUpSession ? <Button data-test-id="handleSubmitTest" onClick={this.handleSubmit} className={this.state.BookFollowUpSession && !this.checkCheckBoxes() ? "Disabled-Button-style" : "Button-style"} > Confirm</Button> : <Button data-test-id="handleSubmitTest" onClick={this.handleSubmit} className="Button-style1" > Prepare for Your Appointment</Button>}
                </Box>
                {!this.state.BookFollowUpSession && <Typography className='loginAccount'>Login to see my Account</Typography>}
                <Modal
                    open={this.state.openPopUp}
                    onClose={this.closePopupModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    data-test-id="testModal"
                >
                    <Box sx={webStyle.modalParent}>
                        <Typography sx={webStyle.ModalHead}>Are you sure you want to continue without follow-up sessions ?</Typography>
                        <Typography style={webStyle.subHead}>Booking follow-ups will help build consistency in your  treatment plan towards feeling better.</Typography>
                        <Box >
                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                <Button data-test-id="openFollowUpTest" onClick={this.openFollowUp} sx={webStyle.buttonStyle} >Yes, I'd like to book follow-ups</Button>
                            </Box>
                            <Typography data-test-id="handleContinueWithoutFollowUpTest" onClick={this.handleContinueWithoutFollowUp} style={webStyle.loginAccount}>Continue without booking a follow-up</Typography>
                        </Box>
                    </Box>
                </Modal>
            </StyledWrapperCheckInsurance >
        )
    }
}
const webStyle = {
    modalParent: {
        position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", maxWidth: "600px", width: "100%", background: "white", padding: "24px", borderRadius: "24px"
    },
    ModalHead: { paddingBlock: "24px", fontWeight: "500px", fontSize: "29px", fontFamily: "Poppins", color: "#2C2524", textAlign: "center", alignItems: "center" },
    subHead: { fontWeight: 400, fontSize: "20px", fontFamily: "Poppins", color: "#44403C", margin: "40px" },
    buttonStyle: {
        margin: "24px auto", backgroundColor: "#E46760", color: "#FFFAF6", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", maxWidth: "392px", width: '100%', justifyContent: "center", alignItems: "center",
        '&:hover': {
            backgroundColor: "#E46760", color: "#FFFAF6"
        }
    },
    loginAccount: { display: "flex", justifyContent: "center", fontFamily: "Raleway", fontSize: "20px", fontWeight: 700, textDecoration: "underline", color: "#78716C", marginBottom: "8px", cursor: "pointer" }
}
const StyledWrapperCheckInsurance = styled('div')({
    width: "100%",
    margin: "0 auto",
    "& .calenderIcon": { height: "24px", weight: "24px" },
    "& .ModalParent": {
        maxWidth: "600px", width: "100%", height: "auto", justifyContent: "center", display: 'flex',
        alignItems: 'center',
    },
    "& .modalHead": { fontFamily: "Poppins", fontWeight: "500px", color: "#2C2524", fontSize: "29px", paddingBlock: "24px", textAlign: "center", alignItems: "center", justifyContent: "center" },
    "& .loginAccount": { marginBottom: "50px", display: "flex", justifyContent: "center", fontFamily: "Raleway", fontSize: "20px", fontWeight: 700, textDecoration: "underline", color: "#78716C", cursor: "pointer" },
    '& .onlineSessionHead': { fontWeight: 500, fontSize: "29px", fontFamily: "Poppins", color: "#2C2524", textAlign: "center" },
    '& .onlineCalenderHead': { fontWeight: 500, fontSize: "29px", fontFamily: "Poppins", color: "#2C2524", textAlign: "center" },
    '& .titleHead': { display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center", margin: "48px auto ", gap: "10px" },
    '& .stepper-width': {
        maxWidth: "600px", width: '100%', fontfamily: "Poppins", margin: "32px auto 0px",
        '& .MuiStepConnector-root': {
            top: '15px !important',
            '& span': {
                borderTopWidth: '3px !important',
            }
        }
    },
    "& .Profile-image-style": { height: "56px", width: "56px", borderRadius: "100px" },
    "& .MuiCheckbox-root.Mui-checked": { color: "#1A4A42", height: "20px", width: "20px" },
    "& .disabledCheckBox.MuiCheckbox-root.Mui-checked": { color: "#D6D3D1" },
    "& .MuiStepIcon-root.Mui-active": { color: "#E46760", height: "32px", width: "32px" },
    "& .MuiStepIcon-root.Mui-completed": { color: "#1A4A42", height: "32px", width: "32px" },
    "& .MuiCheckbox-root": { height: "20px", width: "20px" },
    "& .profile-parent": { display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" },
    "& .orderSummaryHeader1": { fontWeight: 700, fontSize: "20px", fontFamily: "Poppins", color: "#2C2524" },
    "& .marginTopStyle": { gap: "8px" },
    "& .marginTopStyle1": { marginTop: "32px" },
    "& .Button-style": { margin: "66px auto", backgroundColor: "#E46760", color: "#FFFAF6", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", maxWidth: "392px", width: '100%', justifyContent: "center", alignItems: "center" },
    "& .Button-style:hover": { backgroundColor: "#E46760", color: "#FFFAF6" },
    "& .Button-style1": { margin: "55px auto 32px", backgroundColor: "#E46760", color: "#FFFAF6", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", maxWidth: "392px", width: '100%', justifyContent: "center", alignItems: "center" },
    "& .Button-style1:hover": { margin: "55px auto 32px", backgroundColor: "#E46760", color: "#FFFAF6" },
    "& .Disabled-Button-style": { margin: "66px auto", background: "#D6D3D1", color: "#78716C", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", maxWidth: "392px", width: '100%', justifyContent: "center", alignItems: "center" },
    "& .Disabled-Button-style:hover": { background: "#D6D3D1", color: "#78716C" },
    "& .AddmoreSession": { display: "flex", flexDirection: "column", gap: "8px", paddingTop: "32px" },
    "& .SessionsParent": { display: "flex", flexDirection: "column", gap: "12px" },
    "& .boldStyle": { fontWeight: 700, fontSize: "16px", fontFamily: "Poppins", color: "#78716C", marginBottom: "4px" },
    "& .moreSession": { fontWeight: 700, fontSize: "24px", fontFamily: "Poppins", color: "#2C2524", textAlign: "center" },
    "& *": { boxSizing: "border-box" },
    "& .showPolicy1": { fontWeight: 700, fontSize: "16px", fontFamily: "Poppins", color: "#78716C", textAlign: "start" },
    "& .borderBoxStyle1": { alignItems: "center", display: "flex", flexDirection: "row", gap: "5px", border: "2px solid #A8A29E", background: "#F5F5F4", borderRadius: "26px", paddingBlock: "7px", paddingInline: "16px" },
    "& .borderBoxStyle2": { alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "row", gap: "12px", border: "2px solid #A8A29E", background: "#F5F5F4", borderRadius: "26px", paddingBlock: "7px", paddingInline: "16px" },
    "& .borderBoxStyle": { display: "flex", alignItems: "center", flexDirection: "row", gap: "5px", border: "2px solid #A8A29E", borderRadius: "26px", paddingBlock: "7px", paddingInline: "16px" },
    "& .selectedBorderBoxStyle": { display: "flex", alignItems: "center", flexDirection: "row", gap: "5px", border: "2px solid #E46760", borderRadius: "26px", paddingBlock: "7px", paddingInline: "16px" },
    "& .followUpStyle": { color: "#DC2626", fontWeight: "400", fontSize: "20px", fontFamily: "Poppins" },
    "& .followUpStyleParent": { display: "flex", flexDirection: "row", gap: "12px", alignItems: "center", marginTop: "28px" },
    '& .dateStyle': { fontWeight: 700, fontSize: "20px", fontFamily: "Poppins", color: "#44403C", minWidth: "132px" },
    '& .dateStyle2': { fontWeight: 400, fontSize: "20px", fontFamily: "Poppins", color: "#44403C" },
    "& .logoImg": { marginLeft: "144px", width: "162px", height: "41.24px" },
    "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff" },
    "& .titleForFollowUp": { width: "100%", fontWeight: 500, height: "auto", fontSize: "35px", maxWidth: "60%", textAlign: "center", margin: "48px auto ", fontFamily: "Raleway", LineHeight: '60px', color: "#2C2524", LetterSpacing: '0.5px' },
    "& .title": { fontWeight: 700, height: "auto", fontSize: "35px", textAlign: "center", fontFamily: "Raleway", LineHeight: '60px', color: "#2C2524", LetterSpacing: '0.5px' },
    '& .titleHighlighted': { fontWeight: 700, height: "auto", fontSize: "35px", textAlign: "center", fontFamily: "Raleway", LineHeight: '60px', color: "#1A4A42", LetterSpacing: '0.5px' },
    '& .subtext': { fontWeight: 400, width: "100%", fontFamily: "Poppins", fontSize: "20px", textAlign: "center", justifyContent: "center", alignItems: "center", color: "#78716C", lineHeight: "36px", margin: "32px auto 32px ", maxWidth: "856px" },
    "& .heading": { margin: "auto", fontWeight: 400 },
    "& .box1": { padding: '32px', width: '440px', margin: 'auto', background: '#FFFFFF', border: "1px solid #D6D3D1", borderRadius: "24px" },
    "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', borderRadius: '0px 16px 16px 0px', top: 0, left: 0, zIndex: 99999 },
    "& .box2": { padding: '32px', width: '440px', background: '#FFFFFF', border: "1px solid #D6D3D1", borderRadius: "24px", display: "flex", flexDirection: "column", gap: "32px" },
    "& .headerStepper": { display: "flex", justifyContent: "center" },
    "& .careplanParent": { display: "flex", flexDirection: "row", gap: "23px", justifyContent: "center" }
})

export default FollowUpSession;