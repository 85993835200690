// Customizable Area Start
import React from "react";
import { Box, styled, Typography } from "@material-ui/core";
import UploadDocumentController, { Props, configJSON } from "./UploadDocumentController.web";
import { ImportantSymbol, InputsLabel } from "./OnboardingForm.web";
import { uploadIcon } from "./assets";
// Customizable Area End

export default class UploadDocument extends UploadDocumentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <CustomWrapper>
        <InputsLabel>{this.props.label} {this.props.isMandatory && <ImportantSymbol>{configJSON.importantSymbol}</ImportantSymbol> } </InputsLabel>
        <Box className="uploadBox">
          <Box className="innerBox">
            <img src={uploadIcon} alt="" />
            <Typography className="uploadText">Click or Drop files to upload</Typography>
          </Box>
          <input type="file" name={this.props.name} />
        </Box>
        <Typography className="instruction">{this.props.instruction}</Typography>
      </CustomWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  width: "100%",
  "& .uploadBox": {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "100%",
    boxSizing: "border-box",
  },
  "& .innerBox": {
    width: "100%",
    border: "1px dashed #A8A29E",
    color: "gray",
    backgroundColor: "white",
    borderRadius: "24px",
    fontSize: "20px",
    fontWeight: "bold",
    padding: "32px 0 32px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    boxSizing: "border-box",
  },
  "& .uploadText": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: '#A8A29E',
    marginTop: "8px",
  },
  "& .uploadBox input[type=file]": {
    fontSize: "100px",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
  },
  "& .instruction": {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "19.5px",
    color: "#44403C",
    marginTop: "4px",
  },
})
// Customizable Area End
