import React from "react";

// Customizable Area Start
import MoodIcon from '@mui/icons-material/Mood';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import {
  Box,
  Button,
  styled,
  Typography,Step,Stepper,StepLabel
} from '@mui/material';
// Customizable Area End

import PatientRegistrationController, {
  Props
} from "./PatientRegistrationController.web";

export default class PatientRegistration extends PatientRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
     
    // Customizable Area End
  }
  render() {

    return (
      // Customizable Area Start
      <StyledWrapper>
        <section className='leftBorder'></section>
        <Box className='mainContainer'>
        <Stepper alternativeLabel className="stepper">{this.state.steps.map((label, index) => (<Step key={label} sx={{'& .MuiStepLabel-root .Mui-active': {color: '#E46760',},'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {fill: '#FFFFFF',},}}><StepLabel><Typography sx={index == 0 ? { fontFamily: 'Poppins', fontSize: '16px', fontWeight: 700, color: '#2C2524' } : {}}>{label}</Typography></StepLabel></Step> ))}</Stepper>  
<Typography className="mainHeading">How are you feeling?</Typography>
<Box className='subHeadingContainer'>
    <Typography className="subHeading">Let’s get started with a few questions about how you’re doing so we can build a care team customized to your needs.</Typography>
</Box>
           <Box className='ButtonContainer'>
            <Button startIcon={<MoodIcon/>} 
                 onClick={()=>this.setState({selected:'Very Well'})}
               className={this.state.selected=='Very Well'?'emoji-btn-new':'emoji-btn'} data-test-id="emoji-btn">Very Well</Button>
            <Button  onClick={()=>this.setState({selected:'Well'})}
               className={this.state.selected=='Well'?'emoji-btn-new':'emoji-btn'} startIcon={<SentimentSatisfiedAltIcon/>} data-test-id="emoji-btn">Well</Button>
            <Button onClick={()=>this.setState({selected:'Neutral'})}
               className={this.state.selected=='Neutral'?'emoji-btn-new':'emoji-btn'} startIcon={<SentimentSatisfiedIcon/>} data-test-id="emoji-btn">Neutral</Button>
            <Button onClick={()=>this.setState({selected:'Poor'})}
               className={this.state.selected=='Poor'?'emoji-btn-new':'emoji-btn'} startIcon={<SentimentVeryDissatisfiedIcon/>} data-test-id="emoji-btn">Poor</Button>
            <Button onClick={()=>this.setState({selected:'Very Poor'})}
               className={this.state.selected=='Very Poor'?'emoji-btn-new':'emoji-btn'} startIcon={<MoodBadIcon/>} data-test-id="emoji-btn">Very Poor</Button>

            
            </Box> 
            <Box className='continue-btn-Container'>
            <Button className='skip-btn'>Back</Button>
            <Button className="continue-btn" onClick={this.handleNavigate} data-test-id="continuebtn">Continue</Button>
            <Button endIcon={<SkipNextIcon/>} className="skip-btn">
            Skip this Step 
            </Button>
            </Box>


        </Box>
        <Box className='Bottom-Footer'>
         <Box className='emergencyContainer'>
            <WarningAmberIcon className="warning-icon"/>
            <Typography className="emergency-content">If this is an emergency</Typography>
            <Typography className="emergency-Number">please call 911</Typography>
         </Box>
         <Box className='callBtnContainer'>
            <Typography className="HelpContent">Need Help?</Typography>
            <Button variant='outlined' endIcon={<PhoneCallbackIcon color="disabled"/>} className="CallButton">Schedule a Call</Button>
         </Box>
        </Box>
        </StyledWrapper>
        
        // Customizable Area Start
        
    )
}
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    "& .Bottom-Footer":{
        width:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-around',
        backgroundColor:'#fff',
        height:'100px',
        borderTop:'1px solid #E7E5E4'
    },
    "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', borderRadius: '0px 16px 16px 0px', top: 0, left: 0, zIndex: 99999 },
    "& .mainContainer":{
    display:'flex',
    alignItems:'center',
    flexDirection:'column',
    backgroundColor:'#FFFAF6',

},
"& .stepper":{
    width:'600px',
    marginTop:'32px',},
    "& .steplabel":{
        fontFamily:'Poppins',
        fontWeight:700,
        fontSize:'16px'
    },
    "& .CallButton":{
        width:'229px',
        height:'44px',
        borderRadius:'24px',
        border:'2px solid #E46760',
        textTransform:'initial',
    fontFamily:'Raleway',
    fontSize:'20px',
    fontWeight:700,
    color:'#E46760',
    "&:hover":{
        border:'2px solid #E46760', 
    }
    },
"& .label-background":{
    backgroundColor:'#E46760',
    color:'white'
},
"& .callBtnContainer":{
    width:'356px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'

},
"& .mainHeading":{
    fontFamily:'Raleway',
    fontWeight:700,
    color:'#2C2524',
    fontSize:'41px',
    marginTop:'48px'
},
"& .subHeadingContainer":{
    width:'733px',
    height:'72px',
    textAlign:'center'
},
"& .subHeading":{
    fontFamily:'Poppins',
    fontSize:'22px',
    color:'#2C2524',
    marginTop:'32px'
},
"& .emergencyContainer":{
  width:'412px',
  display:'flex',
  justifyContent:'space-between',
 
},
"& .buttonContainer":{
    width:'700px',
    height:'336px',
    display:'flex',
    flexDirection:'column',
    paddingTop:'24px',
    paddingBottom:'24px',
    justifyContent:'space-between',
    marginTop:'32px',
    alignItems:'center',
},
"& .emoji-btn":{
    width:'340px',
    height:'48px',
    padding:'4px 16px 4px 16px',
    textTransform:'capitalize',
    fontFamily:'Poppins',
    fontSize:'20px',
    color:'#44403C',
    justifyContent:'flex-start',
    
    borderRadius:'26px',
    backgroundColor:'#E7E5E4',
    "&:hover":{
        backgroundColor:'#E7E5E4',
    }
    
},
"& .emoji-btn-new":{
    backgroundColor:'#E46760',color:'white',
    width:'340px',
    height:'48px',
    padding:'4px 16px 4px 16px',
    textTransform:'capitalize',
    fontFamily:'Poppins',
    fontSize:'20px',
    justifyContent:'flex-start',
    fontWeight:700,
    borderRadius:'26px',
    "&:hover":{
        backgroundColor:'#E46760'
    }
    
},
"& .continue-btn-Container":{
    width:'1231px',
    height:'56px',
    display:'flex',
    justifyContent:'space-between',
    marginTop:'40px',
    alignItems:'center',
    marginBottom:'126px'
},
"& .continue-btn:hover":{
    backgroundColor:'#E46760',
},
"& .skip-btn":{
    width:'200px',
    height:'23px',
    fontFamily:'Raleway',
    color:'#78716C',
    fontWeight:700,
    justifyContent:'flex-start',
    textTransform:'capitalize'
   
},
"& .continue-btn":{width:'392px',height:'56px',borderRadius:'24px',textTransform:'capitalize',backgroundColor:'#E46760',fontFamily:'Raleway',fontSize:'20px',fontWeight:700,color:'#fff'},
"& .warning-icon":{color:'#D97706',width:'24px',height:'24px'},
"& .emergency-content":{color:'#2C2524',fontFamily:'Poppins',fontSize:'20px',},
"& .emergency-Number":{color:'#D97706',fontFamily:'Poppins',fontSize:'20px',textDecoration:'underline'},
"& .HelpContent":{fontFamily:'Poppins',fontSize:'20px',fontWeight:700,color:'#2C2524'},




})

// Customizable Area End