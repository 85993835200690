// Customizable Area Start
import React from "react";
import { Box, Step, StepLabel, Stepper, styled, TextField, Typography } from "@mui/material";
import MatchMakingMultiSelectController, { Props, configJSON } from "./MatchMakingMultiSelectController.web";
import { skipIcon } from "../assets";
import RegistrationFooter from "../components/RegistrationFooter.web";
import Loader from "../../../../components/src/Loader";
// Customizable Area End

export default class MatchMakingMultiSelect extends MatchMakingMultiSelectController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <SidebarLine />
        {
          this.state.isLoading ? (
            <Loader loading={true} />
          ) : (
            <CustomWrapper>
              <Box className="stepperBox">
                <Stepper alternativeLabel className="stepper">
                  {this.state.stepperSteps.map((label, index) => (
                    <Step
                      key={label}
                      sx={{
                        '& .MuiStepLabel-root .Mui-active': {
                          color: '#E46760',
                        },
                        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                          fill: '#FFFFFF',
                        },
                      }}
                    >
                      <StepLabel>
                        <Typography sx={index == 0 ? { fontFamily: 'Poppins', fontWeight: 700, color: "#2C2524" } : { fontFamily: 'Poppins', fontWeight: 700, color: "#78716C" }}>
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Box className="headingsBox">
                <Typography className="thankYouSharingHeading">{this.state.heading}</Typography>
                <Typography className="pleaseShareDetailsHeading">{this.state.subHeading}</Typography>
                <Typography className="chooseFollowingHeading">{configJSON.chooseFollowingHeading}</Typography>
                <Typography className="selectAllSubHeading">{configJSON.selectAllSubHeading}</Typography>
              </Box>

              <Box className="optionList">
                {
                  this.state.optionList && this.state.optionList.map((option) => {
                    return (
                      <Typography
                        data-test-id="toggleSelection"
                        key={option.id}
                        className="singleOption"
                        style={this.getOptionStyle(option.id)}
                        onClick={() => this.toggleSelection(option.id)}
                      >
                        {option.options_text}
                      </Typography>
                    )
                  })
                }
                {
                  this.state.isOtherInputEnable
                    ? <StyledInputField
                      fullWidth
                      variant="outlined"
                      placeholder={configJSON.otherSpecifyText}
                      data-test-id={`otherInputTestId`}
                      name="otherInput"
                      value={this.state.otherInput}
                      onChange={(event) => this.handleOtherInputChange(event.target.value)}
                      inputRef={this.inputRef}
                    />
                    : <Typography
                      className="singleOption"
                      style={this.getOtherInputStyle()}
                      onClick={this.enableOtherInput}
                      data-test-id="enableOtherInput"
                    >
                      {this.renderLastInputText()}
                    </Typography>
                }
              </Box>

              <Box className="actionBtnsBox">
                <Typography className="backText" data-test-id="btnBack" onClick={this.handleBack}>{configJSON.backText}</Typography>
                <Typography className={`continueText`} style={{
                    color: this.getContinueTextColor(),
                    backgroundColor: this.getContinueBackgroundColor(),
                  }}
                  onMouseEnter={() => this.setState({ isHovered: this.state.selectedOptions.length > 0 })}
                  onMouseLeave={() => this.setState({ isHovered: false })}
                  data-test-id="continueBtn"
                  onClick={this.handleContinue}
                >
                  {configJSON.continueText}
                </Typography>
                <Typography className="skipThisStepText" data-test-id="btnSkip" onClick={this.handleSkip}>{configJSON.skipThisStepText} <img src={skipIcon} alt="" /></Typography>
              </Box>

              <RegistrationFooter />
            </CustomWrapper>
          )
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  marginLeft: "32px",
  boxSizing: "border-box",
  "& .stepperBox": {
    width: "600px",
    margin: "32px auto 0 auto",
    "@media (max-device-width: 610px)": {
      width: "450px",
    },
    "@media (max-device-width: 490px)": {
      width: "360px",
    },
  },
  "& .headingsBox": {
    width: "fit-content",
    margin: "auto",
    textAlign: "center",
  },
  "& .thankYouSharingHeading": {
    fontFamily: "Raleway",
    fontSize: "41px",
    fontWeight: 600,
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    color: "#2C2524",
    marginTop: "60px",
    "@media (max-device-width: 716px)": {
      marginTop: "40px",
      fontSize: "32px",
    },
    "@media (max-device-width: 490px)": {
      marginTop: "20px",
      fontSize: "24px",
    },
  },
  "& .pleaseShareDetailsHeading": {
    width: "600px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "36px",
    marginTop: "32px",
    color: "#2C2524",
    "@media (max-device-width: 716px)": {
      width: "450px",
      marginTop: "24px",
      fontSize: "20px",
    },
    "@media (max-device-width: 490px)": {
      width: "350px",
      marginTop: "16px",
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  "& .chooseFollowingHeading": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "30px",
    color: "#2C2524",
    marginTop: "32px",
    "@media (max-device-width: 490px)": {
      marginTop: "24px",
      fontSize: "16px",
      lineHeight: "16px",
    },
  },
  "& .selectAllSubHeading": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#2C2524",
    "@media (max-device-width: 490px)": {
      marginTop: "5px",
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  "& .optionList": {
    width: "340px",
    margin: "auto",
    marginTop: "40px",
    boxSizing: "border-box",
    "@media (max-device-width: 840px)": {
      width: "280px",
    },
  },
  "& .singleOption": {
    borderRadius: "26px",
    width: "100%",
    boxSizing: "border-box",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "30px",
    padding: "9px 0 9px 16px",
    marginBottom: "12px",
    cursor: "pointer",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
      padding: "6px 0 6px 12px",
    },
  },
  "& .actionBtnsBox": {
    width: "80%",
    boxSizing: "border-box",
    margin: "64px auto 88px auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    "@media (max-device-width: 980px)": {
      width: "100%",
      padding: "0 15px 0 15px",
    },
    "@media (max-device-width: 840px)": {
      margin: "48px auto 68px auto",
    },
    "@media (max-device-width: 716px)": {
      display: "grid",
      gridTemplateRows: "auto auto auto",
      gap: 20,
      width: "fit-content",
    },
    "@media (max-device-width: 490px)": {
      margin: "30px auto 40px auto",
    },
  },
  "& .backText": {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "23.48px",
    letterSpacing: "1px",
    color: "#78716C",
    cursor: "pointer",
    order: "0",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 716px)": {
      margin: "auto",
    },
  },
  "& .continueText": {
    order: "-1",
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    padding: "16.5px 149px",
    borderRadius: "24px",
    cursor: "pointer",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
      padding: "10px 120px",
    },
    "@media (max-device-width: 716px)": {
      position: "static",
      left: "unset",
      transform: "none",
      margin: "auto",
    },
  },
  "& .skipThisStepText": {
    display: "flex",
    alignItems: "center",
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "23.48px",
    letterSpacing: "1px",
    color: "#807C7C",
    cursor: "pointer",
    order: "1",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 716px)": {
      margin: "auto",
    },
  },
  "& .skipThisStepText img": {
    marginLeft: "8px",
  },
});

const SidebarLine = styled(Box)({
  width: "32px",
  height: "100vh",
  position: "fixed",
  backgroundColor: "#1A4A42",
});

const StyledInputField = styled(TextField)({
  marginBottom: "0px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
      borderWidth: "1px",
    },
  },
  "& .MuiInputBase-root": {
    fontFamily: 'Poppins',
    height: '44px',
    margin: 'auto',
    borderRadius: '24px',
    backgroundColor: "#FFFFFF",
    fontSize: '20px',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "24px",
    borderColor: "#E6C79C",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#E6C79C",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: 'Poppins',
    color: '#2C2524',
    padding: '10px 12px 10px 16px',
    "&::placeholder": {
      fontSize: "20px",
      fontFamily: 'Poppins',
      color: '#94A3B8',
    },
    "&:not(:placeholder-shown)": {
      fontFamily: 'Poppins',
      color: '#2C2524',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    color: 'red',
    textTransform: 'uppercase',
    lineHeight: "19.2px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Poppins",
    color: "#DC2626",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },

  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: "1px solid #FFFFFF",
  },
  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
});

// Customizable Area End
