// Customizable Area Start
import React from "react";
import { Badge, Box, Button, styled, Typography, } from "@mui/material";
import ChooseProviderController, { Props, configJSON } from "./ChooseProviderController.web";
import CustomStepper from "../components/CustomStepper.web";
import FilterListIcon from '@mui/icons-material/FilterList';
import CareTeamHeader from "../components/CareTeamHeader";
import { backArrowIcon, } from "../assets";
import { KeyboardArrowDown, } from "@mui/icons-material";
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// Customizable Area End

export default class ChooseProvider extends ChooseProviderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <CustomWrapper>
        <SidebarLine />
        <CareTeamHeader />
        <Box className='container'>
          <Box className='leftBox'>
            <CustomStepper
              activeStep={2}
              stepperSteps={this.state.stepperStep}
              color="#FFFFFF"
              bgColor="#E46760"
            />
            <Box className="topActionsBtnBox">
              <Button className="backBtn" data-test-id="handleBackBtn" onClick={this.handleBack}> <img src={backArrowIcon} alt="" />{configJSON.backText}</Button>
              <Typography className="chooseProviderTxt">{configJSON.chooseProviderHeading}</Typography>
              <Box className='filtericon'>
                <Box>
                  <Badge badgeContent={1} >
                    <FilterListIcon />
                  </Badge>
                </Box>
              </Box>
            </Box>
            <Box className="recommendedBox">
              <Typography className="sortByTxt">{configJSON.sortByText}</Typography>
              <Typography className="recommendedTxt">{configJSON.recommendedTxt}</Typography>
              <KeyboardArrowDown style={{ marginLeft: "6px", }} />
            </Box>

            <Box className="providerBox">
              {this.state.providerList.map((provider, index) => (
                <Box className="provider" key={index}>
                  <Box className="providerTopSection">
                    <img className="cardProfile" src={provider.profileImg} alt="profile-img" />
                    <Box className="providerDetails">
                      <Typography className="providerName">{provider.name}</Typography>
                      <Typography className="fullProfileBtn">Full Profile</Typography>
                      <Typography className="LMFTTxt">{provider.qualification}</Typography>
                      <Box className="LMFTList">
                        {provider.specialties.map((specialty, i) => (
                          <Typography className="LMFTListItem" key={i}>{specialty}</Typography>
                        ))}
                      </Box>
                      <Typography className="priceTitle">Initial session price:</Typography>
                      <Typography className="price">
                        ${provider.initialSessionPrice} <span className="priceSpan">({provider.sessionDuration})</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography className="providerDivider"></Typography>
                  <Box className="providerBottomSection">
                    <Box className="providerBottomLeftBox">
                      <Typography className="nextAvailability">Next availability:</Typography>
                      <Typography className="nextAvailabilityTxt">{provider.nextAvailability}</Typography>
                    </Box>
                    <Button className="selectProvderBtn">{configJSON.selectProviderTxt}</Button>
                  </Box>
                </Box>
              ))}
            </Box>



            <Button className="loadMoreBtn">{configJSON.loadMoreBtnTxt}</Button>
            <Typography className="joinWaitlistTxt" data-test-id="joinWaitListTest" onClick={this.joinWaitList}>{configJSON.joinWaitlistTxt}</Typography>
          </Box>
          <Box className='rightBox'>

            <Box className="rightBoxTop">
              <Typography className="chooseADateTxt" style={{ color: "#A8A29E", }}>{configJSON.chooseADateTxt}</Typography>
              <Typography className="centralTimeTxt" style={{ color: "#A8A29E", }}>{configJSON.centralTimeTxt} <span className="centralTimeTxtSpan">{configJSON.utcTxt}</span><KeyboardArrowDown /> </Typography>
            </Box>
            <Typography className="rightBoxDivider"></Typography>

            <Box className="timeOfDayBoxMain">
              <Box className="timeOfDayInnerBox">
                {
                  this.state.timeOfDay.map((time) => {
                    return (
                      <Box key={time.time} className="timeOfDay">
                        <img src={time.image} alt="" />
                        <Typography className="timeOfDayTxt">{time.time}</Typography>
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>

            <Box className='calender-box'>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  open
                  className="datePicker"
                  label="Select Date"
                  dayOfWeekFormatter={(dayOfWeek) => {
                    const weekdaysFull = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    return weekdaysFull[dayOfWeek as any];
                  }}
                  disablePast
                  data-test-id="dayOfWeekFormatter"
                />
              </LocalizationProvider>
            </Box>
          </Box>

        </Box>
      </CustomWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({

  "& .container": {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    marginLeft: "32px",
    boxSizing: "border-box",
  },
  "& .leftBox": {
    minHeight: "100vh",
    width: "65%",
    boxSizing: "border-box",
  },
  "& .topActionsBtnBox": {
    width: "82%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "60px",
  },
  "& .backBtn": {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "#FFFFFF",
    padding: "10px 16px",
    display: "flex",
    alignItems: "center",
    border: "2px solid #E7E5E4",
    borderRadius: "16px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    color: "#A8A29E",
    textTransform: "capitalize",
  },
  "& .backBtn img": {
    marginRight: "8px",
  },
  "& .chooseProviderTxt": {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "29px",
    lineHeight: "40.6px",
    letterSpacing: "0.5px",
    color: "#2C2524"
  },

  "& .filtericon": {
    position: "relative", cursor: "pointer"
  },
  "& .filtericon .MuiSvgIcon-root": {
    backgroundColor: "#1A4A42",
    color: "#FFFFFF",
    borderRadius: "16px",
    height: "24px",
    width: "24px",
    padding: 10,
    cursor: "pointer"
  },
  "& .MuiBadge-badge": {
    color: "#E46760",
    backgroundColor: "#E46760",
    top: 3,
    right: 3,
    minWidth: 16,
    width: 16,
    height: 16
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "#E46760",
    height: "32px",
    width: "32px"
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: "#1A4A42",
    height: "32px",
    width: "32px"
  },
  "& .recommendedBox": {
    width: "fit-content",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    marginTop: "37px",
  },
  "& .sortByTxt": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#2C2524",
  },
  "& .recommendedTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#2C2524",
    marginLeft: "8px",

  },
  "& .loadMoreBtn": {
    backgroundColor: "#E46760",
    display: "block",
    width: "fit-content",
    margin: "auto",
    marginTop: "27px",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    borderRadius: "24px",
    color: "#FFFFFF",
    padding: "16px 123px",
    textTransform: 'capitalize',
    "@media (max-device-width: 745px)": {
      padding: "8px 70px",
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: "#B6524D",
      color: "#FFFFFF",
    }
  },
  "& .joinWaitlistTxt": {
    width: "fit-content",
    margin: "auto",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    textDecoration: "underline",
    textDecorationStyle: "solid",
    textDecorationOffset: "Auto",
    textDecorationThickness: "Auto",
    color: "#78716C",
    marginTop: "16px",
    cursor: "pointer",
  },
  "& .providerBox": {
    width: "82%",
    margin: 'auto',
    boxSizing: "border-box",
    marginTop: "24px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "24px",
  },
  "& .provider": {
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
    border: "1px solid #D6D3D1",
  },
  "& .providerTopSection": {
    display: "flex",
    alignItems: "center",
    padding: "14px 12px 0px 10px",
  },
  '& .cardProfile': {
    width: '127px',
    height: '127px',
    borderRadius: '50%',
    border: "5px solid #F2F0EE",
  },
  "& .providerDetails": {
    marginLeft: "20px",
  },
  "& .providerName": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
    color: "#292524",
  },
  "& .fullProfileBtn": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#E46760",
  },
  "& .LMFTTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#292524",
    marginTop: "8px",
  },
  "& .LMFTList": {
    marginTop: "4px",
    display: "flex",
    flexWrap: "wrap",
    gap: "2px",
  },
  "& .LMFTListItem": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#203447",
    backgroundColor: "#E9EBED",
    padding: "4px 8px",
    borderRadius: "16px",
  },
  "& .priceTitle": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#292524",
    marginTop: "8px",
  },
  "& .price": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#292524",
  },
  "& .priceSpan": {
    color: "#78716C",
  },
  "& .providerDivider": {
    width: "100%",
    border: "1px solid #E7E5E4",
    margin: "12px 0 12px 0",
    boxSizing: "border-box",
  },
  "& .providerBottomSection": {
    padding: "0 14px 14px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .providerBottomLeftBox": {
    display: "flex",
    alignItems: "center",
  },
  "& .nextAvailability": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#78716C",
  },
  "& .nextAvailabilityTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#292524",
    marginLeft: "6px",
  },
  "& .selectProvderBtn": {
    width: "fit-content",
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    borderRadius: "26px",
    padding: "4px 10.5px",
    textTransform: 'capitalize',
    border: "2px solid #E46760",
    color: "#E46760",
    "&:hover": {
      color: "#E46760",
    }
  },
  "& .rightBox": {
    minHeight: "100vh",
    width: "32.91%",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
  },
  "& .rightBoxTop": {
    width: "364px",
    padding: "34px 0 0 42px",
  },
  "& .chooseADateTxt": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "29px",
    lineHeight: "40.6px",
    letterSpacing: "0.5px",
    textAlign: "center",
  },
  "& .centralTimeTxt": {
    width: "fit-content",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    marginTop: "32px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
  },
  "& .centralTimeTxtSpan": {
    fontWeight: 400,
    margin: "0 6px 0 8px",
  },
  "& .rightBoxDivider": {
    border: "1px solid #D6D3D1",
    width: "100%",
    margin: "12px 0 24px 0",
  },
  "& .timeOfDayBoxMain": {
    width: "364px",
    padding: "0 0 0 42px",
  },
  "& .timeOfDayInnerBox": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 4px",
    border: "1px solid #D6D3D1",
    borderRadius: "50px",
  },
  "& .timeOfDay": {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    backgroundColor: "#F5F5F4",
    borderRadius: "50px",
    cursor: "pointer",
  },
  "& .timeOfDayTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    marginLeft: "6px",
    color: "#78716C",
    opacity: "40%",
  },
  '& .calender-box': {
    margin: "24px 0 0 42px",
    minHeight: "600px",
  },
  "& .datePicker": {
    maxWidth: 425,
    width: "100%",
    marginTop: -50,
    visibility: 'hidden',
  },
});

const SidebarLine = styled(Box)({
  width: "32px",
  backgroundColor: "#1A4A42",
  height: "100vh",
  position: "fixed",
});
// Customizable Area End
