import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    steps: string[];
    BookFollowUpSession: boolean;
    activeStep: number;
    checkBoxes: { checkBoxDayAndDate: string, checkBoxTime: string, checkbox: boolean }[];
    addedSessions: { checkBoxDayAndDate: string, checkBoxTime: string, checkbox: boolean }[] | null;
    openPopUp: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class FollowUpSessionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            //Customizable Area Start
            checkBoxes: [{ checkBoxDayAndDate: "Wed, Aug 22", checkBoxTime: "1:30 - 2:30 AM", checkbox: false }, { checkBoxDayAndDate: "Wed, Aug 29", checkBoxTime: "1:30 - 2:30 AM", checkbox: false }, { checkBoxDayAndDate: "Wed, Sep 5", checkBoxTime: "1:30 - 2:30 AM", checkbox: false }],
            addedSessions: null,
            openPopUp: false,
            BookFollowUpSession: true,
            steps: ["About You", "Verify Insurance", "Choose Provider"],
            activeStep: 3,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    changeCheckBox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedCheckBoxes = [...this.state.checkBoxes];
        updatedCheckBoxes[index].checkbox = event.target.checked;
        this.setState({ checkBoxes: updatedCheckBoxes }, () => {
            const checkedItems = updatedCheckBoxes.filter(item => item.checkbox);
            this.setState({ addedSessions: checkedItems })
        });
    };

    checkCheckBoxes = () => {
        return this.state.checkBoxes.some(cb => cb.checkbox === true);
    };

    handleContinueWithoutFollowUp = () => {
        this.setState({ openPopUp: false, BookFollowUpSession: false })
    }

    openFollowUp = () => {
        this.setState({ openPopUp: false, BookFollowUpSession: true })
    }

    handleSubmit = () => {
        if (this.state.BookFollowUpSession === true && this.checkCheckBoxes()) {
            this.setState({ BookFollowUpSession: false });
        }
        else if (this.state.BookFollowUpSession === true && this.checkCheckBoxes() === false) {
            this.setState({ openPopUp: true });
        }
    }
    closePopupModal = () => { this.setState({ openPopUp: false }) }

    // Customizable Area End
}
