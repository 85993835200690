import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("../config");

interface MultiBoxType {
  "id": number,
  "image": string,
  "title": string,
  "description": string,
  "button_text": string,
}

interface SupportDesignType {
  "id": string,
  "attributes": {
    "title": string,
    "body": string,
    "multi_select_boxes": MultiBoxType[],
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperStep: string[],
  heading: string,
  subHeading: string,
  isLoading: boolean,
  supportDesignList: MultiBoxType[],
  selectedOptions: number[],
  isHovered: boolean,
  patient_id: number,
  submittedList: { id: number, title: string }[],
  otherInput: string,
  index:number|null,
  title:string,
  body:string,
  options:any,
  id:string,
  option_id:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TellUsAboutYouController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSupportDesignApiCallId: string = "";
  submitSelectedDesignApiCallId: string = "";
  getSubmittedDesignApiCallId: string = "";
  tellUsAboutId:string=''
  createPatientOptionId:string=''
  getPatientAboutUsId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
   
    this.state = {
      // Customizable Area Start
      stepperStep: ["About You", "Verify Insurance", "Choose Provider"],
      heading: "",
      subHeading: "",
      isLoading: false,
      supportDesignList: [],
      selectedOptions: [],
      isHovered: false,
      patient_id: 158,
      submittedList: [],
      otherInput: "",
      index:null,
      title:'',
      body:'',
      options:[],
      id:'',
      option_id:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId==this.tellUsAboutId){
       if(responseJson.data){
           this.setState({title:responseJson.data[0].attributes.title})
           this.setState({body:responseJson.data[0].attributes.body})
           this.setState({options:responseJson.data[0].attributes.options})}}
      if(apiRequestCallId==this.createPatientOptionId){
        if(responseJson.message){this.handleNavigate('FlowDecision')}
      }
      if(apiRequestCallId==this.getPatientAboutUsId){
          if(responseJson.patient_id){this.setState({option_id:responseJson.selected_options[0].id})}
      }}}
async componentDidMount() {
    this.handleAboutUs()
    const id=await getStorageData('patientid')
    this.setState({id:id})
    this.getPatientAboutUs()

}
 handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }
  async handleAboutUs(){
    const token = await getStorageData("authToken", false)
    let requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   const header = {
     token: token,
   };
   this.tellUsAboutId = requestMessage.messageId;
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.tellUsAboutYou
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getApiMethod
   );
   runEngine.sendMessage(requestMessage.id, requestMessage);
  }
async handleContinue(){
console.log(this.state.id)
console.log(this.state.option_id)
let requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const header = {
    "Content-Type": "application/json",
  };
  const httpBody={
    "patient_id": this.state.id,
    "option_ids": [
      this.state.option_id
    ]
}
  this.createPatientOptionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patientCreateEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
}
async getPatientAboutUs(){
    const token = await getStorageData("authToken", false)
    let requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   const header = {
     token: token,
   };
   this.getPatientAboutUsId = requestMessage.messageId;
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.getPatientOption(this.state.id)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getApiMethod
   );
   runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
