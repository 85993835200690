import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Formtype {
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  email:string,
  mobile:string

} 
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  steps:string[];
  nextForm:boolean;
  selected:string;
  selectedContactMethods: string[],
  selectedTitle:string;
  formData:Formtype;
  listOfStates:string[],
  selectedState:string,
  isPatient:string,
  autherized_id:string|null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PatientRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPatientAPICallId:string=""
  createPatientStateCallID:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      steps:['About You','Verify Insurance','Choose Provider'],
      nextForm:false,
      selected:'',
      selectedContactMethods: [],
      selectedTitle:'Mrs.',
      formData:{ 
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email:'',
        mobile:''},
      listOfStates:[],
      selectedState:'',
      isPatient:'',
      autherized_id:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId===this.createPatientAPICallId){
          if(responseJson.message){
           const id:any=responseJson.data.id 
           setStorageData('patientid',id)
           this.props.navigation.navigate('MatchMakingMultiSelect')
          }
        }
        if(apiRequestCallId===this.createPatientStateCallID){
         
          let states_obj=responseJson.states
          const values:any = Object.values(states_obj);
         this.setState({listOfStates:values})
        
        }
      }
    }
   



    // Customizable Area Start
    // Customizable Area End
  }  

  // Customizable Area Start

  async componentDidMount() {
    this.handleGetStates()
    const isPatient=await getStorageData('isPatient')
    this.setState({isPatient:isPatient})
   const id=await getStorageData('representativeid')
   this.setState({autherized_id:id})
  }
  handleCheckboxChange = (method:any) => {
    this.setState((prevState:S) => {
      const { selectedContactMethods } = prevState;
      if (selectedContactMethods.includes(method)) {
        
        return {
          selectedContactMethods: selectedContactMethods.filter(
            (item) => item !== method
          ),
        };
      } else {
      
        return {
          selectedContactMethods: [...selectedContactMethods, method],
        };
      }
    });
   
  };
  handleNavigate = () => {
    setStorageData('how_you_feel', this.state.selected);
  
    const navigationTarget:any = {
      patient: 'PatientSignupForm2',
      representative: 'PatientRepresentativeDetail'
    };
  
    const targetScreen = navigationTarget[this.state.isPatient];
  
    if (targetScreen) {
      this.props.navigation.navigate(targetScreen);
    }
  };
  handleAPISubmit=async(values:Formtype)=>{
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": "application/json",
    };
    const httpBody={
      "data": {
          "type": "patients",
          "attributes": {
              "email": values.email,
              "first_name": values.firstName,
              "last_name": values.lastName,
              "phone_number": values.mobile,
              "suffix": this.state.selectedTitle,
              "date_of_birth": values.dateOfBirth,
              "state": this.state.selectedState,
              "authorized_representative_id":this.state.autherized_id?this.state.autherized_id:null,
              "contact_method": this.state.selectedContactMethods,
              "terms_of_use": true,
              "help_footer": true,
              "landline_number": false,
              "feeling": this.state.selected,
              "otp": 123456,
              "patient_consent": true
          }
      }
  }
    this.createPatientAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patientCreateEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
 handleGetStates=async ()=>{
     const token = await getStorageData("authToken", false)
     let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: token,
    };
    this.createPatientStateCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patientState
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
