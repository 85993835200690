import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../config");

interface OptionsType {
  "id": number,
  "option_text": string,
}

interface LastQuestionType {
  "id": string,
  "attributes": {
    "title": string,
    "body": string,
    "options": OptionsType[],
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperStep: string[],
  heading: string,
  subHeading: string,
  isLoading: boolean,
  lastQuestionList: OptionsType[],
  selectedOptions: number[],
  isHovered: boolean,
  patient_id: number,
  otherTextInput: string,
  otherCheckbox: boolean,
  submittedList: { id: number, option_text: string }[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LastQuestionScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLastQuestionApiCallId: string = "";
  submitSelectedQuestionApiCallId: string = "";
  getSubmittedQuestionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepperStep: ["About You", "Verify Insurance", "Choose Provider"],
      heading: "",
      subHeading: "",
      isLoading: false,
      lastQuestionList: [],
      selectedOptions: [],
      isHovered: false,
      patient_id: 158,
      otherTextInput: "",
      otherCheckbox: false,
      submittedList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId) {
        if (apiRequestCallId === this.getLastQuestionApiCallId) {
          this.receiveQuestionList(responseJson);
        } else if (apiRequestCallId === this.submitSelectedQuestionApiCallId) {
          if (responseJson && responseJson.message === "Last Question update successfully!") {
            this.handleNavigate("TellUsAboutYou");
          }
        } else if (apiRequestCallId === this.getSubmittedQuestionApiCallId) {
          this.receiveSubmittedData(responseJson)
        }
      }
    }
  }

  receiveQuestionList = (responseJson: { data: LastQuestionType[] }) => {
    if (responseJson && responseJson.data) {
      let data = responseJson.data && responseJson.data[0]
      let attributes = data && responseJson.data[0].attributes;

      this.setState({
        heading: attributes.title,
        subHeading: attributes.body,
        lastQuestionList: attributes && attributes.options,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false, })
    }
  };

  receiveSubmittedData = (data: { selected_options: { id: number, option_text: string }[], other_text: string, }) => {
    if (data && data.selected_options) {
      let selected_options = data.selected_options;
      this.setState((prevState) => ({
        otherCheckbox: data.other_text ? true : false,
        otherTextInput: data.other_text,
        submittedList: selected_options,
        selectedOptions: [
          ...prevState.selectedOptions,
          ...selected_options.map((item) => item.id),
        ],
      }));
    }
  }


  async componentDidMount() {
    this.getLastQuestionList();
    this.getSubmittedQuestionList();
  }

  getLastQuestionList = () => {
    this.setState({ isLoading: true, });

    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLastQuestionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.lastQuestionEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubmittedQuestionList = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubmittedQuestionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubmittedlastQuestionEndPoint}?patient_id=${this.state.patient_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitSelectedQuestion = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const data = {
      "patient_id": this.state.patient_id,
      "option_ids": this.state.selectedOptions,
      "other_text": this.state.otherCheckbox ? this.state.otherTextInput : "",
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitSelectedQuestionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitlastQuestionEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  handleSkip = () => {
    this.setState({
      selectedOptions: [],
      otherCheckbox: false,
      otherTextInput: "",
    }, () => {
      this.submitSelectedQuestion();
    });
  };

  handleContinue = () => {
    if (this.state.selectedOptions.length > 0 || (this.state.otherCheckbox && this.state.otherTextInput)) {
      this.submitSelectedQuestion();
    }
  };

  handleBack = () => {
    this.handleNavigate("SupportDesign")
  };

  getContinueBackgroundColor = () => {
    const { isHovered, selectedOptions } = this.state;
    if (isHovered) {
      return "#B6524D";
    } else if (selectedOptions.length > 0 || (this.state.otherCheckbox && this.state.otherTextInput)) {
      return "#E46760";
    } else {
      return "#D6D3D1";
    }
  }

  getContinueTextColor = () => {
    return (this.state.selectedOptions.length > 0) || (this.state.otherCheckbox && this.state.otherTextInput)
      ? "#FFFAF6"
      : "#78716C";
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false })
  };

  onMouseEnter = () => {
    this.setState({ isHovered: this.state.selectedOptions.length > 0 })
  };



  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    this.setState((prevState) => {
      let updatedOptions = [...prevState.selectedOptions];

      if (checked) {
        updatedOptions.push(Number(value));
      } else {
        updatedOptions = updatedOptions.filter(id => id !== Number(value));
      }

      return { selectedOptions: updatedOptions };
    });
  };

  handleOtherCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ otherCheckbox: event.target.checked })
  };

  handleOtherTextInputChange = (value: string) => {
    this.setState({ otherTextInput: value, })
  };
  // Customizable Area End
}
